import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-venta-digital',
  templateUrl: './venta-digital.component.html',
  styleUrls: ['./venta-digital.component.scss']
})
export class VentaDigitalComponent implements OnInit {

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
  }

  // ngAfterViewInit() {
  //   const s = document.createElement('script');
  //   s.type = 'text/javascript';
  //   s.src = 'assets/lineup.js';
  //   this.elementRef.nativeElement.appendChild(s);
  // }

}
