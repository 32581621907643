<div class="container py-3 py-md-6">

  <div class="row gx-md-6 mb-4 gy-4">

    <div class="col-md-6">
			<div class="ratio ratio-16x9">
				<img src="https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/soat.webp?alt=media&token=5e134a84-1443-421c-829f-83f8c37436ed" alt="" width="100%" height="auto">
			</div>
		</div>

		<div class="col-md-6 text-md-start">
      <p class="lead">Compra tu <span class="fw-bold">SOAT DIGITAL</span> al instante</p>

			<div class="row gy-2 gy-md-3">
        <div class="col-12" *ngFor="let item of opcionesSoat">

          <div class="position-relative itemSoat bg-white rounded border border-2 shadow-sm px-3 py-2 px-md-4 py-md-3">

            <div class="d-flex justify-content-between align-items-center  ">
              <div>
                <img class="aseguradoraSoat" [src]="item.aseguradoraLogo">
              </div>
              <div *ngIf="item.precio">
                <span class="extraSmall text-muted">DESDE</span>
                <p>S/. {{item.precio}}</p>
              </div>
              <div>
                <a class="btn btn-success btn-sm stretched-link" [href]="item.urlButton" target="_blank">COMPRAR</a>
              </div>
            </div>

            <div class="row gy-1 my-1 my-md-2" *ngIf="item.beneficios">
              <div class="col-12" *ngFor="let caracteristica of item.beneficios">
                <div class="d-flex">
                  <span>
                    <i-tabler name="check" class="text-success me-2" style="width: 21px; height: 21px;"></i-tabler>
                  </span>
                  <p class="mb-0 extraSmall lh-sm mt-1">{{caracteristica}}</p>
                </div>
              </div>
            </div>

          </div>

          

        </div>
      </div>
		</div>
		
	</div>

</div>