<div class="" *ngIf="seguro">

    <div class="container">
        <nav>
            <ol class="breadcrumb small">
                <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
                <li class="breadcrumb-item"><a [routerLink]="'/seguros' + seguro.categoria">Seguros para {{seguro.categoria}}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{seguro.nombre}}</li>
            </ol>
        </nav>
    </div>


    <div class="row g-0">
        <div class="col-md-6">
            <div class="imgSeguroDetalle" [ngStyle]="{'background-image': 'url(' + seguro.image + ')'}">
                <div class="bgGradient h-100"></div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="ps-3 ps-md-7 pe-3 pe-md-6 py-3 py-md-6" style="background-color: #F0FCEE;">
                <p class="h1 text-primary">{{seguro.nombre}}</p>
                <p class="lead mb-3 mb-md-4" style="line-height: 120%;">{{seguro.subtitulo}}</p>
                <div class="d-flex" *ngFor="let caracteristica of seguro.caracteristicas">
                    <span class="mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z"/>
                        <circle cx="12" cy="12" r="9" />
                        <path d="M9 12l2 2l4 -4" />
                      </svg>
                    </span>
                    <p class="font-weight-light" style="line-height: 120%;">{{caracteristica}}</p>
                </div>

                <!-- <a *ngIf="seguro.nombre != 'Seguro de Salud'" class="btn btn-primary mt-3 mt-md-4 shadow" href="https://api.whatsapp.com/send?phone=51998161616&text=Hola,%20necesito%20que%20me%20asesoren%20con%20el%20{{seguro.nombre}}" target="blank">
                    <span class="mr-1">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#41D4C1" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z"/>
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 11l2 2l4 -4" />
                  </svg>
                </span> Asesoría al instante
                </a> -->

                <a *ngIf="seguro.nombre === 'Seguro de Salud'" class="btn btn-primary shadow px-3" routerLink="/cotizadores/salud">
                  Cotiza al instante
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right ml-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#82EA79" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z"/>
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <line x1="15" y1="16" x2="19" y2="12" />
                    <line x1="15" y1="8" x2="19" y2="12" />
                  </svg>
                </a>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="text-center py-3 py-md-5">
            <p class="h3 font-weight-lighter text-center">¿Vamos a los detalles?</p>
            <p class="text-center font-weight-light text-muted mb-3">Conoce más sobre el {{seguro.nombre}}</p>
            <a class="btn btn-success" href="https://api.whatsapp.com/send?phone=51998161616&text=Hola,%20necesito%20que%20me%20asesoren%20con%20el%20{{seguro.nombre}}" target="blank">
                <span class="mr-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z"/>
                  <circle cx="9" cy="7" r="4" />
                  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                  <path d="M16 11l2 2l4 -4" />
                </svg>
              </span> Asesoría al instante
            </a>
            <button class="btn btn-outline-primary mx-2 my-1" (click)="openModalContacto()">Déjanos tus datos</button>
        </div>
    </div>

    <div class="container" *ngIf="seguro.categoria === 'Personas'">
        <p class="lead">Otros seguros para {{seguro.categoria}}</p>
        <div class="grillaSeguros">
            <div *ngFor="let seguro of segurosPersonas | async">
                <app-card-seguro [seguro]="seguro"></app-card-seguro>
            </div>
        </div>
    </div>

    <div class="container" *ngIf="seguro.categoria === 'Empresas'">
        <p class="lead">Otros seguros para {{seguro.categoria}}</p>
        <div class="grillaSeguros">
            <div *ngFor="let seguro of segurosEmpresas | async">
                <app-card-seguro [seguro]="seguro"></app-card-seguro>
            </div>
        </div>
    </div>


</div>