import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-seguros-personas',
  templateUrl: './seguros-personas.component.html',
  styleUrls: ['./seguros-personas.component.scss']
})
export class SegurosPersonasComponent implements OnInit {

  segurosPersonas;

  constructor(
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    this.segurosPersonas = this.fs.getSegurosPersonas();
  }

}
