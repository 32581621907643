<div class="container py-4 py-md-5">

  <p class="h3">Política de privacidad</p>

  <p>En cumplimiento de Ley N° 29733, Ley de Protección de Datos Personales, y su reglamento aprobado por Decreto Supremo N° 003-2013-JUS, deseamos informarte lo siguiente sobre el tratamiento de tus datos personales:</p>

  <ol>

    <li>
      <p>IDENTIDAD Y DOMICILIO DEL TITULAR DEL BANCO DE DATOS PERSONALES</p>
      <p>El titular del banco de datos en el que se almacenarán los datos personales que nos proporciones es LA PREVISORA CORREDORES DE SEGUROS S.A.C., con RUC No. 20335001657, y con domicilio para estos efectos Jr. Tomas Ramsey N° 930 oficina 309, Magdalena del Mar, Lima (en adelante, “EL TITULAR”.</p>
      <p>Dicho banco se denomina “Banco de Datos de Clientes” y se encuentra inscrito en el Registro Nacional de Protección de Datos Personales con el código N° RNPDP-PJP N° 12528.</p>
    </li>

    <li>
      <p>FINALIDAD</p>
      <p>La empresa tratará los datos personales con la finalidad de prestar los Servicios, según este término es definido en la hoja de términos y condiciones de uso de la empresa, atender las consultas y/o solicitudes de los clientes al respecto, y cumplir las obligaciones asociadas a la prestación de los Servicios. Todos los términos aquí contenidos que empiecen en mayúscula y no tengan una definición en este documento, tendrán el significado que se les atribuye en los Términos y Condiciones de Uso.</p>
    </li>

    <li>
      <p>DATOS PERSONALES SOLICITADOS</p>
      <p>Los datos que solicitamos para cumplir la anterior finalidad son los siguientes: nombres y apellidos, documento de identidad (DNI, RUC o CE), número pasaporte, dirección del domicilio, teléfono, dirección de correo electrónico, imagen, voz, firma, N° de licencia de conducir, N° de carnet de seguros, N° placa del vehículo, N° CUSSP, estado civil, fecha de nacimiento, nacionalidad, sexo, profesión, edad, antecedentes ocupacionales, datos de familiares, créditos, préstamos, avales, datos bancarios, seguros, tarjetas de crédito, bienes patrimoniales, planes de pensiones/jubilación, bienes patrimoniales materia del seguro, aficiones y hábitos personales, características de vivienda, características físicas, información de salud e  ingresos económicos, de ser el caso. Una vez registrados tus datos automáticamente declaras y certificas que ellos corresponden a ti y que son verdaderos, exactos, auténticos, completos, y correctos; y que eres mayor de edad.</p>
    </li>

    <li>
      <p>CONSECUENCIAS DE NO PROPORCIONARNOS TUS DATOS PERSONALES</p>
      <p>De no proporcionarnos los datos personales indicados en el numeral 3, no será posible cumplir la finalidad indicada en el numeral 2. En tal sentido, para cumplir dicha finalidad los referidos datos son obligatorios.</p>
    </li>

    <li>
      <p>TRANSFERENCIAS Y DESTINATARIOS DE LOS DATOS PERSONALES</p>
      <p>Usted se encuentra debidamente informado de que los Datos Personales podrán ser objeto de transferencia nacional a una empresa que brinde servicio de almacenamiento. Asimismo, podrá transferir sus datos a la persona o empresa que opere la plataforma tecnológica, de tiempo en tiempo.</p>
    </li>

    <li>
      <p>PLAZO DE CONSERVACIÓN DE LOS DATOS</p>
      <p>Conservaremos los datos personales mientras se encuentre registrado, salvo norma legal que disponga algo distinto.</p>
    </li>

    <li>
      <p>EJERCICIO DE LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN</p>
      <p>Con los derechos ARCO podrás generar solicitudes respecto a:</p>
      <ul>
        <li>
          <p>Derecho de acceso: Te permite averiguar en el banco de datos de Claro si tus datos personales están siendo usados y además, solicitar la información sobre el origen de dichos datos y además, las comunicaciones realizada con su uso.</p>
        </li>
        <li>
          <p>Derecho de rectificación: Te permite actualizar o completar tus datos personales faltantes. Sólo debes precisar qué datos deseas modificar o agregar en la solicitud y adjuntar un documento que lo valide.</p>
        </li>
        <li>
          <p>Derecho de cancelación: Té da la facultad de solicitar la eliminación  de tus datos personales siempre y cuando: (i) Hayan dejado de ser necesarios sobre el origen de la recopilación o (ii)Haya vencido el plazo establecido con el contrato.</p>
        </li>
        <li>
          <p>Derecho de oposición: Si en caso estén usando tus datos personales para otros motivos, podrás generar una solicitud para restringir <sup>(2)</sup> su uso.</p>
        </li>
      </ul>
      <p>Puede ejercer sus derechos de acceso, rectificación, cancelación, oposición, a través de jleon@previsora.pe</p>
      <p>De considerar que no ha sido atendido en el ejercicio de sus derechos puede presentar una reclamación ante la Autoridad Nacional de Protección de Datos Personales del Ministerio de Justicia y Derechos Humanos, ubicado en calle Scipión Llona 350, Miraflores, llenando el formulario publicado en la página.</p>
    </li>

  </ol>

  <hr class="mt-3">

  <div class="small text-muted mt-3">
    <p class="mb-0"><sup>1</sup> La eliminación no procederá cuando los datos personales deban ser conservados por razones estrictas a las leyes aplicables o relación entre responsable y titular.</p>
    <p><sup>2</sup> Deberás indicar y fundamentar sobre qué dato personal deseas restringir su uso.</p>
  </div>


</div>