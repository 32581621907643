import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActionSequence } from 'protractor';
import { map } from 'rxjs/operators';
import { Banner } from '../classes/banner';
import { Beneficio } from '../classes/beneficio';
import { Seguro } from '../classes/seguro';

interface Prima {
  edad: number;
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  primaObject: AngularFirestoreDocument<Prima>;

  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) { }

  // addSeguro(dataSeguro: string) {
  //   return this.afs.collection('seguros').add(dataSeguro);
  // }

  addBanner(dataBanner) {
    return this.afs.collection('banners').add(dataBanner);
  }

  deleteBanner(idBanner) {
    return this.afs.collection('banners').doc(idBanner).delete();
  }

  deleteBannerStorage(idBanner, imageName) {
    return this.storage.ref(`imagesBanners/${idBanner}/${imageName}`).delete();
  }

  updateBanner(idBanner, dataBanner) {
    return this.afs.collection('banners').doc(idBanner).update(dataBanner);
  }

  updateAprobado(idBanner, publicado) {
    this.afs.doc('banners/' + idBanner).update({ publicado });
  }

  updateDestacado(idBanner, destacado) {
    this.afs.doc('banners/' + idBanner).update({ destacado });
  }

  getAllBanners() {
    return this.afs.collection('banners', ref => ref
    .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Banner;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getBannersHome() {
    return this.afs.collection('banners', ref => ref
      .where('publicado', '==', true)
      .where('destacado', '==', true)
      .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Banner;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getBannersInterna() {
    return this.afs.collection('banners', ref => ref
      .where('publicado', '==', true)
      .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Banner;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }



  getAllActualizacionDatos() {
    return this.afs.collection('actualizacionDatos', ref => ref
      // .orderBy('titulo', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }



  addSeguro(dataSeguro: string, idSeguro: string) {
    return this.afs.collection('seguros' + idSeguro).add(dataSeguro);
  }

  getAllSeguros() {
    return this.afs.collection('seguros', ref => ref
      .orderBy('fechaCreacion', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Seguro;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getSegurosPersonas() {
    return this.afs.collection('seguros', ref => ref
      .where('categoria', '==', 'Personas')
      .orderBy('fechaCreacion', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Seguro;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getSegurosPersonasFilter(seguroActual) {
    return this.afs.collection('seguros', ref => ref
      .where('categoria', '==', 'Personas')
      .where('nombre', '<', seguroActual)
      .where('nombre', '>', seguroActual)
      .orderBy('fechaCreacion', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Seguro;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getSegurosEmpresas() {
    return this.afs.collection('seguros', ref => ref
      .where('categoria', '==', 'Empresas')
      .orderBy('fechaCreacion', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Seguro;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getSeguro(id) {
    // return this.afs.doc('notas/' + id).get();
    return this.afs.doc('seguros/' + id).valueChanges();
  }

  addContacto(dataContacto) {
    return this.afs.collection('contactos').add(dataContacto);
  }

  addReclamo(dataReclamo) {
    return this.afs.collection('reclamos').add(dataReclamo);
  }

  addContactoSalud(dataContacto) {
    return this.afs.collection('contactosSalud').add(dataContacto);
  }

  getCotizacion(id) {
    // return this.afs.doc('notas/' + id).get();
    return this.afs.doc('cotizaciones/' + id).valueChanges();
  }

  getPrima(id) {
    return this.afs.doc('primasSalud/' + id).valueChanges();
  }

  getPrimaConyuge(id) {
    return this.afs.doc('primasSalud/' + id).valueChanges();
  }

  getPrimaFamiliar(id) {
    // const user = firebase.auth().currentUser;
    return this.afs.collection('cotizaciones', ref => ref
      .where('favoritos', 'array-contains', 'conyuge')
    ).snapshotChanges();
  }


  getAseguradoras() {
    return this.afs.collection('aseguradoras', ref => ref

    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  
  deleteAseguradora(idAseguradora) {
    return this.afs.collection('aseguradoras').doc(idAseguradora).delete();
  }

  deleteAseguradoraStorage(idAseguradora, imageName) {
    return this.storage.ref(`aseguradoras/${idAseguradora}/${imageName}`).delete();
  }



  getCotizacionSalud(id) {
    // return this.afs.doc('notas/' + id).get();
    return this.afs.doc('cotizacionesSalud/' + id).valueChanges();
  }

  addActualizacionDatos(formDatos) {
    return this.afs.collection('actualizacionDatos').add(formDatos);
  }

  getActualizacionDatos(id) {
    return this.afs.doc('actualizacionDatos/' + id).valueChanges();
  }

  getEdadesSalud() {
    return this.afs.collection('primasSalud', ref => ref
      .orderBy('edad', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getPrimasSalud() {
    return this.afs.collection('primasSalud3', ref => ref
    .orderBy('edad', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
  );
  }


  addReporteBi(formDatos) {
    return this.afs.collection('reportesBi').add(formDatos);
  }


  getReportesBi() {
    return this.afs.collection('reportesBi', ref => ref
      // .orderBy('titulo', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  deleteReporte(idReporte) {
    return this.afs.collection('reportesBi').doc(idReporte).delete();
  }

  getSolicitudesSoat() {
    return this.afs
      .collection('cotizacionesSoat', (ref) => ref.orderBy('dataUsuario.fechaCreacion', 'desc'))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getPrimasSaludAdulto() {
    return this.afs.collection('primasSaludAdulto', ref => ref
    .orderBy('edad', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
  );
  }

  getPrimasSaludConyuge() {
    return this.afs.collection('primasSaludConyuge', ref => ref
    .orderBy('edad', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
  );
  }

  getPrimasSaludHijo() {
    return this.afs.collection('primasSaludHijo', ref => ref
    .orderBy('edad', 'asc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
  );
  }

  addContactoSeguroSalud(id, contacto, seguro) {
    return this.afs.doc('cotizacionesSalud/' + id).set({
      dataContacto: contacto,
      dataSeguro: seguro,
      oculto: false
    });
  }

  getPlanesSalud() {
    return this.afs.collection('planesSalud').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  getSolicitudesSalud() {
    return this.afs
      .collection('cotizacionesSalud', (ref) => ref
      .where('oculto', '==', false)
      .orderBy('dataContacto.fechaCreacion', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getPrimasSoatAutos() {
    return this.afs
      .collection('primasSoatAutos', (ref) => ref
      .orderBy('marca', 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getPrimasSoatMotos() {
    return this.afs
      .collection('primasSoatMotos', (ref) => ref
      .orderBy('marca', 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getPrimasVehicularMotos() {
    return this.afs
      .collection('primasVehicularMotos', (ref) => ref
      .orderBy('marca', 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getBeneficiosAll() {
    return this.afs.collection('beneficios').snapshotChanges()
    .pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Beneficio;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  getBeneficiosHome() {
    return this.afs.collection('beneficios', (ref) => ref
    .where('publicado', '==', true)
    .where('destacado', '==', true)
    .orderBy('fechaCreacion', 'asc')
    ).snapshotChanges()
    .pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Beneficio;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  getBeneficiosInterna() {
    return this.afs.collection('beneficios', (ref) => ref
    .where('publicado', '==', true)
    .orderBy('fechaCreacion', 'asc')
    ).snapshotChanges()
    .pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Beneficio;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

    






}
