<div class="container py-4 py-md-5">

    <p class="h3">Términos y condiciones</p>

    <p>La Previsora Corredores de Seguros S.A.C. (en adelante, la “Empresa”) da la bienvenida a los usuarios de Internet que visitan nuestra página web y espera que la información puesta a su disposición sea de utilidad.</p>

    <p>Los usuarios de nuestra página web, al acceder y hacer uso de la misma declaran aceptar los términos y condiciones que a continuación se indican. Si el usuario no está de acuerdo con los términos y condiciones que aparecen a continuación, deberá abstenerse de ingresar a esta página web ni a ninguna de sus páginas.</p>

    <p>A continuación, se exponen dichas condiciones:</p>

    <ol>

      <li>
        <p>Toda la información mostrada a través de este medio es referencial; por lo que el consumidor deberá informarse de las condiciones vigentes al día de la contratación.</p>
      </li>

      <li>
        <p>Los derechos de propiedad intelectual (nombres comerciales, marcas, lemas, encabezados de páginas, gráficos, textos, logos, botones, íconos, imágenes, audio, código y software) incorporados a esta página web son de propiedad exclusiva de la Empresa. Su uso, disposición o integración están protegidos por la legislación de la materia. Se prohíbe cualquier otro uso, incluyendo la reproducción, modificación, distribución, transmisión, exhibición o impresión, salvo autorización escrita de la Empresa.</p>
      </li>

      <li>
        <p>El usuario reconoce que las disposiciones establecidas en el párrafo anterior también son aplicables a los derechos de propiedad intelectual de terceros cuyas páginas web estén enlazadas a la página web de la Empresa.</p>
      </li>

      <li>
        <p>La Empresa, es un intermediario autorizado por la Superintendencia de Banca, Seguros y AFP para el corretaje de seguros (Reg. SBS J-0593). Las coberturas para todos los seguros son otorgadas por las compañías de seguros respectivas.</p>
      </li>

      <li>
        <p>Lea esta sección detenidamente. Esta sección limita la responsabilidad de la Empresa ante usted en cuanto a problemas que pudieran surgir en relación con el uso de la plataforma, redes, páginas webs, aplicaciones o servicios prestados por la Empresa. Si no comprende las condiciones de esta sección o de cualquier parte del documento, consulte a un abogado para que se los aclare antes de acceder o usar los servicios.</p>

        <p>La Empresa no garantiza la inexistencia de interrupciones o errores en el acceso al sitio web o a su contenido, ni que este se encuentre actualizado. Al hacer uso de la página web, usted reconoce y acepta la probable existencia de estos errores, y asume el riesgo de continuar adelante con su uso.</p>

        <p>La Empresa no responderá de ninguna consecuencia, daño o perjuicio que pudieran derivarse de su acceso o uso. Tampoco se hace responsable de los errores de seguridad, que se puedan producir ni de los daños que puedan producirse en el sistema informático; como consecuencia de (i) la presencia de un software malicioso en sus ordenadores que sea utilizado para la conexión a los contenidos de la Empresa; (ii) un mal funcionamiento del navegador; (iii) uso de versiones no actualizadas del navegador.</p>

        <p>La Empresa no asume ninguna responsabilidad derivada de los contenidos enlazados desde la plataforma, siempre que sean ajenos al mismo, ni garantiza la ausencia de software malicioso u otros elementos en los mismos que puedan producir alteraciones en el sistema informático (hardware y software).</p>

        <p>Como usuario de los Servicios, usted entiende y acepta que la Empresa no tendrá responsabilidad con usted por cualquier uso que haga de la pagina web.</p>

        <p>Estos términos y condiciones y la anterior exención de responsabilidad no afectan los derechos legales obligatorios que no pueden excluirse según la ley aplicable; por ejemplo, las leyes de protección del consumidor.</p>
      </li>

      <li>
        <p>La Empresa podrá actualizar, modificar, aumentar, disminuir, cambiar o corregir cualquier información u omisión contenida en esta página web, así como los términos y condiciones aquí descritos, en cualquier momento y sin previo aviso a los usuarios/clientes. Dichos cambios serán reflejados en la presente página web, y entrarán en vigencia desde la fecha de su publicación o desde la fecha que se indique para tal efecto, no siendo necesario notificación al cliente de ninguna otra forma.</p>
      </li>

      <li>
        <p>La Empresa atenderá las quejas y reclamos en un plazo máximo de treinta (30) días de presentado.</p>
      </li>

      <li>
        <p>El acceso y uso de esta página web se rige por los términos y condiciones aquí descritos, las disposiciones legales aplicables a la materia y cualquier otro instrumento suscrito por el usuario/cliente, en lo que le sea aplicable.</p>
      </li>

      <li>
        <p>Toda controversia, discrepancia e infracción de los términos y condiciones establecidos en el presente documento serán sometidas a los jueces y tribunales competentes del Distrito Judicial de Lima - Cercado.</p>
      </li>

    </ol>




    <!-- <p>Lea detenidamente estos Términos y Condiciones de Uso (en adelante “Términos y Condiciones”) antes de utilizar el sitio web de La Previsora Corredores de Seguros S.A.C. (en adelante La Previsora).</p>
    <p>Al acceder y utilizar este sitio web usted expresamente reconoce y acepta los siguientes Términos y Condiciones. Si no acepta estos Términos y Condiciones, por favor no utilice el sitio web.</p>
    <p>La información y materiales que constan en este sitio web son de propiedad de La Previsora, o cuenta con los derechos de uso, por lo que salvo se indique lo contrario en estos Términos y Condiciones, no se podrá modificar, copiar, distribuir, transmitir,
        mostrar, ejecutar, reproducir, publicar, conceder licencias, crear trabajos derivados, transferir o vender información, software, productos o servicios obtenidos en los materiales y/o en el sitio web.</p>
    <p class="h4 mt-4">Alcance de los Términos y Condiciones</p>
    <p>Estos Términos y Condiciones se aplican a todos los sitios web de La Previsora, ya sea que se trate de un sitio patrocinado, administrado por intermedio de un tercero o alojado por un tercero.</p>
    <p class="h4 mt-4">Cambios y actualizaciones</p>
    <p>La Previsora se reserva el derecho de modificar o suspender, temporal o permanentemente, en cualquier momento y sin previo aviso, el Sitio web o materiales publicados en los mismos (todo o parte de ellos). Sin que ello genere derecho a reclamo o indemnización
        alguna a favor del usuario.</p>
    <p>Del mismo modo, La Previsora se reserva el derecho de modificar estos Términos y Condiciones, así como el diseño, la presentación o configuración, los requisitos de registro o uso en el Sitio web, en cualquier momento y sin previo aviso ni comunicación
        alguna. Sin que ello genere derecho a reclamo o indemnización alguna a favor del usuario.</p>
    <p class="h4 mt-4">Exactitud, precisión y actualidad de la Información de este Sitio</p>
    <p>La Previsora aclara que la información aquí expuesta podría no ser exacta, completa ni actualizada y que se entrega solo como referencia. Todo el material contenido en este sitio es proporcional a la información general y no debe utilizarse como la
        única base para tomar decisiones. Confiar en este material será bajo responsabilidad y riesgo de quien la utilice. Nos reservamos el derecho de modificar el contenido en cualquier momento. Convenimos que es su responsabilidad vigilar los cambios
        y actualizaciones.</p>
    <p class="h4 mt-4">Propiedad Intelectual</p>
    <p>La Previsora es el titular de todos los derechos legítimos sobre el Sitio web y los materiales que en ellos se encuentren. Asimismo, La Previsora es licenciatario de todos los nombres comerciales, marcas comerciales, marcas de servicios, marcas de
        productos, logotipos, nombres de dominio y otras características distintivas de las marcas contenidas en ellos.</p>
    <p>Se prohíbe cualquier copia, reproducción, modificación, publicación, carga, envío, transmisión o distribución en modo alguno, salvo indicación expresa en contrario. La Previsora no concede derecho expreso alguno ni implícito sobre las patentes, derechos
        de autor, marcas comerciales o información de secretos comerciales.</p>
    <p class="h3 mt-5">2. SEGURIDAD Y PRIVACIDAD</p>
    <p>La información que se envíe a La Previsora a través de formularios para completar datos en el Sitio web se regirá de conformidad con el siguiente texto. En ese sentido, como usuario de este sitio web, le informamos sobre el uso que le damos a los
        datos que registre en el mismo, a fin que pueda decidir si quiere ingresar sus datos o no.</p>
    <p>La Previsora considera importante garantizar la protección de Datos Personales de sus clientes. Es por eso que, en cumplimiento con la Ley Nro. – 29733, Ley de Protección de Datos Personales (en adelante “LPDP”) y el D.S. No. 003-2013 que aprueba
        el Reglamento de la LPDP, hemos desarrollado las medidas de seguridad necesarias para el correcto uso de su información.</p>
    <p>Los datos recogidos a través de este sitio web serán objeto de tratamiento automatizado e incorporados a las correspondientes bases de datos que La Previsora es titular y responsable. Del mismo modo utilizamos los datos recolectados para identificación,
        elaborar estadísticas, para el manejo de datos a nivel interno y para poder mejorar el sitio.</p>
    <p>Al facilitar sus datos en el Sitio web, nos está comunicando que Usted consciente y voluntariamente nos está suministrando dicha información, así como a que sean incorporados a una base de datos cuya titularidad es de La Previsora Corredores de Seguros
        S.A.C. con RUC N° 20335001657, ubicados en Calle Tomas Ramsey N° 930, Oficina 309, Magdalena del Mar, Lima. Estos datos serán almacenados por un periodo de 2 años y únicamente serán usados para los fines aquí descritos. La Previsora se compromete
        a no divulgar sus datos.</p>
    <p>Usted podrá ejercer los derechos que la legislación vigente en materia de protección de datos personales te otorga (acceso, actualización, inclusión, rectificación, supresión) enviando un correo electrónico a <a href="mailto:previsora@previsora.pe">previsora@previsora.pe</a></p>
    <ul>
        <li>
            <p class="h5">Tratamiento de datos personales</p>
            <p>Los datos personales que nos proporciona, sea de manera física, oral o electrónica, son los necesarios para brindarles nuestros servicios, como gestionar cotizaciones de seguros, pólizas de seguros, asesoría ante un siniestro, hacer seguimiento
                de diversos trámites, fines comerciales, entre otros similares.</p>
        </li>
        <li>
            <p class="h5">Derechos del titular de datos personales</p>
            <p>Como titular de datos personales, tiene los siguientes derechos:</p>
            <p>Derecho de Acceso / Información: tiene derecho a conocer y obtener información sobre los datos personales con los que contamos en nuestros bancos, la finalidad para la cual son utilizados tus datos personales, quiénes tienen acceso a ellos,
                en cuáles de nuestras bases de datos se almacenan.</p>
            <p>Derecho de Rectificación / Actualización: Solicitar la modificación, actualización, inclusión de los datos que resulten inexactos,incompletos, desactualizados o falsos.</p>
            <p>Derecho de Cancelación / Supresión: eliminar sus datos de nuestro banco de datos si han dejado de ser necesarios para la finalidad que fueron recopilados, si venció el plazo para su tratamiento o cuando el tratamiento no se realice conforme
                a las normas de protección de datos personales.</p>
            <p>Derecho de oposición: puede oponerse a que sus datos sean tratados o que formen parte de nuestro banco de datos cuando no haya autorizado su recopilación, por haber sido tomada de fuentes de acceso al público o por un motivo legítimo y fundado,
                referido a una situación personal concreta.</p>
            <p>Derecho de Revocación: dejar sin efecto, en cualquier momento y sin justificación previa, la autorización al tratamiento de datos que hubieses dado en algún momento.</p>
            <p>Usted puede ejercer sus derechos que como titular de datos personales le asisten (acceso, rectificación, cancelación, oposición, información o revocación). Para tal efecto, puede enviar una comunicación gratuitamente a previsora@previsora.pe
                con la referencia: “Atención de Derechos ARCO”.</p>
        </li>
    </ul>
    <p class="h4 mt-4">USTED RECONOCE Y ACEPTA QUE:</p>
    <ul>
        <li>
            <p>Ha leído y acepta los términos y condiciones de uso descritos anteriormente.</p>
        </li>
        <li>
            <p>El uso de los Sitios web y de los materiales publicados en los mismos corren por la cuenta y riesgo del usuario.</p>
        </li>
        <li>
            <p>Todo material descargado u obtenido de otro modo a través del sitio web corre por la cuenta y riesgo del usuario, quien será el único responsable de los daños que pueda sufrir su sistema informático u otro dispositivo o la pérdida de datos
                que derive de la descarga de ese material.</p>
        </li>
        <li>
            <p>En ningún caso ni La Previsora Corredores de Seguros S.A.C., ni sus trabajadores serán responsables de daño alguno derivado del uso de los Sitios web o de los materiales, o de la imposibilidad de uso de los mismos.</p>
        </li>
        <li>
            <p>En ningún caso ni La Previsora Corredores de Seguros S.A.C., ni sus trabajadores responderán por daños especiales, indirectos, incidentales o de cualquier otra naturaleza que deriven de lucro cesante, pérdida de uso o pérdida de datos, emergente
                del o en relación con el uso o desempeño del software, documentos, la prestación o no de servicios o información disponible en el sitio web.</p>
        </li>
    </ul> -->

</div>