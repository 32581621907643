import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-comparadores',
  templateUrl: './comparadores.component.html',
  styleUrls: ['./comparadores.component.scss']
})
export class ComparadoresComponent implements OnInit {

  aseguradoras;

  comparadores = [
    {
      nombre: 'Salud',
      descripcion: 'Compara seguros de',
      image: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/imagesComparadores%2Fsalud.webp?alt=media&token=ad320814-cb50-479e-a5e9-82320942a1b8',
      url: '/cotizadores/salud'
    },

    // {
    //   nombre: 'Vehicular',
    //   descripcion: 'Compara seguros',
    //   image: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/imagesComparadores%2FiconSeguroVehicular.svg?alt=media&token=8aa68b91-0bdb-4392-9c60-d833d4a9f17e',
    //   url: '/cotizadores/vehicular'
    // },

    // {
    //   nombre: 'Motos',
    //   descripcion: 'Compara seguros',
    //   image: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/imagesComparadores%2Fmotos.webp?alt=media&token=d687d624-12e1-4b40-b97f-2d9d684881c8',
    //   url: '/cotizadores/vehicularMoto'
    // },

    // {
    //   nombre: 'SOAT Autos',
    //   descripcion: 'Compara',
    //   image: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/imagesComparadores%2Fsoat.png?alt=media&token=54349ae2-c731-4adc-b6e2-fa417984972b',
    //   url: '/cotizadores/soatAuto'
    // },

    // {
    //   nombre: 'SOAT Motos',
    //   descripcion: 'compara',
    //   image: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/imagesComparadores%2Fsoat.webp?alt=media&token=d9278b00-038e-4531-9440-60380e777275',
    //   url: '/cotizadores/soatMoto'
    // },

  ]

  constructor(
    private ds: DataService
  ) { }

  ngOnInit(): void {
    this.aseguradoras = this.ds.aseguradoras;
  }

}
