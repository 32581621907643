<div class="container py-4 py-md-5">

  <p class="h3">Añadir cotización</p>

  <div class="col-md-4">
    <form [formGroup]="formSalud" (ngSubmit)="onSubmit()" novalidate class="row">

      <mat-form-field  appearance="outline" class="col-12">
        <mat-label>Celular</mat-label>
        <input matInput formControlName="celular">
        <mat-error *ngIf="errorCelular()">{{errorCelular()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Edad del titular</mat-label>
        <mat-select formControlName="edadTitular">
          <mat-option *ngFor="let item of edades" [value]="item">
            {{item}} años
          </mat-option>
        </mat-select>
        <mat-error *ngIf="errorEdadTitular()">{{errorEdadTitular()}}</mat-error>
      </mat-form-field>

      <div formArrayName="familiares" class="col-12">
        <div *ngFor="let familiar of formSalud.controls['familiares']['controls']; let i = index;">
          <div class="d-flex" [formGroupName]="i">
            <div class="row w-100 g-2 g-md-3">
              <mat-form-field appearance="outline" class="col-6">
                <mat-label>Vínculo</mat-label>
                <mat-select formControlName="vinculo">
                  <mat-option *ngFor="let vinculo of vinculos" [value]="vinculo">{{vinculo}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-6">
                <mat-label>Edad</mat-label>
                <mat-select formControlName="edad">
                  <mat-option *ngFor="let item of edades" [value]="item">{{item}} años</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex-shrink-1 ms-2" style="padding-top: 18px;">
              <button mat-icon-button (click)="eliminarFamiliar(i)">
                <mat-icon color="primary" class="material-icons-outlined">close</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div>
          <button class="btn btn-link btn-sm text-primary p-0" type="button" (click)="agregarFamiliar()"
            [disabled]="this.formSalud.get('edadTitular').value === ''">
            <mat-icon class="align-middle">add</mat-icon>
            <span class="ml-1">
              Agregar familiar
            </span>
          </button>
        </div>
      </div>

      <div class="d-grid mt-4" style="width: 220px;">
        <button class="btn btn-primary" [disabled]="!formSalud.valid">
          <span class="d-flex justify-content-center" *ngIf="loading">
            <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
          </span>
          <span *ngIf="!loading">Añadir cotización</span>
        </button>
      </div>
    </form>
  </div>

</div>