import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Aseguradora } from 'src/app/classes/aseguradora';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-eliminar-aseguradora',
  templateUrl: './eliminar-aseguradora.component.html',
  styleUrls: ['./eliminar-aseguradora.component.scss']
})
export class EliminarAseguradoraComponent implements OnInit {

  deshabilitado: boolean;

  constructor(
    private fs: FirebaseService,
    private dialogRef: MatDialogRef<EliminarAseguradoraComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Aseguradora,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  eliminarReserva(idReserva) {
    this.deshabilitado = true;
    this.fs.deleteAseguradora(idReserva)
    .then(() => {
      this.dialogRef.close();
      this.snackBar.open('Aseguradora eliminada', 'CERRAR', {
        duration: 3000,
      });
    });

    this.fs.deleteAseguradoraStorage(this.data.id, this.data.nombreImage);
  }

  cancelar() {
    this.dialogRef.close();
  }

}
