<div class="container py-3 py-md-4">

  <div class="d-flex flex-column flex-md-row justify-content-md-between">
    <p class="h2">Solicitudes Salud</p>
    <div class="d-flex align-items-start">
      <mat-form-field appearance="outline" class="myWidhtField">
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="value">
        <mat-icon *ngIf="!value" matSuffix>search</mat-icon>
        <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilters()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <a class="btn btn-primary ms-2 mt-2" routerLink="/admin/solicitudesSalud/crear">Añadir</a>
    </div>
    
  </div>


  <div class="d-flex justify-content-center align-items-center" style="height: 400px;" *ngIf="loader">
    <span class="myColorSpinnerSoatDetalle ">
      <mat-spinner class="text-center" [diameter]="36"></mat-spinner>
    </span>
  </div>


  <div class="table-responsive">

    <table mat-table [dataSource]="dataSolicitudesSalud" matSort class="example-table">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumnXs">Código</th>
        <td mat-cell *matCellDef="let item" class="hideColumnXs font-monospace">{{item.id}}</td>
      </ng-container>

      <ng-container matColumnDef="dataContacto.fechaCreacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha solicitud</th>
        <td mat-cell *matCellDef="let item"> {{ item.dataContacto.fechaCreacion?.toDate() | date: 'medium' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dataContacto.nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
        <td mat-cell *matCellDef="let item" class="">{{item.dataContacto.nombre}}</td>
      </ng-container>

      <ng-container matColumnDef="dataContacto.celular">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Celular</th>
        <td mat-cell *matCellDef="let item" class="">{{item.dataContacto.celular}}</td>
      </ng-container>

      <ng-container matColumnDef="detalles">
        <th mat-header-cell *matHeaderCellDef class="hideColumnXs"></th>
        <td mat-cell *matCellDef="let item" class="hideColumnXs text-right">
          <a class="btn btn-light btn-sm text-primary" [routerLink]="'/admin/solicitudesSoat/' + item.id">
            Ver detalles
          </a>
        </td>
      </ng-container>

      
      <ng-container matColumnDef="corredor">
        <th mat-header-cell *matHeaderCellDef>Asignar</th>
        <td mat-cell *matCellDef="let item">
            {{item.corredor}}
          <button mat-icon-button (click)="openModalAsignarCorredor(item.id, item.corredor, item)">
            <!-- <mat-icon class="material-icons-outlined">manage_accounts</mat-icon> -->
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960e0" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <circle cx="12" cy="7" r="4" />
              <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
            </svg>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef>Estado</th>
        <td mat-cell *matCellDef="let item">
          <!-- <mat-form-field appearance="outline" class="no-bottom">
            <mat-select (selectionChange)="selectedEstado(item.estado)" [formControl]="estado">
              <mat-option *ngFor="let estado of estados" [value]="estado">{{estado}}</mat-option>
            </mat-select>
          </mat-form-field> -->
          {{item.estado}}
          <button mat-icon-button (click)="openModalEstado(item.id, item.estado)">
            <!-- <mat-icon class="material-icons-outlined text-muted">drive_file_rename_outline</mat-icon> -->
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-bookmark" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960e0" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M9 4h6a2 2 0 0 1 2 2v14l-5 -3l-5 3v-14a2 2 0 0 1 2 -2" />
            </svg>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="motivo">
        <th mat-header-cell *matHeaderCellDef>Motivo</th>
        <td mat-cell *matCellDef="let item">
            {{item.motivo}}
          <button mat-icon-button (click)="openModalMotivo(item.id, item.motivo)">
            <!-- <mat-icon class="material-icons-outlined text-muted">drive_file_rename_outline</mat-icon> -->
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-message" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960e0" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />
              <line x1="8" y1="9" x2="16" y2="9" />
              <line x1="8" y1="13" x2="14" y2="13" />
            </svg>
          </button>
        </td>
      </ng-container>



      <ng-container matColumnDef="mas">
        <th mat-header-cell *matHeaderCellDef class=""></th>
        <td mat-cell *matCellDef="let item" class="text-end">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="material-icons-outlined">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">

            <!-- <button mat-menu-item (click)="leerSolicitud(item.id)">
              <mat-icon class="material-icons-outlined">sticky_note_2</mat-icon>
              <span>Ver detalles</span>
            </button>

            <mat-divider></mat-divider> -->

            <button mat-menu-item (click)="openModalActualizarVisible(item.id)">
              <mat-icon class="material-icons-outlined">hide_source</mat-icon>
              <span>Ocultar</span>
            </button>

            <button mat-menu-item (click)="openModalDelete(item.id)">
              <mat-icon class="material-icons-outlined text-danger">delete</mat-icon>
              <span class="text-danger">Eliminar solicitud</span>
            </button>

          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-paginator [pageSize]="50" [pageSizeOptions]="[50, 100, 200]" [showFirstLastButtons]="true">
  </mat-paginator>





</div>