import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, NgForm } from '@angular/forms';
import { FirebaseService } from '../../services/firebase.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as firebase from 'firebase/app';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-libro-reclamaciones',
  templateUrl: './libro-reclamaciones.component.html',
  styleUrls: ['./libro-reclamaciones.component.scss']
})
export class LibroReclamacionesComponent implements OnInit {

  formReclamo: UntypedFormGroup;
  deshabilitado: boolean;
  tipoReclamos = [
    {
      nombre: 'Reclamo',
      descripcion: 'Disconformidad relacionada a los productos o servicios.'
    },
    {
      nombre: 'Queja',
      descripcion: 'Malestar o descontento respecto a la atención al público.'
    },
  ];
  tipoDocumentos = [
    'DNI',
    'Pasaporte',
    'RUC',
    'Carnet de extranjería',
    'Carnet de recién nacido'
  ];
  @ViewChild('f') form;
  // @ViewChild('myForm', { static: false }) myForm: NgForm;

  constructor(
    private fb: UntypedFormBuilder,
    private fs: FirebaseService,
    private snackBar: MatSnackBar
  ) {
    this.formReclamo = this.fb.group({
      tipoReclamo: ['', Validators.required],
      tipoDocumento: ['DNI', Validators.required],
      numeroDocumento: ['', Validators.required],
      nombreRazonSocial: ['', Validators.required],
      email: ['', Validators.required],
      telefono: ['', Validators.required],
      domicilio: ['', Validators.required],
      tipoSeguro: ['', Validators.required],
      detalle: ['', Validators.required],
      pedido: ['', Validators.required],
      fechaCreacion: [ Timestamp.now()],
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.formReclamo.valid) {
      this.crearReclamo();
      this.deshabilitado = true;
    } else {
      this.validateAllFormFields(this.formReclamo);
      this.deshabilitado = false;
    }
  }

  crearReclamo() {
    this.fs.addReclamo(this.formReclamo.value)
      .then(() => {
        this.snackBar.open('Reclamo enviado', 'CERRAR', {
          duration: 3000,
        });
        this.form.resetForm({
          fechaCreacion: Timestamp.now(),
        });
        this.deshabilitado = false;
      });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
