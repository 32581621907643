import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-consultas',
  templateUrl: './consultas.component.html',
  styleUrls: ['./consultas.component.scss']
})
export class ConsultasComponent implements OnInit {

  opciones = [
    {
      titulo: 'Salud, Vida Individual',
      descripcion: 'Consulta o reporta un siniestro',
      nombre: 'Nydia Manchego',
      cargo: 'Ejecutiva',
      numero: '998484891',
      image: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2Fnydia.jpg?alt=media&token=920e10f3-fad8-4e92-9ee1-5222374729b1'
    },
    // {
    //   titulo: 'Vehículos, Hogar y SOAT',
    //   descripcion: 'Consulta o reporta un siniestro',
    //   nombre: 'Norma Ticona',
    //   cargo: 'Ejecutiva',
    //   numero: '975079393',
    //   image: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2Fnorma.jpg?alt=media&token=788760d5-9fcc-449b-9f53-bb7ddac0da1c'
    // },
    {
      titulo: 'Vehículos, Hogar y SOAT',
      descripcion: 'Consulta o reporta un siniestro',
      nombre: 'Fiorella Huaringa',
      cargo: 'Ejecutiva',
      numero: '975079393',
      image: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2FfiorellaHuaringa.png?alt=media&token=5902a3ee-df27-4da0-9f50-2da66698158'
    },
    // {
    //   titulo: 'Patrimonial, Accidentes Personales Colectivos, FOLA, EPS, SCTR y Vida Ley',
    //   descripcion: 'Consulta o reporta un siniestro',
    //   nombre: 'Melina López',
    //   cargo: 'Coordinadora',
    //   numero: '949668440',
    //   image: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2Fmelina.jpg?alt=media&token=cd5e6d10-abe8-4067-a839-bb1b0b41298b'
    // },
    {
      titulo: 'Patrimonial y Accidentes Personales Colectivos',
      descripcion: 'Consulta o reporta un siniestro',
      nombre: 'Norma Ticona',
      cargo: 'Ejecutiva',
      numero: '914083971',
      image: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2Fnorma.jpg?alt=media&token=788760d5-9fcc-449b-9f53-bb7ddac0da1c'
    },
    {
      titulo: 'EPS y Vida Ley',
      descripcion: 'Consulta o reporta un siniestro',
      nombre: 'Melina López',
      cargo: 'Ejecutiva',
      numero: '949668440',
      image: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2Fmelina.jpg?alt=media&token=cd5e6d10-abe8-4067-a839-bb1b0b41298b'
    },
    {
      titulo: 'FOLA y SCTR',
      descripcion: 'Consulta o reporta un siniestro',
      nombre: 'Alexis Muñoz',
      cargo: 'Seguros Laborales',
      numero: '945417567',
      image: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2Falexis.png?alt=media&token=4ad98cb3-6c78-473b-8805-35c9a7ab2ccd'
    },
  ];


  constructor(
    private matDialogRef: MatDialogRef<ConsultasComponent>
  ) { }

  ngOnInit(): void {
  }

  cancelar() {
    this.matDialogRef.close();
  }

}
