import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

declare var gtag;

@Component({
  selector: 'app-modal-me-interesa',
  templateUrl: './modal-me-interesa.component.html',
  styleUrls: ['./modal-me-interesa.component.scss']
})
export class ModalMeInteresaComponent implements OnInit {

  messageWhatsApp: string;
  caracteristicas = [];
  numero: number;

  constructor(
    public dialogRef: MatDialogRef<ModalMeInteresaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private analytics: AngularFireAnalytics,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.valoresIniciales();
    this.llamada();
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  caracteriticas() {
    this.caracteristicas = [
    ];
  }

  llamada() {
    if (this.router.url === '/cotizadores/salud') {
      this.numero = 950831391;
    } else {
      this.numero = 998161616;
    }
  }

  valoresIniciales() {

    this.valoresCotizadorSalud();

    if (this.router.url === '/cotizadores/vehicularMoto') {
      this.caracteristicas = [
        {
          nombre: 'Marca',
          valor: this.data.formVehicularMoto_.get('marca').value
        },
        {
          nombre: 'Año',
          valor: this.data.formVehicularMoto_.get('anioFabricacion').value
        },
        {
          nombre: 'Valor comercial',
          valor: `USD ${this.data.formVehicularMoto_.get('valorComercial').value}`
        },
        {
          nombre: 'Aseguradora',
          valor: this.data.item_.aseguradora
        }
      ];
      this.messageWhatsApp = `https://api.whatsapp.com/send?phone=51998161616&text=Hola Jaime, te escribo desde la web de La Previsora. Estoy interesado en un seguro para mi moto marca ${this.data.formVehicularMoto_.get('marca').value}, año ${this.data.formVehicularMoto_.get('anioFabricacion').value}, valor comercial de USD ${this.data.formVehicularMoto_.get('valorComercial').value}, con ${this.data.item_.aseguradora}`;
    }


    if (this.router.url === '/cotizadores/soatMoto') {
      this.caracteristicas = [
        {
          nombre: 'Marca',
          valor: this.data.formSoatMoto_.get('marca').value
        },
        {
          nombre: 'Modelo',
          valor: this.data.formSoatMoto_.get('modelo').value
        },
        {
          nombre: 'Aseguradora',
          valor: this.data.item_.aseguradora
        },
        {
          nombre: 'Precio',
          valor: `S/. ${this.data.item_.prima}`
        }
      ];
      this.messageWhatsApp = `https://api.whatsapp.com/send?phone=51998161616&text=Hola Jaime, te escribo desde la web de La Previsora. Estoy interesado en un SOAT para mi moto marca ${this.data.formSoatMoto_.get('marca').value}, modelo ${this.data.formSoatMoto_.get('modelo').value}, con ${this.data.item_.aseguradora} a S/. ${this.data.item_.prima}`;
    }


    if (this.router.url === '/cotizadores/soatAuto') {
      this.caracteristicas = [
        {
          nombre: 'Marca',
          valor: this.data.formSoatAuto_.get('marca').value
        },
        {
          nombre: 'Modelo',
          valor: this.data.formSoatAuto_.get('modelo').value
        },
        {
          nombre: 'Aseguradora',
          valor: this.data.item_.aseguradora
        },
        {
          nombre: 'Precio',
          valor: `S/. ${this.data.item_.prima}`
        }
      ];
      this.messageWhatsApp = `https://api.whatsapp.com/send?phone=51998161616&text=Hola Jaime, te escribo desde la web de La Previsora. Estoy interesado en un SOAT para mi auto/camioneta marca ${this.data.formSoatAuto_.get('marca').value}, modelo ${this.data.formSoatAuto_.get('modelo').value}, con ${this.data.item_.aseguradora} a S/. ${this.data.item_.prima}`;
    }

  }

  valoresCotizadorSalud() {
    if (this.router.url === '/cotizadores/salud') {
      this.caracteristicas = [
        {
          nombre: 'Edad titular',
          valor: `S/. ${this.data.formSalud_.get('edadTitular').value} años`
        },
        {
          nombre: 'Familiares',
          valor: `${this.data.item_.familiares.length > 0 ? `${this.data.item_.familiares.map( familiar => ` ${familiar.vinculo} de ${familiar.edad} años`)}` : 'Ninguno' }`
        },
        {
          nombre: 'Aseguradora',
          valor: this.data.item_.aseguradora
        },
        {
          nombre: 'Precio',
          valor: `S/. ${this.data.item_.prima} por mes`
        }
      ];
      this.messageWhatsApp = `https://api.whatsapp.com/send?phone=51950831391&text=Hola Karina, te escribo desde la web de La Previsora, necesito que me asesoren con un seguro de Salud *${this.data.item_.plan}* con *${this.data.item_.aseguradora}*. La edad del titular es ${this.data.formSalud_.get('edadTitular').value} años. Familiares: ${this.data.item_.familiares.length > 0 ? `${this.data.item_.familiares.map( familiar => `${familiar.vinculo} de ${familiar.edad} años`)}` : 'Ninguno'}. A un costo aproximado de S/. ${this.data.item_.prima} por mes`;
    }
  }

  sendEventAnalitycsWhatsApp() {

    if (this.router.url === '/cotizadores/vehicularMoto') {
      this.analytics.logEvent('btnWhatsAppSeguroMoto');
    }

    if (this.router.url === '/cotizadores/salud') {
     this.gtag_report_conversion(this.router.url);
    }
  }


  gtag_report_conversion(url) {
    // tslint:disable-next-line:only-arrow-functions
    const callback = function() {
      if (typeof(url) !== 'undefined') {
        window.location = url;
      }
    };
    gtag('event', 'conversion', {
        send_to: 'AW-416169663/L1vsCMrZxPACEL_9uMYB',
        event_callback: callback
    });
    console.log('conversion');
    return false;
  }






  sendEventAnalitycsLlamada() {

    if (this.router.url === '/cotizadores/vehicularMoto') {
      this.analytics.logEvent('btnLlamadaSeguroMoto');
    }

  }



}
