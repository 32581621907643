import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { Timestamp } from 'firebase/firestore';
import { FirebaseService } from 'src/app/services/firebase.service';
import { TerminosCondicionesModalComponent } from '../../terminos-condiciones-modal/terminos-condiciones-modal.component';

@Component({
  selector: 'app-form-contacto',
  templateUrl: './form-contacto.component.html',
  styleUrls: ['./form-contacto.component.scss']
})
export class FormContactoComponent implements OnInit {

  formContacto: UntypedFormGroup;
  loader: boolean;
  solicitudId: string;

  constructor(
    public dialogRef: MatDialogRef<FormContactoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private fs: FirebaseService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore,
    private analytics: AngularFireAnalytics
  ) {
    this.solicitudId = this.afs.collection('cotizaciones').ref.doc().id;
    console.log(this.solicitudId);
  }

  ngOnInit(): void {
    this.formContacto = this.fb.group({
      nombre: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      celular: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(9), Validators.maxLength(9)]],
      aceptaTerminosCondiciones: [false, Validators.requiredTrue],
      fechaCreacion: [Timestamp.now()]
    });

    console.log(this.data);
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  agregarContactoSeguroSalud() {

  }

  onSubmit() {
    if (this.formContacto.valid) {
      this.guardarContacto();
      this.loader = true;
    } else {
      this.validateAllFormFields(this.formContacto);
      this.loader = false;
    }
  }

  // addContactoSeguroSalud(id, contacto, seguro) {
  //   return this.afs.doc('cotizacionesSalud/' + id).set({
  //     dataContacto: contacto,
  //     dataSeguro: seguro,
  //     oculto: false
  //   });
  // }

  guardarContacto() {
    if ( this.router.url === '/cotizadores/vehicularMoto') {
     this.guardarContactoVehicularMoto();
    }

    if ( this.router.url === '/cotizadores/salud') {
      this.guardarContactoSalud();
     }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  guardarContactoVehicularMoto() {
    const solicitudId = this.afs.collection('cotizacionesVehicularMotos').ref.doc().id;
    console.log(solicitudId);
    this.afs.doc('cotizacionesVehicularMotos/' + solicitudId).set({
      dataContacto: this.formContacto.value,
      dataSeguro: this.data,
      oculto: false
    }).then( () => {
      console.log('Datos enviados');
      this.dialog.closeAll();
      this.router.navigate([`/cotizadores/${solicitudId}/gracias`]);
    });
  }

  guardarContactoSalud() {
    const solicitudId = this.afs.collection('cotizacionesSalud').ref.doc().id;
    console.log(solicitudId);
    this.afs.doc('cotizacionesSalud/' + solicitudId).set({
      dataContacto: this.formContacto.value,
      dataSeguro: this.data,
      oculto: false
    }).then( () => {
      console.log('Datos enviados');
      this.dialogRef.close();
      this.router.navigate([`/cotizadores/${solicitudId}/gracias`]);
    });
  }

  openModalTerminos() {
    this.dialog.open(TerminosCondicionesModalComponent, {
      panelClass: 'dialogDeleteSolicitud',
    });
  }

  clickVideollamada() {
    this.analytics.logEvent('botonVideollamadaSalud');
  }

  clickWhatsApp() {
    this.analytics.logEvent('botonWhatsAppSalud');
  }

  clickLlamada() {
    this.analytics.logEvent('botonLlamadaSalud');
  }

  errorNombre() {
    return this.formContacto.controls.nombre.hasError('required') ? 'Ingresa tu nombre' : '';
  }


  errorEmail() {
    return this.formContacto.controls.email.errors?.required ? 'Ingrese un email' :
      this.formContacto.controls.email.errors?.email ? 'Ingrese un email válido' :
        '';
  }

  errorCelular() {
    return this.formContacto.controls.celular.hasError('required') ? 'Ingrese el número de celular' :
    this.formContacto.controls.celular.hasError('pattern') ? 'Solo se admiten números' :
    this.formContacto.controls.celular.hasError('minlength') ? 'Debe contener 9 dígitos' :
    this.formContacto.controls.celular.hasError('maxlength') ? 'No debe exceder los 9 dígitos' :
    '';
  }

  errorAceptaTerminosCondiciones() {
    return this.formContacto.controls.aceptaTerminosCondiciones.hasError('required') && this.formContacto.controls.aceptaTerminosCondiciones.touched ? 'Acepte los términos y condiciones' : '';
  }

}
