<div class="myPositionBottom">
	<a class="btn btn-success btn-circle-lg shadow-sm"
		href="https://api.whatsapp.com/send?phone=51998161616&text=Hola Jaime, te escribo desde la web de La Previsora necesito que me ayudes con un seguro"
		target="blank">
		<i-tabler class="text-primary" name="brand-WhatsApp" style="width: 32px; height: 32px; stroke-width: 1.3;"></i-tabler>
	</a>
</div>


<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>