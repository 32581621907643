import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FirebaseService } from 'src/app/services/firebase.service';
import { CrearActualizacionDatosComponent } from '../crear-actualizacion-datos/crear-actualizacion-datos.component';
import { DetalleActualizacionDatosComponent } from '../detalle-actualizacion-datos/detalle-actualizacion-datos.component';
import { EditarActualizacionDatosComponent } from '../editar-actualizacion-datos/editar-actualizacion-datos.component';
import { EliminarActualizacionDatosComponent } from '../eliminar-actualizacion-datos/eliminar-actualizacion-datos.component';

@Component({
  selector: 'app-actualizacion-datos-admin',
  templateUrl: './actualizacion-datos-admin.component.html',
  styleUrls: ['./actualizacion-datos-admin.component.scss']
})
export class ActualizacionDatosAdminComponent implements OnInit {

  count: number;
  loading: boolean;
  displayedColumns = [ 'nombres', 'apellidoPaterno', 'apellidoMaterno', 'email', 'tipoDocumento', 'numeroDocumento', 'celular' ];
  actualizacionDatosData = new MatTableDataSource();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.actualizacionDatosData.filter = filterValue;
  }

  constructor(
    private fs: FirebaseService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.fs.getAllActualizacionDatos().subscribe(data => {
      this.actualizacionDatosData.data = data;
      this.loading = false;
      this.count = data.length;
    });
    this.actualizacionDatosData.paginator = this.paginator;
    this.actualizacionDatosData.sort = this.sort;

    // excluir acentos
    this.actualizacionDatosData.filterPredicate = (data: any, filter: string): boolean => {
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        return (currentTerm + (data as { [key: string]: any })[key] + '◬');
      }, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
      const transformedFilter = filter.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
  }

  openModalCrear() {
    const dialogRef = this.dialog.open(CrearActualizacionDatosComponent, {
      panelClass: 'modalFullScreen',
    });
    dialogRef.afterClosed().subscribe();
  }


  eliminarAviso(idProduct) {
    this.fs.deleteBanner(idProduct);
  }

  openModalDetalle(producto) {
    const dialogRef = this.dialog.open(DetalleActualizacionDatosComponent, {
      panelClass: 'modalFullScreen',
      data: producto
    });
    dialogRef.afterClosed().subscribe();
  }

  openModalDelete(producto) {
    const dialogRef = this.dialog.open(EliminarActualizacionDatosComponent, {
      data: producto
    });
    dialogRef.afterClosed().subscribe();
  }

  openModalEdit(producto) {
    const dialogRef = this.dialog.open(EditarActualizacionDatosComponent, {
      panelClass: 'modalFullScreen',
      data: producto
    });
    dialogRef.afterClosed().subscribe();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.actualizacionDatosData.paginator = this.paginator;
    // this.avisosData.sort = this.sort;
  }

  actualizarPublicado(key, obj, e) {
    this.fs.updateAprobado(key, e);
  }

  actualizarDestacado(key, obj, e) {
    this.fs.updateDestacado(key, e);
  }

  trackByPublicado(item) {
    return item.publicado;
  }
}
