import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})
export class NosotrosComponent implements OnInit {

  caracteristicas = [
    'Te asesoramos antes, durante y después de la contratación de tu seguro.',
    'Los mejores precios de todas las compañías de seguros.',
    'Asistencia 24/7 los 365 del año.',
    'Contratar tu seguro es más simple y más fácil.',
    'Múltiples formas de pago, tarjeta de crédito, etc.'
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
