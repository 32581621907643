import { Component, Input, OnInit } from '@angular/core';
import { MenuMobileComponent } from '../menu-mobile/menu-mobile.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { ConsultasComponent } from '../consultas/consultas.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() sidenavMain;
  linksMenu;

  constructor(
    private ds: DataService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.linksMenu = this.ds.linksMenu;
  }

  openModalMenuMobile() {
    const dialogRef = this.dialog.open(MenuMobileComponent, {
      panelClass: 'modalFullScreen',
    });
    dialogRef.afterClosed().subscribe();
  }

  getRoute() {
    if (this.router.url !== '/') {
     return 'myBorderBottom';
    }
  }

  openModalConsultas() {
    this.dialog.open(ConsultasComponent, {
      panelClass: 'modalConsultasSiniestros'
    })
  }

}
