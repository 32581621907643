<div>
    <div class="d-flex justify-content-end">
        <button mat-icon-button (click)="cancelar()"><mat-icon>close</mat-icon></button>
    </div>
    <form class="px-3 pb-3" [formGroup]="formContacto" (submit)="onSubmit()" novalidate>
        <p class="h5 mb-3">Déjanos tus datos</p>
        <div class="row">
            <!-- <mat-radio-group formControlName="tipo" class="col-12">
              <mat-radio-button class="mr-3" *ngFor="let tipo of tipos" [value]="tipo">{{tipo}}</mat-radio-button>
          </mat-radio-group> -->
          
            <mat-form-field class="col-12">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="nombre" placeholder="Ingresa un nombre">
                <mat-error *ngIf="errorNombre()">{{errorNombre()}}</mat-error>
            </mat-form-field>
            <mat-form-field class="col-12">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" placeholder="Ingresa tu email">
                <mat-error *ngIf="errorEmail()">{{errorEmail()}}</mat-error>
            </mat-form-field>
            <mat-form-field class="col-12">
                <mat-label>Celular</mat-label>
                <input matInput formControlName="celular" placeholder="Ingresa tu celular">
                <mat-error *ngIf="errorCelular()">{{errorCelular()}}</mat-error>
            </mat-form-field>

            <div class="col-12 text-right mt-2">
                <button class="btn btn-warning">
                <mat-spinner diameter="24" *ngIf="loader"></mat-spinner>
                <span *ngIf="!loader">Enviar datos</span>
              </button>
            </div>
        </div>
    </form>
</div>