import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-detalle-solicitud',
  templateUrl: './detalle-solicitud.component.html',
  styleUrls: ['./detalle-solicitud.component.scss']
})
export class DetalleSolicitudComponent implements OnInit {

  idSolicitudSoat;
  solicitudSoat;
  data;
  loader = true;
  dataAseguradora;
  dataVehiculo;
  dataUsuario;
  dataUsuarioPersonaNatural;
  dataUsuarioEmpresa;

  // myForma = new Date(date).toLocaleDateString();

  constructor(
    private fs: FirebaseService,
    private activatedRoute: ActivatedRoute,
    private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.idSolicitudSoat = params.id;
      this.afs.doc('cotizacionesSoat/' + this.idSolicitudSoat).valueChanges().subscribe(res => {
        this.solicitudSoat = res;
        this.loader = false;

        this.dataAseguradora = [
          {
            nombre: 'Aseguradora',
            valor: this.solicitudSoat.dataAseguradora.aseguradora,
          },
          {
            nombre: 'Precio',
            valor: `S/. ${this.solicitudSoat.dataAseguradora.precio}`,
          },
        ];

        this.dataVehiculo = [
          {
            nombre: 'Marca',
            valor: this.solicitudSoat.dataVehiculo.marca,
          },
          {
            nombre: 'Modelo',
            valor: this.solicitudSoat.dataVehiculo.modelo,
          },
          {
            nombre: 'Placa',
            valor: this.solicitudSoat.dataVehiculo.placa,
          },
          {
            nombre: 'Año de fabricación',
            valor: this.solicitudSoat.dataVehiculo.anoFabricacion,
          },
          {
            nombre: 'Número de chasis',
            valor: this.solicitudSoat.dataVehiculo.numeroChasis,
          },
          {
            nombre: 'Número de motor',
            valor: this.solicitudSoat.dataVehiculo.numeroMotor,
          },
        ];

        this.dataUsuarioPersonaNatural = [
          {
            nombre: 'Nombres',
            valor: this.solicitudSoat.dataUsuario.nombres,
          },
          {
            nombre: 'Apellido Paterno',
            valor: this.solicitudSoat.dataUsuario.apellidoPaterno,
          },
          {
            nombre: 'Apellido Materno',
            valor: this.solicitudSoat.dataUsuario.apellidoMaterno,
          },
          {
            nombre: 'Tipo documento',
            valor: this.solicitudSoat.dataUsuario.tipoDocumento,
          },
          {
            nombre: 'Número DNI',
            valor: this.solicitudSoat.dataUsuario.numeroDNI,
          },
          {
            nombre: 'Número Carnet de extranjería',
            valor: this.solicitudSoat.dataUsuario.numeroCarnetExtranjeria,
          },
          {
            nombre: 'Fecha de nacimiento',
            valor: this.solicitudSoat.dataUsuario.fechaNacimiento?.toDate().toLocaleDateString(),
            // valor: this.solicitudSoat.dataUsuario.fechaNacimiento,
          },
          {
            nombre: 'Celular',
            valor: this.solicitudSoat.dataUsuario.celular,
          },
          {
            nombre: 'Email',
            valor: this.solicitudSoat.dataUsuario.email,
          },
          {
            nombre: 'Departamento',
            valor: this.solicitudSoat.dataUsuario.departamento.name,
          },
          {
            nombre: 'Provincia',
            valor: this.solicitudSoat.dataUsuario.provincia.name,
          },
          {
            nombre: 'Distrito',
            valor: this.solicitudSoat.dataUsuario.distrito.name,
          },
          {
            nombre: 'Dirección',
            valor: this.solicitudSoat.dataUsuario.direccion,
          },
        ];

        this.dataUsuarioEmpresa = [
          {
            nombre: 'Razón social',
            valor: this.solicitudSoat.dataUsuario.razonSocial,
          },
          {
            nombre: 'RUC',
            valor: this.solicitudSoat.dataUsuario.ruc,
          },
          {
            nombre: 'Celular',
            valor: this.solicitudSoat.dataUsuario.celular,
          },
          {
            nombre: 'Email',
            valor: this.solicitudSoat.dataUsuario.email,
          },
          {
            nombre: 'Departamento',
            valor: this.solicitudSoat.dataUsuario.departamento.name,
          },
          {
            nombre: 'Provincia',
            valor: this.solicitudSoat.dataUsuario.provincia.name,
          },
          {
            nombre: 'Distrito',
            valor: this.solicitudSoat.dataUsuario.distrito.name,
          },
          {
            nombre: 'Dirección',
            valor: this.solicitudSoat.dataUsuario.direccion,
          },
        ];

      });
    });
  }

}
