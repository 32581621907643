<div class="container py-4 py-md-6">
    <div class="text-center mb-4 mb-md-5">
        <p class="h3">Libro de Reclamaciones</p>
        <p>Por favor, complete el formulario.</p>
    </div>
    <div>
        <form #f="ngForm" class="col-md-6 mx-auto" [formGroup]="formReclamo" (ngSubmit)="onSubmit()" novalidate>
            <div class="row ">
                <mat-radio-group class="col-12 mb-3" formControlName="tipoReclamo">
                    <mat-radio-button class="me-3" *ngFor="let tipoReclamo of tipoReclamos" [value]="tipoReclamo.nombre">{{tipoReclamo.nombre}}</mat-radio-button>
                </mat-radio-group>
                <mat-form-field class="col-6">
                    <mat-label>Tipo de documento</mat-label>
                    <mat-select formControlName="tipoDocumento">
                        <mat-option *ngFor="let tipoDocumento of tipoDocumentos" [value]="tipoDocumento">{{tipoDocumento}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="col-6">
                    <mat-label>Nro. Documento</mat-label>
                    <input matInput formControlName="numeroDocumento">
                </mat-form-field>
                <mat-form-field class="col-12">
                    <mat-label>Nombre y apellidos / Razón Social</mat-label>
                    <input matInput formControlName="nombreRazonSocial">
                </mat-form-field>
                <mat-form-field class="col-6">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                </mat-form-field>
                <mat-form-field class="col-6">
                    <mat-label>Teléfono</mat-label>
                    <input matInput formControlName="telefono">
                </mat-form-field>
                <mat-form-field class="col-12">
                    <mat-label>Domicilio</mat-label>
                    <input matInput formControlName="domicilio">
                </mat-form-field>
                <mat-form-field class="col-12">
                    <mat-label>Tipo de seguro</mat-label>
                    <input matInput formControlName="tipoSeguro">
                </mat-form-field>
                <mat-form-field class="col-12">
                    <mat-label>Detalle de lo ocurrido</mat-label>
                    <textarea matInput formControlName="detalle" rows="4"></textarea>
                </mat-form-field>
                <mat-form-field class="col-12">
                    <mat-label>Pedido del consumidor</mat-label>
                    <textarea matInput formControlName="pedido" rows="4"></textarea>
                </mat-form-field>
            </div>
            <p class="small text-muted">La formulación del reclamo no impide acudir a otras vías de solución de controversias ni es requisito previo para interponer una denuncia ante el INDECOPI. El proveedor deberá dar respuesta al reclamo en un plazo no mayor a treinta (30) días
                calendario, pudiendo ampliar el plazo hasta por treinta (30) días más, previa comunicación al consumidor.</p>
            <div class="text-right">
                <button class="btn btn-primary">
                  <mat-spinner *ngIf="deshabilitado" [diameter]="24" class="float-left mr-2"></mat-spinner>
                  Enviar {{formReclamo.value.tipoReclamo}}</button>
            </div>
        </form>

        <!-- <pre>{{formReclamo.value | json}}</pre> -->
    </div>
</div>