import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-motivo',
  templateUrl: './motivo.component.html',
  styleUrls: ['./motivo.component.scss']
})
export class MotivoComponent implements OnInit {

  deshabilitado: boolean;
  motivo = new UntypedFormControl(this.data.motivo, [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<MotivoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
  }

  actualizarMotivo(idSolicitudSalud) {
    this.deshabilitado = true;
    this.afs.doc('cotizacionesSalud/' + idSolicitudSalud).update({
      motivo: this.motivo.value
    })
    .then(() => {
      this.dialogRef.close();
      this.snackBar.open('Motivo actualizado', 'CERRAR', {
        duration: 3000,
      });
    });
  }

  cancelar() {
    this.dialogRef.close(false);
  }

}
