<div class="col-md-10 mx-auto py-4 py-md-6">
    <div class="text-center">
        <p class="h2">Adquiere tu SOAT 100% digital</p>
        <p>Adquiérelo con la compañía de seguros de tu preferencia, te mostramos algunas opciones:</p>
    </div>

    <div class="grillaSoat mt-3 mt-md-5">
        <div class="border rounded p-2 p-md-3 d-flex flex-column flex-md-row justify-content-center justify-content-md-start align-items-center" *ngFor="let soat of soats">
            <div class="mr-0 mr-md-3">
                <img class="logoSoat" [src]="soat.logo">
            </div>
            <div class="text-center text-md-left">
                <p class="h4">{{soat.titulo}}</p>
                <a class="btn btn-primary" [href]="soat.urlBoton" target="blank">{{soat.textBoton}}</a>
            </div>
        
        </div>
    </div>
  

</div>