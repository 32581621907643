import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-eliminar-solicitud-salud',
  templateUrl: './eliminar-solicitud-salud.component.html',
  styleUrls: ['./eliminar-solicitud-salud.component.scss']
})
export class EliminarSolicitudSaludComponent implements OnInit {

  deshabilitado: boolean;

  constructor(
    public dialogRef: MatDialogRef<EliminarSolicitudSaludComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
  }

  eliminarSolicitudSalud(idSolicitudSalud) {
    this.deshabilitado = true;
    this.afs.doc('cotizacionesSalud/' + idSolicitudSalud).delete()
      .then(() => {
        this.dialogRef.close();
        this.snackBar.open('Solicitud Salud eliminada', 'CERRAR', {
          duration: 3000,
        });
      });
  }

  cancelar() {
    this.dialogRef.close(false);
  }

}
