import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seguro-oncologico',
  templateUrl: './seguro-oncologico.component.html',
  styleUrls: ['./seguro-oncologico.component.scss']
})
export class SeguroOncologicoComponent implements OnInit {

  caracteristicas = [
    'Tienes tu tratamiento cubierto',
    'Cuentas con un despistaje oncológico anual',
    'Amplia red de clínicas afiliadas',
    'Tienes asesoría personalizada',
  ];

  segurosOncologicos = [
    {
      logoAseguradora: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoPacifico.svg?alt=media&token=bb52ed5e-dd51-4adf-a775-1aa903e01901',
      nombreSeguro: 'Seguro Oncológico Nacional Pacífico',
      caracteristicas: [
        'Beneficio Máximo Anual - US$ 1,125,000',
        'Tratamientos: quimioterapia, cirugía oncológica, radioterapia y terapia biológica  al 100%',
        'Chequeo preventivo al 100% a la primera renovación'
      ],
      precio: 26
    },
    {
      logoAseguradora: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoRimac.svg?alt=media&token=c51c72ea-e0b5-4ba3-93c6-7b7e69b8b969',
      nombreSeguro: 'Seguro Oncológico Integral Rimac',
      caracteristicas: [
        'Cobertura ilimitada al 100%',
        'Tratamientos cubiertos: Quimioterapia, Radioterapia, Terapia biológica, Tomografía, etc.',
        'Chequeo preventivo al 100% a partir de los 6 meses de inicio de vigencia.'
      ],
      precio: 33
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
