<div class="col-md-10 mx-auto" *ngIf="actualizacion">

    <nav class="my-2 my-md-3">
        <ol class="breadcrumb small">
            <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
            <li class="breadcrumb-item"><a routerLink="/actualizacionDatos">Actualización de datos</a></li>
            <li class="breadcrumb-item active">{{idActualizacion}}</li>
        </ol>
    </nav>

    <div class="text-center py-3 py-md-4">
        <img src="assets/images/iconCheck.svg" width="200px">
        <p class="h3">Gracias, {{actualizacion.nombres}}</p>
        <p class="">Tus datos han sido registrados.</p>
    </div>



</div>