<div class="container">

  <nav class="extraSmall my-2">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/beneficios">Beneficios</a></li>
      <li class="breadcrumb-item active">Crear</li>
    </ol>
  </nav>

  <p class="h2">Crear Beneficio</p>

  <form [formGroup]="formBeneficio" (ngSubmit)="onSubmit()" novalidate class="col-md-6">

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Título</mat-label>
      <input matInput formControlName="titulo">
    </mat-form-field>

    <div formArrayName="descripciones" class="col-12">
      <div class="d-flex" *ngFor="let descripcion of formBeneficio.controls['descripciones']['controls']; let i = index;">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Descripción {{i+1}}</mat-label>
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10"
            [formControlName]="i"></textarea>
        </mat-form-field>
        <div class="pt-2">
          <button class="ms-2" mat-button matSuffix mat-icon-button (click)="eliminarDescripcion(i)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 mb-3 mb-md-5">
      <button type="button" class="btn btn-light btn-sm text-primary" (click)="agregarDescripcion()">Agregar
        Descripción</button>
    </div>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Aseguradoras</mat-label>
      <mat-select formControlName="aseguradoras" multiple>
        <mat-option *ngFor="let aseguradora of aseguradoras | async" [value]="aseguradora.nombre">{{aseguradora.nombre}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="d-grid mt-2" style="width: 200px;">
      <button type="submit" class="btn btn-primary">
        <span class="myColorSpinner d-flex justify-content-center" *ngIf="deshabilitado">
          <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
        </span>
        <div *ngIf="!deshabilitado" class="d-flex-inline">
          <span>Crear beneficio</span>
        </div>
      </button>
    </div>

  </form>

</div>