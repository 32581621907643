import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soat',
  templateUrl: './soat.component.html',
  styleUrls: ['./soat.component.scss']
})
export class SoatComponent implements OnInit {

  soats = [
    {
      titulo: 'SOAT Rimac',
      textBoton: 'comprar con Rimac',
      urlBoton: 'https://www.rimac.com/comprar/soat-digital?mcid=previsora:referral:10_2020_soat_conversion:nclientes:previsora-soat:',
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoRimac.svg?alt=media&token=c51c72ea-e0b5-4ba3-93c6-7b7e69b8b969'
    },
    {
      titulo: 'SOAT Mapfre',
      textBoton: 'comprar con Mapfre',
      urlBoton: 'https://soat.mapfre.com.pe/cotiza-intermediario/AF60AE64E9060876E0530AA0785452BD',
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoMapfre.svg?alt=media&token=e48e4fe1-10d1-4c02-885a-1609573eaea5'
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
