import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, UntypedFormArray, ValidatorFn, ValidationErrors } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import * as firebase from 'firebase/app';
import { PrivacidadComponent } from 'src/app/components/privacidad/privacidad.component';
import { requireCheckboxesToBeCheckedValidator } from '../checkbox.validator';
import { Timestamp } from 'firebase/firestore';

// export const requireCheckboxesToBeCheckedValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
//   const nacional = control.get('nacional').value;
//   const internacional = control.get('internacional').value;
//   const nacionalSinReembolso = control.get('nacionalSinReembolso').value;
//   const nacionalClinicasAcotadas = control.get('nacionalClinicasAcotadas').value;

//   const isAnyChecked = nacional || internacional || nacionalSinReembolso || nacionalClinicasAcotadas
//   return isAnyChecked ? null : { required: true };
//  };


@Component({
  selector: 'app-cotizador-salud',
  templateUrl: './cotizador-salud.component.html',
  styleUrls: ['./cotizador-salud.component.scss']
})
export class CotizadorSaludComponent implements OnInit {

  form: UntypedFormGroup;
  formSalud: UntypedFormGroup;
  deshabilitado: boolean;
  // edades = ['1', '2', '3'];
  vinculos = [
    'Cónyuge',
    'Hijo(a)'
  ];

  // edades = [...Array(66).keys()];

  edades;

  isLinear = false;

  checkboxes = [
    {
      id: 'nacional',
      nombre: 'Nacional',
      descripcion: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book'
    },
    {
      id: 'nacionalSinReembolso',
      nombre: 'Nacional sin reembolso',
      descripcion: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book'
    },
    {
      id: 'nacionalClinicasAcotadas',
      nombre: 'Nacional con clínicas acotadas',
      descripcion: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book'
    },
    {
      id: 'internacional',
      nombre: 'Internacional',
      descripcion: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book'
    },
  ];

  constructor(
    private fs: FirebaseService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private afs: AngularFirestore,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.formSalud = this.fb.group({
      edadTitular: ['', Validators.required],
      familiares: this.fb.array([]),
      // nombre: ['', Validators.required],
      // email: ['', [Validators.required, Validators.email]],
      // celular: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(9)] ],
      // acepta: [false, Validators.requiredTrue],
      categorias: new UntypedFormGroup({
        internacional:  new UntypedFormControl(false),
        nacional:  new UntypedFormControl(false),
        nacionalSinReembolso:  new UntypedFormControl(false),
        nacionalClinicasAcotadas:  new UntypedFormControl(false)
      }, requireCheckboxesToBeCheckedValidator()),
      fechaCreacion: [Timestamp.now()],
    });

    this.edades = this.fs.getEdadesSalud();
    console.log(this.edades);
  }

  onSubmit() {
    if (this.formSalud.valid) {
      this.crearCotizacion();
      this.deshabilitado = true;
    } else {
      this.validateAllFormFields(this.formSalud);
      this.deshabilitado = false;
    }
  }

  // crearContactoSalud() {
  //   this.fs.addContactoSalud(this.formSalud.value)
  //     .then(() => {
  //       this.deshabilitado = false;
  //       this.router.navigate(['/cotizador/gracias']);
  //     });
  // }

  crearCotizacion() {
    const cotizacion = this.afs.collection('cotizacionesSalud').ref.doc();
    console.log(cotizacion.id);
    cotizacion.set(this.formSalud.value)
    .then(() => {
      this.router.navigate([`/cotizacion/salud/${cotizacion.id}`]);
    });

  }



  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  agregarFamiliar() {
    // tslint:disable-next-line:no-string-literal
    (this.formSalud.controls['familiares'] as UntypedFormArray).push(
      this.fb.group({
        vinculo: [''],
        edad: ['']
      })
    );
  }

  eliminarFamiliar(index: number): void {
    // tslint:disable-next-line:no-string-literal
    // (this.formProduct.controls['presentaciones']as FormArray).removeAt(index);
    const control = this.formSalud.controls.familiares as UntypedFormArray;
    control.removeAt(index);
  }


  openModalPrivacidad() {
    const dialogRef = this.dialog.open(PrivacidadComponent, {
      // panelClass: 'myModalPrivacidad'
    });
    dialogRef.afterClosed().subscribe();
  }

  errorEdadTitular() {
    return this.formSalud.controls.edadTitular.hasError('required') ? 'La edad es necesaria' : '';
  }
  errorNombre() {
    return this.formSalud.controls.nombre.hasError('required') ? 'El nombre es necesario' : '';
  }
  errorEmail() {
    return this.formSalud.controls.email.hasError('required') ? 'El email es necesario' :
    this.formSalud.controls.email.hasError('email') ? 'No es un email válido.' : '';
  }
  errorCelular() {
    return this.formSalud.controls.celular.hasError('required') ? 'El número de celular es necesario' :
    this.formSalud.controls.celular.hasError('maxlength') ? 'No debe exceder los 9 caracteres' :
    this.formSalud.controls.celular.hasError('pattern') ? 'Solo se admiten números' : '';
  }
  errorAcepta() {
    return this.formSalud.controls.acepta.hasError('required') ? 'Es necesario aceptar este campo' : '';
  }
  // errorCheckbox() {
  //   return this.formSalud.controls.categorias.hasError('requiredTrue') ? 'Marcar por lo menos una casilla' : '';
  // }

}
