<app-carousel></app-carousel>


<div class="container">
	<app-comparadores></app-comparadores>
</div>



<!-- <div class="container">
	<mat-accordion  *ngFor="let item of opciones; let primero = first; let ultimo = last" [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }">
		<mat-expansion-panel>
			<mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto" >
				<mat-panel-description class="w-100">
					<div>
						<p class="mb-0 h5 fw-light">{{item.titulo}}</p>
						<p class="small text-muted mb-0">{{item.descripcion}}</p>
					</div>
				</mat-panel-description>
			</mat-expansion-panel-header>
			Datos contacto
		</mat-expansion-panel>
	</mat-accordion>
</div> -->







<div class="container mt-5 mt-md-7">
	<div class="row align-items-center gx-md-6 mb-4">
		<div class="col-md-6 text-center text-md-start">
			<p class="display-4 fw-bold">Compra tu SOAT desde S/. 55</p>
			<p class="lead">Disponible con Pacífico y Vivir</p>
			<a class="btn btn-success" routerLink="/soat">COMPRAR SOAT</a>
		</div>
		<div class="col-md-6">
			<div class="ratio ratio-16x9">
				<img src="https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/soat.webp?alt=media&token=5e134a84-1443-421c-829f-83f8c37436ed" alt="" width="100%" height="auto">
			</div>
		</div>
	</div>
</div>



<div class="container mt-5 mt-md-7">

	<div class="bg-secondary rounded-3 bannerChequeoPreventivo px-4 px-md-6 pt-4 pt-md-0 pb-0 position-relative">
		<div class="row justify-content-between align-items-center">
			<div class="col-md-6">
				<div class="text-white">
					<p class="display-5 lh-1">Chequeo Preventivo</p>
					<p class="fs-5 lh-sm">Evaluaciones médicas preventivas y un control de riesgos, a los que tienes acceso según tu edad y sexo.</p>
				</div>
				<div class="d-flex gap-2">
					<i-tabler class="text-secondary" *ngFor="let icon of icons" [name]="icon" style="width: 42px; height: 42px; stroke-width: 1.2;"></i-tabler>
				</div>
			</div>
			<div class="col-md-4">
				<img class="mt-3 mt-md-5" src="https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/landings%2FchequeoPreventivo%2Fdoctor3d.png?alt=media&token=66319d95-b72b-420a-8ef4-1dd053f90af9" alt="" width="100%">
			</div>
		</div>
		<a class="stretched-link" routerLink="/landings/chequeoPreventivo"></a>
	</div>

</div>







<app-beneficios></app-beneficios>




<div class="container py-4 pt-md-5">
	<p class="h3 mb-3 mb-md-4 font-weight-light">Seguros para Personas</p>
	<div class="grillaSeguros">
		<app-card-seguro *ngFor="let seguro of segurosPersonas" [seguro]="seguro"></app-card-seguro>
	</div>
</div>


<div class="container py-4 pt-md-5">
	<p class="h3 mb-3 mb-md-4 font-weight-light">Seguros para Empresas</p>
	<div class="grillaSeguros">
		<app-card-seguro *ngFor="let seguro of segurosEmpresas" [seguro]="seguro"></app-card-seguro>
	</div>
</div>


<div class="bg-secondary py-4 py-md-7">
	<div class="container text-center">
		<p class="h2 mb-0">¿Quiénes somos?</p>
		<p class="lead">Conoce un poco más de nosotros</p>
		<div class="row">
			<div class="col-md-10 mx-auto">
				<div class="ratio ratio-16x9 mt-3 mt-md-4">
					
					<!-- <iframe width="100%" height="auto" src="https://www.youtube.com/embed/-SCHmvODloE"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

					<iframe srcdoc="
						<style>
							body, .full {
								width: 100%;
								height: 100%;
								margin: 0;
								position: absolute;
								display: flex;
								justify-content: center;
								object-fit: cover;
							}
						</style>
						<a href='https://www.youtube.com/embed/-SCHmvODloE?autoplay=1' class='full'>
							<img src='https://i.ytimg.com/vi_webp/-SCHmvODloE/maxresdefault.webp' class='full'/>
							<svg version='1.1' viewBox='0 0 68 48' width='68px'	style='position: relative;'>
									<path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
									<path d='M 45,24 27,14 27,34' fill='#fff'></path>
							</svg>
						</a>" style="max-width: 100%px; width: 100%; aspect-ratio: 16/9;" frameborder="0">
					</iframe>

				</div>
			</div>
		</div>
	</div>
</div>

<div class="container py-5 py-md-6">
	<div class="row d-flex align-items-center justify-content-between">
		<div class="col-md-5">
			<p class="h2 font-weight-light">¿Por qué contratar con <span class="text-primary">La Previsora?</span></p>
			<p>Más de 30 años de experiencia brindando asesoría profesional y especializada en seguros.</p>
			<a class="btn btn-primary shadow" routerLink="/nosotros">
				Conoce más de nosotros
				<span class="ms-1">
					<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-right" width="20"
						height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#82EA79" fill="none" stroke-linecap="round"
						stroke-linejoin="round">
						<path stroke="none" d="M0 0h24v24H0z" />
						<line x1="5" y1="12" x2="19" y2="12" />
						<line x1="13" y1="18" x2="19" y2="12" />
						<line x1="13" y1="6" x2="19" y2="12" />
					</svg>
				</span>
			</a>
		</div>
		<div class="col-md-6">
			<div class="my-2 px-3 py-2 rounded myShadow d-flex align-items-center"
				*ngFor="let caracteristica of caracteristicas">
				<!-- <span class="text-secondary h4 mb-0 mr-2 mr-md-3">

                </span> -->
				<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check me-2" width="36"
					height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="#82EA79" fill="none" stroke-linecap="round"
					stroke-linejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" />
					<circle cx="12" cy="12" r="9" />
					<path d="M9 12l2 2l4 -4" />
				</svg>
				<span>{{caracteristica}}</span>
			</div>
		</div>
	</div>
</div>

<div class="col-md-6 mx-auto py-4 py-md-5">
	<p class="text-center h5 mb-3 mb-md-4 font-weight-light">Trabajamos con todas las aseguradoras</p>
	<div class="grillaAseguradoras">
		<div *ngFor="let aseguradora of aseguradoras" class="">
			<img [src]="aseguradora.logo" width="100%">
		</div>
	</div>
</div>