<div class="container py-2 py-md-3">

    <nav>
        <ol class="breadcrumb small">
            <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
            <li class="breadcrumb-item active">Derechos ARCO</li>
        </ol>
    </nav>

    <div class="py-4 py-5">
        <p class="h2 mb-3 mb-md-5 text-center">Derechos ARCO</p>
        <div class="grillaDerechosArco">
            <div class="bg-light p-4 rounded-3" *ngFor="let derecho of derechos">
                <p class="h4 text-primary font-weight-lighter">{{derecho.nombre}}</p>
                <p>{{derecho.descripcion}}</p>
            </div>
        </div>
    </div>

    <div>
        <p>Para ejercer sus derechos ARCO, debe presentar los siguientes documentos:</p>
        <ol>
            <li>Solicitud de Derechos ARCO (descargar
                <a href="https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/docs%2F05.%20FORMATO%20DE%20EJERCICIO%20DE%20DERECHOS%20ARCO.pdf?alt=media&token=803976b5-0dd3-49bf-b80f-07faae009b30" target="blank">aquí</a>).
            </li>
            <li>Documento de identidad (DNI /CE/ Pasaporte) escaneado o copia.</li>
            <li>En caso tenga un representante, deberá presentar una copia simple de la Carta Poder, así como del Documento de identidad del representante.</li>
        </ol>
        <p>Una vez que la haya completado la puede enviar a previsora&#64;previsora.pe o a nuestras oficinas en Av. Mariscal La Mar 750, Miraflores.</p>

    </div>



</div>