import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EliminarSolicitudComponent } from '../../../soat/admin/eliminar-solicitud/eliminar-solicitud.component';
import { AsignarComponent } from '../asignar/asignar.component';
import { EliminarSolicitudSaludComponent } from '../eliminar-solicitud-salud/eliminar-solicitud-salud.component';
import { EstadoComponent } from '../estado/estado.component';
import { MotivoComponent } from '../motivo/motivo.component';
import { OcultarSolicitudSaludComponent } from '../ocultar-solicitud-salud/ocultar-solicitud-salud.component';

@Component({
  selector: 'app-lista-solicitudes-salud',
  templateUrl: './lista-solicitudes-salud.component.html',
  styleUrls: ['./lista-solicitudes-salud.component.scss']
})
export class ListaSolicitudesSaludComponent implements OnInit {

  loader = true;
  value;
  displayedColumns = [ 'dataContacto.fechaCreacion', 'id', 'dataContacto.celular', 'corredor', 'estado', 'motivo', 'mas'];
  dataSolicitudesSalud = new MatTableDataSource();

  estado = new UntypedFormControl('', [Validators.required]);
  avisos: any[];

  estados = [
    'Ganada', 'Perdida', 'Pendiente'
  ];

  selected = 'Ganada';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSolicitudesSalud.filter = filterValue;
  }

  constructor(
    private fs: FirebaseService,
    public dialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {
    this.fs.getSolicitudesSalud().subscribe(res => {
      this.dataSolicitudesSalud.data = res;
      this.loader = false;
    });
    this.dataSolicitudesSalud.paginator = this.paginator;
    this.dataSolicitudesSalud.sort = this.sort;

    // Para ordenar objetos anidados
    this.dataSolicitudesSalud.sortingDataAccessor = (item, property) => {
      // Split '.' to allow accessing property of nested object
      if (property.includes('.')) {
        const accessor = property.split('.');
        let value: any = item;
        accessor.forEach((a) => {
          value = value[a];
        });
        return value;
      }
      // Access as normal
      return item[property];
    };

    // Para filtrar objetos anidados, incluye minusculas
    this.dataSolicitudesSalud.filterPredicate = (data: any, filter: string) => {
      const dataStr = JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };
  }

  selectedEstado(estado) {
    estado = estado;
  }

  clearFilters() {
    this.dataSolicitudesSalud.filter = '';
    this.value = '';
  }

  leerSolicitud(solicitudId) {
    this.router.navigate([`/admin/solicitudesSoat/${solicitudId}`]);
  }

  editarAviso() {
  }

  urlAviso() {
  }

  eliminarAviso(idSolicitudSoat) {
    this.afs.doc('solicitudesSoat/' + idSolicitudSoat).delete();
  }

  // openModalDetalle(aviso) {
  //   const dialogRef = this.dialog.open(AvisoDetalleAdminComponent, {
  //     // width: '250px',
  //     panelClass: 'myDialogAdmin',
  //     data: { id: aviso }
  //   });
  //   dialogRef.afterClosed().subscribe();
  // }

  openModalEstado(idSolicitudSalud, estado) {
    this.dialog.open(EstadoComponent, {
      panelClass: 'dialogDeleteSolicitud',
      data: {idSolicitudSalud, estado}
    });
  }

  openModalMotivo(idSolicitudSalud, motivo) {
    this.dialog.open(MotivoComponent, {
      panelClass: 'dialogDeleteSolicitud',
      data: {idSolicitudSalud, motivo}
    });
  }

  openModalAsignarCorredor(idSolicitudSalud, corredor, item) {
    this.dialog.open(AsignarComponent, {
      panelClass: 'dialogDeleteSolicitud',
      data: {idSolicitudSalud, corredor, item}
    });
  }

  openModalDelete(idSolicitudSalud) {
    const dialogRef = this.dialog.open(EliminarSolicitudSaludComponent, {
      panelClass: 'dialogDeleteSolicitud',
      data: idSolicitudSalud
    });
    dialogRef.afterClosed().subscribe();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.dataSolicitudesSalud.paginator = this.paginator;
    // this.dataSolicitudesSalud.sort = this.sort;
  }

  openModalActualizarVisible(idSolicitudSalud) {
    this.dialog.open(OcultarSolicitudSaludComponent, {
      panelClass: 'dialogDeleteSolicitud',
      data: idSolicitudSalud
    });
  }

  // actualizarVisible(id, aviso, e) {
  //   this.fs.updateAprobado(key, e);
  // }

  // trackByAprobado(item) {
  //   return item.aprobado;
  // }

}
