<div class="container py-3 py-md-4">

    

    <div class="d-flex justify-content-between align-content-center mb-2 mb-md-3">
        <p class="h3 mb-0">Actualización de datos</p>
        <!-- <div>
            <button class="btn btn-primary" (click)="openModalCrear()"><mat-icon>add</mat-icon> Crear</button>
        </div> -->
    </div>

        <div class="">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Ingresa una palabra">
            </mat-form-field>
        </div>
    



    <div class="table-responsive">

        <table mat-table [dataSource]="actualizacionDatosData" matSort class="example-table" [trackBy]="trackByPublicado">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Código</th>
                <td mat-cell *matCellDef="let actualizacionDato"> {{ actualizacionDato.id }} </td>
            </ng-container>

            <ng-container matColumnDef="nombres">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombres</th>
                <td mat-cell *matCellDef="let item"> {{ item.nombres }} </td>
            </ng-container>

            <ng-container matColumnDef="apellidoPaterno">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Apellido Paterno</th>
                <td mat-cell *matCellDef="let item"> {{ item.apellidoPaterno }} </td>
            </ng-container>

            <ng-container matColumnDef="apellidoMaterno">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Apellido Materno</th>
                <td mat-cell *matCellDef="let item"> {{ item.apellidoMaterno }} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell *matCellDef="let item"> {{ item.email }} </td>
            </ng-container>

            <ng-container matColumnDef="tipoDocumento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Documento</th>
                <td mat-cell *matCellDef="let item"> {{ item.tipoDocumento }} </td>
            </ng-container>

            <ng-container matColumnDef="numeroDocumento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Número Documento</th>
                <td mat-cell *matCellDef="let item"> {{ item.numeroDocumento }} </td>
            </ng-container>

            <ng-container matColumnDef="celular">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Celular</th>
                <td mat-cell *matCellDef="let item"> {{ item.celular }} </td>
            </ng-container>

            <ng-container matColumnDef="fechaCreacion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha creación</th>
                <td mat-cell *matCellDef="let banner"> {{ banner.fechaCreacion.toDate() | date: 'short' }} </td>
            </ng-container>

            <ng-container matColumnDef="departamento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Ubicación</th>
                <td mat-cell *matCellDef="let banner"> {{ banner.departamento }} </td>
            </ng-container>

            <ng-container matColumnDef="categoria">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoria</th>
                <td mat-cell *matCellDef="let banner"> {{ banner.categoria}} </td>
            </ng-container>

            <ng-container matColumnDef="imagen">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Imagen</th>
                <td mat-cell *matCellDef="let banner"> <img class="imageBannerAdmin border my-2 rounded-sm" [src]="banner.image"></td>
            </ng-container>

            <ng-container matColumnDef="titulo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Título</th>
                <td mat-cell *matCellDef="let banner"> {{ banner.titulo }} </td>
            </ng-container>

            <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripcion</th>
                <td mat-cell *matCellDef="let banner"> {{banner.descripcion | slice: 0:64}}...</td>
            </ng-container>

            <ng-container matColumnDef="publicado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Publicado</th>
                <td mat-cell *matCellDef="let banner">
                    <mat-slide-toggle color="primary" #toggle [ngModel]="banner.publicado" (ngModelChange)="actualizarPublicado(banner.id, banner, $event)">
                    </mat-slide-toggle>
                </td>
            </ng-container>

            <ng-container matColumnDef="destacado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Destacado</th>
                <td mat-cell *matCellDef="let banner">
                    <!-- <mat-slide-toggle color="primary" #toggle [ngModel]="banner.publicado" (ngModelChange)="actualizarPublicado(banner.id, banner, $event)">
                  </mat-slide-toggle> -->
                    <mat-checkbox #toggle2 [ngModel]="banner.destacado" (ngModelChange)="actualizarDestacado(banner.id, banner, $event)"></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="detalles">
                <th mat-header-cell *matHeaderCellDef>Detalles</th>
                <td mat-cell *matCellDef="let banner">
                    <button mat-icon-button (click)="openModalDetalle(banner)">
                <mat-icon class="material-icons-outlined text-muted">remove_red_eye</mat-icon>
            </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="editar">
                <th mat-header-cell *matHeaderCellDef>Editar</th>
                <td mat-cell *matCellDef="let banner">
                    <button mat-icon-button (click)="openModalEdit(banner)">
              <mat-icon class="material-icons-outlined text-muted">edit</mat-icon>
          </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="eliminar">
                <th mat-header-cell *matHeaderCellDef>Eliminar</th>
                <td mat-cell *matCellDef="let banner">
                    <button mat-icon-button (click)="openModalDelete(banner)">
          <mat-icon class="material-icons-outlined text-muted">delete</mat-icon>
        </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <mat-paginator [pageSize]="50" [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
    </mat-paginator>





</div>