import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-ocultar-solicitud-salud',
  templateUrl: './ocultar-solicitud-salud.component.html',
  styleUrls: ['./ocultar-solicitud-salud.component.scss']
})
export class OcultarSolicitudSaludComponent implements OnInit {

  deshabilitado: boolean;

  constructor(
    private fs: FirebaseService,
    private afs: AngularFirestore,
    private dialogRef: MatDialogRef<OcultarSolicitudSaludComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  ocultarSolicitud(id) {
    this.deshabilitado = true;

    this.afs.doc('cotizacionesSalud/' + this.data).update({
      oculto: true
    })
    .then(() => {
      this.dialogRef.close();
      this.snackBar.open('Solicitud ocultada', 'CERRAR', {
        duration: 2000,
      });
    });
  }

  cancelar() {
    this.dialogRef.close();
  }

}
