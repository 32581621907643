import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eliminar-beneficio',
  templateUrl: './eliminar-beneficio.component.html',
  styleUrls: ['./eliminar-beneficio.component.scss']
})
export class EliminarBeneficioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
