import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  linksFooter = [
    {
      nombre: 'Libro de reclamaciones (Requerimientos / Reclamos)',
      url: '/libroReclamaciones'
    },
    {
      nombre: 'Actualización de datos',
      url: '/actualizacionDatos'
    },
    {
      nombre: 'Términos y condiciones',
      url: '/terminosCondiciones'
    },
    {
      nombre: 'Política de privacidad',
      url: '/politicaPrivacidad'
    },
    {
      nombre: 'Derechos ARCO',
      url: '/derechosArco'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
