import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicComponent } from './components/public/public.component';
import { SeguroOncologicoComponent } from './components/seguro-oncologico/seguro-oncologico.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { DetalleSeguroComponent } from './components/detalle-seguro/detalle-seguro.component';
import { SegurosPersonasComponent } from './components/seguros-personas/seguros-personas.component';
import { LibroReclamacionesComponent } from './components/libro-reclamaciones/libro-reclamaciones.component';
import { LanzamientosComponent } from './components/lanzamientos/lanzamientos.component';
// import { CotizadorSaludComponent } from './components/cotizador-salud/cotizador-salud.component';

import { SegurosEmpresasComponent } from './components/seguros-empresas/seguros-empresas.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component';
import { PoliticaPrivacidadComponent } from './components/politica-privacidad/politica-privacidad.component';
import { DerechosArcoComponent } from './components/derechos-arco/derechos-arco.component';

import { ResultadosSaludComponent } from './components/cotizadores/salud/resultados-salud/resultados-salud.component';
import { ActualizacionDatosComponent } from './components/actualizacion-datos/actualizacion-datos.component';
import { GraciasActualizacionComponent } from './components/gracias-actualizacion/gracias-actualizacion.component';


// import { CotizadoresComponent } from './components/cotizadores/cotizadores.component';
// import { GraciasCotizadorComponent } from './components/cotizadores/gracias-cotizador/gracias-cotizador.component';
// import { CotizadorSoatComponent } from './components/cotizadores/soat/cotizador-soat/cotizador-soat.component';
// import { FormDatosSoatComponent } from './components/cotizadores/soat/form-datos-soat/form-datos-soat.component';
// import { GraciasSoatComponent } from './components/cotizadores/soat/gracias-soat/gracias-soat.component';
// import { CotizadorSalud2Component } from './components/cotizadores/salud/cotizador-salud2/cotizador-salud2.component';
// import { GraciasSaludComponent } from './components/cotizadores/salud/gracias-salud/gracias-salud.component';
// import { CotizadorSoatMotoComponent } from './components/cotizadores/soatMotos/cotizador-soat-moto/cotizador-soat-moto.component';
// import { CotizadorVehicularMotoComponent } from './components/cotizadores/vehicularMotos/cotizador-vehicular-moto/cotizador-vehicular-moto.component';
// import { CotizadorSoatAutoComponent } from './components/cotizadores/soatAutos/cotizador-soat-auto/cotizador-soat-auto.component';
// import { VehicularComponent } from './components/cotizadores/vehicular/vehicular.component';



// import { LoginComponent } from './components/login/login.component';
import { SoatComponent } from './components/campanas/soat/soat.component';
import { TerminosCondicionesComponent } from './components/terminos-condiciones/terminos-condiciones.component';

import { DetallePromocionComponent } from './components/detalle-promocion/detalle-promocion.component';
import { PromocionesComponent } from './components/promociones/promociones.component';
import { BeneficiosListaComponent } from './components/beneficios-lista/beneficios-lista.component';

import { SoatCampaingComponent } from './components/soat-campaing/soat-campaing.component';
import { CardDetalleComponent } from './components/card-detalle/card-detalle.component';
import { ChequeoPreventivoComponent } from './components/chequeo-preventivo/chequeo-preventivo.component';
import { VentaDigitalComponent } from './components/venta-digital/venta-digital.component';
import { LoginComponent } from './components/login/login.component';
import { AdminSegurosComponent } from './components/admin-seguros/admin-seguros.component';
import { ActualizacionDatosAdminComponent } from './components/admin/actualizacion-datos/actualizacion-datos-admin/actualizacion-datos-admin.component';
import { AdminComponent } from './components/admin/admin.component';
import { CrearAseguradoraComponent } from './components/admin/aseguradoras/crear-aseguradora/crear-aseguradora.component';
import { ListaAseguradorasComponent } from './components/admin/aseguradoras/lista-aseguradoras/lista-aseguradoras.component';
import { CrearBannerComponent } from './components/admin/banners/crear-banner/crear-banner.component';
import { ListaBannersComponent } from './components/admin/banners/lista-banners/lista-banners.component';
import { CrearBeneficioComponent } from './components/admin/beneficios/crear-beneficio/crear-beneficio.component';
import { ListaBeneficiosComponent } from './components/admin/beneficios/lista-beneficios/lista-beneficios.component';
import { InicioAdminComponent } from './components/admin/inicio-admin/inicio-admin.component';
import { CrearReporteBiComponent } from './components/admin/reportesBi/crear-reporte-bi/crear-reporte-bi.component';
import { ListaReportesBiComponent } from './components/admin/reportesBi/lista-reportes-bi/lista-reportes-bi.component';
import { CrearCotizacionSaludComponent } from './components/cotizadores/salud/admin/crear-cotizacion-salud/crear-cotizacion-salud.component';
import { ListaSolicitudesSaludComponent } from './components/cotizadores/salud/admin/lista-solicitudes-salud/lista-solicitudes-salud.component';
import { DetalleSolicitudComponent } from './components/cotizadores/soat/admin/detalle-solicitud/detalle-solicitud.component';
import { ListaSolicitudesComponent } from './components/cotizadores/soat/admin/lista-solicitudes/lista-solicitudes.component';
import { CrearPrimaComponent } from './components/crear-prima/crear-prima.component';
import { CrearSeguroComponent } from './components/crear-seguro/crear-seguro.component';

import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);



const routes: Routes = [
  {
    path: '', component: PublicComponent,
    children: [
      { path: '', component: InicioComponent },
      { path: 'segurosPersonas', component: SegurosPersonasComponent },
      { path: 'segurosEmpresas', component: SegurosEmpresasComponent },
      { path: 'segurosPersonas/:id', component: DetalleSeguroComponent },
      { path: 'segurosEmpresas/:id', component: DetalleSeguroComponent },
      { path: 'libroReclamaciones', component: LibroReclamacionesComponent },
      { path: 'promociones', component: PromocionesComponent },
      { path: 'promociones/:id', component: DetallePromocionComponent },
      { path: 'beneficios', component: BeneficiosListaComponent },
      { path: 'nosotros', component: NosotrosComponent },
      { path: 'contacto', component: ContactoComponent },
      { path: 'politicaPrivacidad', component: PoliticaPrivacidadComponent },
      { path: 'terminosCondiciones', component: TerminosCondicionesComponent },
      { path: 'actualizacionDatos', component: ActualizacionDatosComponent },
      { path: 'actualizacionDatos/:id', component: GraciasActualizacionComponent },
      { path: 'derechosArco', component: DerechosArcoComponent },
      { path: 'cotizacion/salud/:id', component: ResultadosSaludComponent },
      { path: 'cotizadores', loadChildren: () => import('./components/cotizadores/cotizadores.module').then( m => m.CotizadoresModule) },
      // { path: 'cotizadores', component: CotizadoresComponent },
      // { path: 'cotizadores/salud', component: CotizadorSalud2Component },
      // { path: 'cotizadores/salud/:id/gracias', component: GraciasSaludComponent },
      // { path: 'cotizadores/soat', component: CotizadorSoatComponent },
      // { path: 'cotizadores/soat/:id', component: FormDatosSoatComponent },
      // { path: 'cotizadores/soat/:id/gracias', component: GraciasSoatComponent },
      // { path: 'cotizadores/soatMoto', component: CotizadorSoatMotoComponent },
      // { path: 'cotizadores/soatAuto', component: CotizadorSoatAutoComponent },
      // { path: 'cotizadores/vehicularMoto', component: CotizadorVehicularMotoComponent },
      // { path: 'cotizadores/:id/gracias', component: GraciasCotizadorComponent },
      // { path: 'cotizadores/vehicular', component: VehicularComponent },
      { path: 'campanas/soat', component: SoatComponent },
      { path: 'soat', component: SoatCampaingComponent },
      { path: 'landings/chequeoPreventivo', component: ChequeoPreventivoComponent },
     
      
    ]
  },

  { path: 'ventaDigital', component: VentaDigitalComponent },
 
  { path: 'cards/:id', component: CardDetalleComponent },
  { path: 'login', component: LoginComponent },


  // {
  //   path: 'landings', component: LanzamientosComponent,
  //   children: [
  //     { path: 'seguroOncologico', component: SeguroOncologicoComponent },
  //     { path: 'chequeoPreventivo', component: ChequeoPreventivoComponent },
  //   ]
  // },



  {
    path: 'admin', component: AdminComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      { path: '', component: InicioAdminComponent },
      { path: 'crearSeguro', component: CrearSeguroComponent },
      { path: 'seguros', component: AdminSegurosComponent },
      { path: 'crearPrima', component: CrearPrimaComponent },
      { path: 'banners', component: ListaBannersComponent },
      { path: 'banners/crearBanner', component: CrearBannerComponent },
      { path: 'beneficios', component: ListaBeneficiosComponent },
      { path: 'beneficios/crearBeneficio', component: CrearBeneficioComponent },
      { path: 'aseguradoras', component: ListaAseguradorasComponent },
      { path: 'aseguradoras/crearAseguradora', component: CrearAseguradoraComponent },
      { path: 'actualizacionDatos', component: ActualizacionDatosAdminComponent },
      { path: 'reportes', component: ListaReportesBiComponent },
      { path: 'reportes/crearReporte', component: CrearReporteBiComponent },
      { path: 'solicitudesSoat', component: ListaSolicitudesComponent },
      { path: 'solicitudesSoat/:id', component: DetalleSolicitudComponent },
      { path: 'solicitudesSalud', component: ListaSolicitudesSaludComponent },
      { path: 'solicitudesSalud/crear', component: CrearCotizacionSaludComponent },
    ]
  },

  { path: '**', pathMatch: 'full', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: "reload",
    relativeLinkResolution: 'legacy',
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
