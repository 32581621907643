<!-- <div class="container py-3 py-md-5">
        <div class="p-4 border rounded d-flex justify-content-between">
            <p class="h4 text-primary mb-0">Actualización de datos</p>
            <a class="btn btn-primary" routerLink="/admin/actualizacionDatos">Ir a sección</a>
        </div>
</div> -->

<div class="container py-4 py-5">
  <div class="grillaSeccionesAdmin">
    <div *ngFor="let seccion of seccionesAdmin" class="bg-light rounded-3 p-3 p-md-4 position-relative w-100">
      <div class="d-flex align-items-center justify-content-between">
        <p class="lead mb-0">{{seccion.nombre}}</p>
        <div class="text-end">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-right" width="36" height="36" viewBox="0 0 24 24" stroke-width="1" stroke="#7960E0" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <line x1="5" y1="12" x2="19" y2="12" />
            <line x1="13" y1="18" x2="19" y2="12" />
            <line x1="13" y1="6" x2="19" y2="12" />
          </svg>
        </div>
      </div>
      
      <a class="stretched-link" [routerLink]="seccion.url"></a>
    </div>
  </div>
</div>