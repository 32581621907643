<div class="container py-2 py-md-3">
    <nav>
        <ol class="breadcrumb small">
            <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
            <li class="breadcrumb-item active">Seguros para Personas</li>
        </ol>
    </nav>
    <p class="h2 mb-3 mb-md-4 font-weight-light">Seguros para Personas</p>
    <div class="grillaSeguros">
        <app-card-seguro *ngFor="let seguro of segurosPersonas | async" [seguro]="seguro"></app-card-seguro>
    </div>
</div>