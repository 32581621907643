<div class="p-1 p-md-3">

  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="mt-3 mb-2">
    <p class="h3 mb-0">Copagos en {{data.item_.aseguradora}}</p>
    <p class="small">{{data.formVehicularMoto_.get('marca').value}} - {{data.formVehicularMoto_.get('anioFabricacion').value}} </p>

    <div class="small" *ngFor="let copago of data.item_.copagos; last as last">
      <p class="mb-0">
        <span class="text-muted">{{copago.cobertura}}: </span>
        <span>{{copago.copago}}</span>
      </p>
      <mat-divider class="my-2" *ngIf="!last"></mat-divider>
    </div>

  </div>


  <!-- <div class="d-grid gap-2 w-100 mb-1">
    <a class="btn btn-success"
      href="https://api.whatsapp.com/send?phone=51998161616&text=Hola Jaime, te escribo desde la web de La Previsora. Estoy interesado en un seguro para mi moto {{data.formSoatMoto_.get('marca').value}}, año {{data.formSoatMoto_.get('anioFabricacion').value}} con un valor comercial de USD {{data.formSoatMoto_.get('valorComercial').value}}"
      target="_blank" (click)="clickWhatsApp()">Pídelo por WhatsApp</a>
    <a class="btn btn-light fw-normal" href="tel:998161616" target="_blank" (click)="clickLlamada()">Llámanos al
      998161616</a>
  </div> -->


</div>