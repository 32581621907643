<div class="p-1 p-md-3">

  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="mt-3 mb-2">
    <!-- <p class="h5">Solicita el SOAT para tu moto {{data.formSoatMoto_.get('marca').value}} - {{data.formSoatMoto_.get('modelo').value}} con {{data.item_.aseguradora}} asolo S/. {{data.item_.prima}}</p> -->
    <!-- <p>{{data.formSoatMoto_.get('marca').value}} - {{data.formSoatMoto_.get('modelo').value}} </p> -->
    <!-- <p class="small">Te explicamos todos los detalles de tu seguro.</p> -->

    <p class="h3">Solicita el seguro para tu moto</p>
    <div class="small">
      <div>
        <span class="text-muted">Marca: </span>
        <span>{{data.formVehicularMoto_.get('marca').value}}</span>
      </div>
      <div>
        <span class="text-muted">Año de fabricación: </span>
        <span>{{data.formVehicularMoto_.get('anioFabricacion').value}}</span>
      </div>
      <div>
        <span class="text-muted">Valor comercial: </span>
        <span>USD {{data.formVehicularMoto_.get('valorComercial').value}}</span>
      </div>
    </div>
  </div>


  <div class="d-grid gap-2 w-100 mb-1">
    <a class="btn btn-success"
      href="https://api.whatsapp.com/send?phone=51998161616&text=Hola Jaime, te escribo desde la web de La Previsora. Estoy interesado en un seguro para mi moto {{data.formVehicularMoto_.get('marca').value}}, año {{data.formVehicularMoto_.get('anioFabricacion').value}} con un valor comercial de USD {{data.formVehicularMoto_.get('valorComercial').value}}"
      target="_blank" (click)="clickWhatsApp()">Pídelo por WhatsApp</a>
    <a class="btn btn-light fw-normal" href="tel:998161616" target="_blank" (click)="clickLlamada()">Llámanos al
      998161616</a>
    <button class="btn btn-light fw-normal" (click)="openModalContact()">Déjanos tus datos</button>
  </div>


</div>