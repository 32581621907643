import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { Timestamp } from 'firebase/firestore';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-crear-beneficio',
  templateUrl: './crear-beneficio.component.html',
  styleUrls: ['./crear-beneficio.component.scss']
})
export class CrearBeneficioComponent implements OnInit {

  formBeneficio: UntypedFormGroup;
  aseguradoras;
  deshabilitado: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private fs: FirebaseService,
    private afs: AngularFirestore,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.formBeneficio = this.fb.group({
      titulo: ['', Validators.required],
      descripciones: new UntypedFormArray ([
        new UntypedFormControl ('', [])
      ]),
      aseguradoras: ['', Validators.required],
      publicado: [false],
      fechaCreacion: [Timestamp.now()],
    });

    this.aseguradoras = this.fs.getAseguradoras();
  }


  onSubmit() {
    if (this.formBeneficio.valid) {
      this.crearBeneficio();
      this.deshabilitado = true;
    } else {
      this.validateAllFormFields(this.formBeneficio);
      this.deshabilitado = false;
    }
  }

  crearBeneficio() {
    this.afs.collection('beneficios').add(this.formBeneficio.value)
    .then( () => {
      this.router.navigate(['/admin/beneficios'])
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }



  agregarDescripcion() {
    (this.formBeneficio.controls.descripciones as UntypedFormArray).push(
      new UntypedFormControl('', [Validators.required])
    );
  }

  eliminarDescripcion(index: number): void {
    (this.formBeneficio.controls.descripciones as UntypedFormArray).removeAt(index);
  }

}
