<mat-expansion-panel class="myBodyAcordion">
  <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
    <mat-panel-title>
      <!-- <p>{{item.tipo}}</p> -->
    </mat-panel-title>
    <mat-panel-description class="w-100 me-3">
      <p class="planSalud mb-0">{{item.tipo}}</p>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="listaCaracteristicas mt-3">
    <table class="table ">
      <thead>
        <tr class="">
          <th scope="col">Clínica</th>
          <th scope="col">Copago</th>
          <th scope="col">Cobertura</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let clinica of item.establecimientos">
          <td>{{clinica.clinica}}</td>
          <td>{{clinica.copago}}</td>
          <td>{{clinica.cobertura}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-expansion-panel>