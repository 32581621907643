<div class="bgCarousel">

  <div class="">
    <swiper [config]="config">
        <ng-template swiperSlide *ngFor="let slide of banners">

          <div class="container">

            <div class="row d-flex justify-content-between align-items-center">

              <div class="col-md-5 boxCaption">
                <div class="py-4 py-md-5">
                  <p class="gorro">{{slide.gorro}}</p>
                  <p class="titulo">{{slide.titulo}}</p>
                  <p class="subtitulo">{{slide.subtitulo}}</p>
                  <a class="btn btn-success" [routerLink]=" '/promociones/' + slide.id " *ngIf="!slide.external">Conoce más</a>
                  <a class="btn btn-success" href="https://www.vivirseguros.pe/vivemax/inicio?sale=0X2022A12M21D1603" target="_blank" *ngIf="slide.external">Conoce más</a>
                </div>
              </div>

              <div class="col-md-5 boxImage">
                <img class="imgSlide" [src]="slide.image">
              </div>

            </div>

          </div>

        </ng-template>
      <!-- <div class="swiper-pagination"></div> -->
    </swiper>
  </div>



</div>

<div class="mt-2 mt-md-3 text-center">
  <a class="btn btn-link" routerLink="/promociones">Ver todas las promociones
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-right ms-2" width="24" height="24"
      viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round"
      stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <line x1="5" y1="12" x2="19" y2="12" />
      <line x1="13" y1="18" x2="19" y2="12" />
      <line x1="13" y1="6" x2="19" y2="12" />
    </svg>
  </a>
</div>