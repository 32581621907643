<div class="container py-3 py-md-4">

    <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-center ">
        <p class="h2">Solicitudes SOAT</p>
        <mat-form-field appearance="outline" class="myWidhtField">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="value">
            <mat-icon *ngIf="!value" matSuffix>search</mat-icon>
            <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilters()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>


    <div class="d-flex justify-content-center align-items-center" style="height: 400px;" *ngIf="loader">
        <span class="myColorSpinnerSoatDetalle ">
            <mat-spinner class="text-center" [diameter]="36"></mat-spinner>
        </span>
    </div>


    <div class="table-responsive">

        <table mat-table [dataSource]="dataSolicitudesSoat" matSort class="example-table">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Código</th>
                <td mat-cell *matCellDef="let item" class="text-monospace">{{item.id}}</td>
            </ng-container>

            <ng-container matColumnDef="dataUsuario.fechaCreacion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha solicitud</th>
                <td mat-cell *matCellDef="let item"> {{ item.dataUsuario.fechaCreacion.toDate() | date: 'medium' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="dataUsuario.nombres">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombres</th>
                <td mat-cell *matCellDef="let item" class="">{{item.dataUsuario.nombres}}</td>
            </ng-container>

            <ng-container matColumnDef="dataAseguradora.aseguradora">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Aseguradora</th>
                <td mat-cell *matCellDef="let item" class="">{{item.dataAseguradora.aseguradora}}</td>
            </ng-container>

            <ng-container matColumnDef="dataAseguradora.precio">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Precio</th>
                <td mat-cell *matCellDef="let item" class="">S/. {{item.dataAseguradora.precio}}</td>
            </ng-container>

            <ng-container matColumnDef="detalles">
                <th mat-header-cell *matHeaderCellDef class="hideColumnXs"></th>
                <td mat-cell *matCellDef="let item" class="hideColumnXs text-right">
                    <a class="btn btn-light btn-sm text-primary" [routerLink]="'/admin/solicitudesSoat/' + item.id">
                        Ver detalles
                    </a>
                </td>
            </ng-container>


            <ng-container matColumnDef="eliminar">
                <th mat-header-cell *matHeaderCellDef>Eliminar</th>
                <td mat-cell *matCellDef="let item">
                    <button mat-icon-button (click)="openModalDelete(item.id)">
                        <mat-icon class="text-muted material-icons-outlined">delete</mat-icon>
                    </button>
                </td>
            </ng-container>



            <ng-container matColumnDef="mas">
                <th mat-header-cell *matHeaderCellDef class=""></th>
                <td mat-cell *matCellDef="let item" class="text-right">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon class="material-icons-outlined">more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">

                        <button mat-menu-item (click)="leerSolicitud(item.id)">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-text mr-2"
                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#757575" fill="none"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                <line x1="9" y1="9" x2="10" y2="9" />
                                <line x1="9" y1="13" x2="15" y2="13" />
                                <line x1="9" y1="17" x2="15" y2="17" />
                            </svg>
                            <span>Ver detalles</span>
                        </button>

                        <mat-divider></mat-divider>

                        <button mat-menu-item (click)="openModalDelete(item.id)">
                            <!-- <mat-icon class="material-icons-outlined text-danger">delete</mat-icon> -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash mr-2"
                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#d9453d" fill="none"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <line x1="4" y1="7" x2="20" y2="7" />
                                <line x1="10" y1="11" x2="10" y2="17" />
                                <line x1="14" y1="11" x2="14" y2="17" />
                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                            </svg>
                            <span class="text-danger">Eliminar solicitud SOAT</span>
                        </button>

                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
    </mat-paginator>





</div>