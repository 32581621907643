<!-- <div class="col-md-10 mx-auto py-3 py-md-4">
    <p class="h3 mb-3">Reportes BI</p>
    <div class="grillaReportes">
        <div *ngFor="let reporte of reportes | async" class="border rounded px-3 py-2 d-flex justify-content-between shadow-sm">
            <div class="d-flex">
                <div class="mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-database" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#41D4C1 " fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <ellipse cx="12" cy="6" rx="8" ry="3"></ellipse>
                  <path d="M4 6v6a8 3 0 0 0 16 0v-6" />
                  <path d="M4 12v6a8 3 0 0 0 16 0v-6" />
                </svg>
                </div>
                <div>
                    <p class="h5 mb-0">{{reporte.titulo}}</p>
                    <p class="mb-0 text-muted" style="line-height: 120%;">{{reporte.descripcion}}</p>
                    <a class="stretched-link" [href]="reporte.url" target="blank"></a>
                </div>
            </div>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-external-link" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
                <line x1="10" y1="14" x2="20" y2="4" />
                <polyline points="15 4 20 4 20 9" />
              </svg>
            </div>
        </div>
    </div>
</div> -->



<div class="col-md-10 mx-auto py-3 py-md-4">

    <div class="d-flex justify-content-between align-items-center mb-2 mb-md-3">
        <span class="h4
         mb-0">Reportes Bi</span>
        <!-- <div>
            <button class="btn btn-primary" (click)="openModalCrear()"><mat-icon>add</mat-icon> Crear</button>
        </div> -->
    </div>

    <!-- <div class="">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="value" placeholder="Ingresa una palabra">
            <mat-icon *ngIf="!value" matSuffix>search</mat-icon>
            <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilters()">
                    <mat-icon>close</mat-icon>
                </button>
        </mat-form-field>
    </div> -->




    <div class="table-responsive">

        <table mat-table [dataSource]="dataReportes" matSort class="example-table" [trackBy]="trackByPublicado">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Código</th>
                <td mat-cell *matCellDef="let item">{{item.id}}</td>
            </ng-container>


            <ng-container matColumnDef="titulo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Título</th>
                <td mat-cell *matCellDef="let item">{{item.titulo}}</td>
            </ng-container>

            <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">Descripción</th>
                <td mat-cell *matCellDef="let item" class="hideColumn">{{item.descripcion}}</td>
            </ng-container>

            <ng-container matColumnDef="url">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Reporte</th>
                <td mat-cell *matCellDef="let item">
                    <a class="text-decoration-none" [href]="item.url" target="blank">Ver reporte
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-text" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                            <line x1="9" y1="9" x2="10" y2="9" />
                            <line x1="9" y1="13" x2="15" y2="13" />
                            <line x1="9" y1="17" x2="15" y2="17" />
                          </svg>
                    </a>
                </td>
            </ng-container>



            <!-- <ng-container matColumnDef="detalles">
                <th mat-header-cell *matHeaderCellDef class="hideColumn">Detalles</th>
                <td mat-cell *matCellDef="let item" class="hideColumn">
                    <button mat-icon-button (click)="openModalDetalle(item)">
                <mat-icon class="material-icons-outlined text-muted">remove_red_eye</mat-icon>
            </button>
                </td>
            </ng-container> -->

            <ng-container matColumnDef="editar">
                <th mat-header-cell *matHeaderCellDef class="hideColumn">Editar</th>
                <td mat-cell *matCellDef="let item" class="hideColumn">
                    <button mat-icon-button (click)="openModalEdit(item)">
              <mat-icon class="material-icons-outlined text-muted">edit</mat-icon>
          </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="eliminar">
                <th mat-header-cell *matHeaderCellDef class="hideColumn">Eliminar</th>
                <td mat-cell *matCellDef="let item" class="hideColumn">
                    <button mat-icon-button (click)="openModalDelete(item)">
                        <mat-icon class="material-icons-outlined text-muted">delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="mas">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item" class="text-right">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon class="material-icons-outlined">more_vert</mat-icon>
                      </button>
                    <mat-menu #menu="matMenu">
                        <!-- <a class="text-decoration-none text-reset" mat-menu-item [href]="item.url" target="blank">
                            <mat-icon>remove_red_eye</mat-icon>
                            <span>Ver reporte</span>
                        </a> -->
                        <!-- <button mat-menu-item (click)="openModalEdit(item)">
                          <mat-icon>edit</mat-icon>
                          <span>Editar</span>
                        </button> -->
                        <button mat-menu-item (click)="openModalDelete(item)">
                          <mat-icon>delete</mat-icon>
                          <span>Eliminar</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
    </mat-paginator>





</div>