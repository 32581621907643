import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DetallePrimaComponent } from '../detalle-prima/detalle-prima.component';

@Component({
  selector: 'app-resultados-salud',
  templateUrl: './resultados-salud.component.html',
  styleUrls: ['./resultados-salud.component.scss']
})
export class ResultadosSaludComponent implements OnInit {

  idCotizacion;
  cotizacion;
  primaTitular;
  primaConyuge;
  primaFamiliar = [];
  primaFamiliarSingle = [];
  primaFilter = [];
  primaFilterArray = [];
  primaFamiliarFilter = [];
  primaFamiliarFilter2 = [];
  totalSumPrimasFamiliares = 0;
  a = [];
  primasSalud = [];
  primasSaludFilter = [];

  pacifico = 'Pacifico';
  rimac = 'Rimac';
  mapfre = 'Mapfre';

  primasTotal = [];

  primasInternacional = [];
  primasNacional = [];
  primasNacionalSinReembolso = [];
  primasNacionalClinicasAcotadas = [];

  medicvidaInternacionalPrimas;
  medicvidaInternacionalArray = [];
  medicvidaInternacionalSumFam = 0;
  medicvidaInternacionalFam = [];
  medicvidaInternacionalLogo = 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoPacifico.svg?alt=media&token=bb52ed5e-dd51-4adf-a775-1aa903e01901';

  saludPreferencialPrimas;
  saludPreferencialArray = [];
  saludPreferencialSumFam = 0;
  saludPreferencialFam = [];
  saludPreferencialLogo = 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoRimac.svg?alt=media&token=c51c72ea-e0b5-4ba3-93c6-7b7e69b8b969';

  internacionalPrevisoraPrimas;
  internacionalPrevisoraArray = [];
  internacionalPrevisoraSumFam = 0;
  internacionalPrevisoraFam = [];
  internacionalPrevisoraLogo = 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoMapfre.svg?alt=media&token=e48e4fe1-10d1-4c02-885a-1609573eaea5';

  medicvidaNacionalPrimas;
  medicvidaNacionalArray = [];
  medicvidaNacionalSumFam = 0;
  medicvidaNacionalFam = [];
  medicvidaNacionalLogo = 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoPacifico.svg?alt=media&token=bb52ed5e-dd51-4adf-a775-1aa903e01901';

  fullsaludPrimas;
  fullsaludArray = [];
  fullsaludSumFam = 0;
  fullsaludFam = [];
  fullsaludLogo = 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoRimac.svg?alt=media&token=c51c72ea-e0b5-4ba3-93c6-7b7e69b8b969';

  multisaludPrimas;
  multisaludArray = [];
  multisaludSumFam = 0;
  multisaludFam = [];
  multisaludLogo = 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoPacifico.svg?alt=media&token=bb52ed5e-dd51-4adf-a775-1aa903e01901';

  redMedicaPrimas;
  redMedicaArray = [];
  redMedicaSumFam = 0;
  redMedicaFam = [];
  redMedicaLogo = 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoRimac.svg?alt=media&token=c51c72ea-e0b5-4ba3-93c6-7b7e69b8b969';

  trebolSaludPrimas;
  trebolSaludArray = [];
  trebolSaludSumFam = 0;
  trebolSaludFam = [];
  trebolSaludLogo = 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoMapfre.svg?alt=media&token=e48e4fe1-10d1-4c02-885a-1609573eaea5';

  redPreferentePacificoPrimas;
  redPreferentePacificoArray = [];
  redPreferentePacificoSumFam = 0;
  redPreferentePacificoFam = [];
  redPreferentePacificoLogo = 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoPacifico.svg?alt=media&token=bb52ed5e-dd51-4adf-a775-1aa903e01901';

  redPreferenteRimacPrimas;
  redPreferenteRimacArray = [];
  redPreferenteRimacSumFam = 0;
  redPreferenteRimacFam = [];
  redPreferenteRimacLogo = 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoRimac.svg?alt=media&token=c51c72ea-e0b5-4ba3-93c6-7b7e69b8b969';

  vivaSaludPrimas;
  vivaSaludArray = [];
  vivaSaludSumFam = 0;
  vivaSaludFam = [];
  vivaSaludLogo = 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoMapfre.svg?alt=media&token=e48e4fe1-10d1-4c02-885a-1609573eaea5';

  vivaSaludPlusPrimas;
  vivaSaludPlusArray = [];
  vivaSaludPlusSumFam = 0;
  vivaSaludPlusFam = [];
  vivaSaludPlusLogo = 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoMapfre.svg?alt=media&token=e48e4fe1-10d1-4c02-885a-1609573eaea5';

  primasResult;
  primasResult2;



  constructor(
    private fs: FirebaseService,
    private activatedRoute: ActivatedRoute,
    private afs: AngularFirestore,
    public dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    this.getCotizacionDetails();
    // this.primasResult = this.primasResult();

    // this.traerPrima();
  }

  getCotizacionDetails() {
    const id = this.activatedRoute.params.subscribe(params => {
      this.idCotizacion = params.id;

      this.fs.getCotizacionSalud(this.idCotizacion).subscribe(res => {
        this.cotizacion = res;
        console.log(this.cotizacion.edadTitular);

        // Traer prima Titular
        this.fs.getPrima(this.cotizacion.edadTitular).subscribe( res2 => {
          this.primaTitular = res2;
          this.primasSalud = [
            {
              nombre: 'Medicvida Internacional',
              logo: this.medicvidaInternacionalLogo,
              edadTitular: this.cotizacion.edadTitular,
              primaTitular: this.primaTitular.medicvidaInternacional,
              total: this.primaTitular.medicvidaInternacional,
              internacional: true
            },
            {
              nombre: 'Salud Preferencial',
              logo: this.saludPreferencialLogo,
              edadTitular: this.cotizacion.edadTitular,
              primaTitular: this.primaTitular.saludPreferencial,
              total: this.primaTitular.saludPreferencial,
              internacional: true
            },
            {
              nombre: 'Internacional Previsora',
              logo: this.internacionalPrevisoraLogo,
              edadTitular: this.cotizacion.edadTitular,
              primaTitular: this.primaTitular.internacionalPrevisora,
              total: this.primaTitular.internacionalPrevisora,
              internacional: true
            },
            {
              nombre: 'Medicvida Nacional',
              logo: this.medicvidaNacionalLogo,
              edadTitular: this.cotizacion.edadTitular,
              primaTitular: this.primaTitular.medicvidaNacional,
              total: this.primaTitular.medicvidaNacional,
              nacional: true,
            },
            {
              nombre: 'Fullsalud',
              logo: this.fullsaludLogo,
              edadTitular: this.cotizacion.edadTitular,
              primaTitular: this.primaTitular.fullsalud,
              total: this.primaTitular.fullsalud,
              nacional: true,
            },
            {
              nombre: 'Trebol Salud',
              logo: this.trebolSaludLogo,
              edadTitular: this.cotizacion.edadTitular,
              primaTitular: this.primaTitular.trebolSalud,
              total: this.primaTitular.trebolSalud,
              nacional: true,
            },
            {
              nombre: 'Multisalud',
              logo: this.multisaludLogo,
              edadTitular: this.cotizacion.edadTitular,
              primaTitular: this.primaTitular.multisalud,
              total: this.primaTitular.multisalud,
              nacionalSinReembolso: true

            },
            {
              nombre: 'Red Médica',
              logo: this.redMedicaLogo,
              edadTitular: this.cotizacion.edadTitular,
              primaTitular: this.primaTitular.trebolSalud,
              total: this.primaTitular.trebolSalud,
              nacionalSinReembolso: true
            },
            {
              nombre: 'Viva Salud Plus',
              logo: this.vivaSaludPlusLogo,
              edadTitular: this.cotizacion.edadTitular,
              primaTitular: this.primaTitular.vivaSaludPlus,
              total: this.primaTitular.vivaSaludPlus,
              nacionalSinReembolso: true
            },
            {
              nombre: 'Red Preferente Pacífico',
              logo: this.redPreferentePacificoLogo,
              edadTitular: this.cotizacion.edadTitular,
              primaTitular: this.primaTitular.redPreferentePacifico,
              total: this.primaTitular.redPreferentePacifico,
              nacionalClinicasAcotadas: true
            },
            {
              nombre: 'Red Preferente Rimac',
              logo: this.redPreferenteRimacLogo,
              edadTitular: this.cotizacion.edadTitular,
              primaTitular: this.primaTitular.redPreferenteRimac,
              total: this.primaTitular.redPreferenteRimac,
              nacionalClinicasAcotadas: true
            },
            {
              nombre: 'Viva Salud',
              logo: this.vivaSaludLogo,
              edadTitular: this.cotizacion.edadTitular,
              primaTitular: this.primaTitular.vivaSalud,
              total: this.primaTitular.vivaSalud,
              nacionalClinicasAcotadas: true
            },

          ];
          // this.primasResult = of(this.primasSalud.sort((a, b) => a.total < b.total ? -1 : a.total > b.total ? 1 : 0));
          this.primasResult = of(this.primasSalud.filter( prima =>
              prima.internacional === false && prima.nacional === false && prima.nacionalSinReembolso === false && prima.nacionalClinicasAcotadas === true
          ));

          this.primasInternacional = this.primasSalud.filter( prima => prima.internacional === this.cotizacion.categorias.internacional);
          this.primasNacional = this.primasSalud.filter( prima => prima.nacional === this.cotizacion.categorias.nacional);
          this.primasNacionalSinReembolso = this.primasSalud.filter( prima => prima.nacionalSinReembolso === this.cotizacion.categorias.nacionalSinReembolso);
          this.primasNacionalClinicasAcotadas = this.primasSalud.filter( prima => prima.nacionalClinicasAcotadas === this.cotizacion.categorias.nacionalClinicasAcotadas);

        });

        this.cotizacion.familiares.forEach( familiar => {
          this.fs.getPrima(familiar.edad).subscribe( (prima: any) => {

            // Unir prima + datos familia
            this.primaFamiliar.push({familia: familiar, primas: prima});
            console.log(this.primaFamiliar);

            this.medicvidaInternacionalFam.push({
              vinculo: familiar.vinculo,
              edad: familiar.edad,
              prima: prima.medicvidaInternacional
            });

            this.saludPreferencialFam.push({
              vinculo: familiar.vinculo,
              edad: familiar.edad,
              prima: prima.medicvidaInternacional
            });

            this.internacionalPrevisoraFam.push({
              vinculo: familiar.vinculo,
              edad: familiar.edad,
              prima: prima.internacionalPrevisora
            });

            this.medicvidaNacionalFam.push({
              vinculo: familiar.vinculo,
              edad: familiar.edad,
              prima: prima.medicvidaNacional
            });

            this.fullsaludFam.push({
              vinculo: familiar.vinculo,
              edad: familiar.edad,
              prima: prima.fullsalud
            });

            this.multisaludFam.push({
              vinculo: familiar.vinculo,
              edad: familiar.edad,
              prima: prima.multisalud
            });

            this.redMedicaFam.push({
              vinculo: familiar.vinculo,
              edad: familiar.edad,
              prima: prima.redMedica
            });

            this.trebolSaludFam.push({
              vinculo: familiar.vinculo,
              edad: familiar.edad,
              prima: prima.trebolSalud
            });

            this.redPreferentePacificoFam.push({
              vinculo: familiar.vinculo,
              edad: familiar.edad,
              prima: prima.redPreferentePacifico
            });

            this.redPreferenteRimacFam.push({
              vinculo: familiar.vinculo,
              edad: familiar.edad,
              prima: prima.redPreferenteRimac
            });

            this.vivaSaludFam.push({
              vinculo: familiar.vinculo,
              edad: familiar.edad,
              prima: prima.vivaSalud
            });

            this.vivaSaludPlusFam.push({
              vinculo: familiar.vinculo,
              edad: familiar.edad,
              prima: prima.vivaSaludPlus
            });


            // Filtrar primas
            this.primaFamiliar.filter( resu => {
              this.medicvidaInternacionalPrimas = resu.primas.medicvidaInternacional;
              this.saludPreferencialPrimas = resu.primas.saludPreferencial;
              this.internacionalPrevisoraPrimas = resu.primas.internacionalPrevisora;
              this.medicvidaNacionalPrimas = resu.primas.medicvidaNacional;
              this.fullsaludPrimas = resu.primas.fullsalud;
              this.multisaludPrimas = resu.primas.multisalud;
              this.redMedicaPrimas = resu.primas.redMedica;
              this.trebolSaludPrimas = resu.primas.trebolSalud;
              this.redPreferentePacificoPrimas = resu.primas.redPreferentePacifico;
              this.redPreferenteRimacPrimas = resu.primas.redPreferenteRimac;
              this.vivaSaludPrimas = resu.primas.vivaSalud;
              this.vivaSaludPlusPrimas = resu.primas.vivaSaludPlus;
            });

            this.medicvidaInternacionalArray.push(this.medicvidaInternacionalPrimas);
            this.medicvidaInternacionalSumFam = this.medicvidaInternacionalArray.reduce((partialsum, a) => partialsum + a, 0);

            this.saludPreferencialArray.push(this.saludPreferencialPrimas);
            this.saludPreferencialSumFam = this.saludPreferencialArray.reduce((partialsum, a) => partialsum + a, 0);

            this.internacionalPrevisoraArray.push(this.internacionalPrevisoraPrimas);
            this.internacionalPrevisoraSumFam = this.internacionalPrevisoraArray.reduce((partialsum, a) => partialsum + a, 0);

            this.medicvidaNacionalArray.push(this.medicvidaNacionalPrimas);
            this.medicvidaNacionalSumFam = this.medicvidaNacionalArray.reduce((partialsum, a) => partialsum + a, 0);

            this.fullsaludArray.push(this.fullsaludPrimas);
            this.fullsaludSumFam = this.fullsaludArray.reduce((partialsum, a) => partialsum + a, 0);

            this.multisaludArray.push(this.multisaludPrimas);
            this.multisaludSumFam = this.multisaludArray.reduce((partialsum, a) => partialsum + a, 0);

            this.redMedicaArray.push(this.redMedicaPrimas);
            this.redMedicaSumFam = this.redMedicaArray.reduce((partialsum, a) => partialsum + a, 0);

            this.trebolSaludArray.push(this.trebolSaludPrimas);
            this.trebolSaludSumFam = this.trebolSaludArray.reduce((partialsum, a) => partialsum + a, 0);

            this.redPreferentePacificoArray.push(this.redPreferentePacificoPrimas);
            this.redPreferentePacificoSumFam = this.redPreferentePacificoArray.reduce((partialsum, a) => partialsum + a, 0);

            this.redPreferenteRimacArray.push(this.redPreferenteRimacPrimas);
            this.redPreferenteRimacSumFam = this.redPreferenteRimacArray.reduce((partialsum, a) => partialsum + a, 0);

            this.vivaSaludArray.push(this.vivaSaludPrimas);
            this.vivaSaludSumFam = this.vivaSaludArray.reduce((partialsum, a) => partialsum + a, 0);

            this.vivaSaludPlusArray.push(this.vivaSaludPlusPrimas);
            this.vivaSaludPlusSumFam = this.vivaSaludPlusArray.reduce((partialsum, a) => partialsum + a, 0);


            this.primasSalud = [
              {
                nombre: 'Medicvida Internacional',
                logo: this.medicvidaInternacionalLogo,
                edadTitular: this.cotizacion.edadTitular,
                primaTitular: this.primaTitular.medicvidaInternacional,
                familiares: this.medicvidaInternacionalFam,
                total: this.medicvidaInternacionalSumFam + this.primaTitular.medicvidaInternacional,
                aseguradora: this.pacifico,
                internacional: true,
              },
              {
                nombre: 'Salud Preferencial',
                logo: this.saludPreferencialLogo,
                edadTitular: this.cotizacion.edadTitular,
                primaTitular: this.primaTitular.saludPreferencial,
                familiares: this.medicvidaInternacionalFam,
                total: this.saludPreferencialSumFam + this.primaTitular.saludPreferencial,
                aseguradora: this.rimac,
                internacional: true,
              },
              {
                nombre: 'Internacional Previsora',
                logo: this.internacionalPrevisoraLogo,
                edadTitular: this.cotizacion.edadTitular,
                primaTitular: this.primaTitular.internacionalPrevisora,
                familiares: this.internacionalPrevisoraFam,
                total: this.internacionalPrevisoraSumFam + this.primaTitular.internacionalPrevisora,
                aseguradora: this.mapfre,
                internacional: true,
              },
              {
                nombre: 'Medicvida Nacional',
                logo: this.medicvidaNacionalLogo,
                edadTitular: this.cotizacion.edadTitular,
                primaTitular: this.primaTitular.medicvidaNacional,
                familiares: this.medicvidaNacionalFam,
                total: this.medicvidaNacionalSumFam + this.primaTitular.medicvidaNacional,
                aseguradora: this.pacifico,
                nacional: true,
              },
              {
                nombre: 'Full Salud',
                logo: this.fullsaludLogo,
                edadTitular: this.cotizacion.edadTitular,
                primaTitular: this.primaTitular.fullsalud,
                familiares: this.fullsaludFam,
                total: this.fullsaludSumFam + this.primaTitular.fullsalud,
                aseguradora: this.rimac,
                nacional: true,
              },
              {
                nombre: 'Trebol Salud',
                logo: this.trebolSaludLogo,
                edadTitular: this.cotizacion.edadTitular,
                primaTitular: this.primaTitular.trebolSalud,
                familiares: this.trebolSaludFam,
                total: this.trebolSaludSumFam + this.primaTitular.trebolSalud,
                aseguradora: this.mapfre,
                nacional: true,
              },
              {
                nombre: 'Multisalud',
                logo: this.multisaludLogo,
                edadTitular: this.cotizacion.edadTitular,
                primaTitular: this.primaTitular.multisalud,
                familiares: this.multisaludFam,
                total: this.multisaludSumFam + this.primaTitular.multisalud,
                aseguradora: this.pacifico,
                nacionalSinReembolso: true,
              },
              {
                nombre: 'Red Médica',
                logo: this.redMedicaLogo,
                edadTitular: this.cotizacion.edadTitular,
                primaTitular: this.primaTitular.redMedica,
                familiares: this.redMedicaFam,
                total: this.redMedicaSumFam + this.primaTitular.redMedica,
                aseguradora: this.rimac,
                nacionalSinReembolso: true,
              },
              {
                nombre: 'Viva Salud Plus',
                logo: this.vivaSaludPlusLogo,
                edadTitular: this.cotizacion.edadTitular,
                primaTitular: this.primaTitular.vivaSaludPlus,
                familiares: this.vivaSaludPlusFam,
                total: this.vivaSaludPlusSumFam + this.primaTitular.vivaSaludPlus,
                aseguradora: this.mapfre,
                nacionalSinReembolso: true,
              },
              {
                nombre: 'Red Preferente Pacífico',
                logo: this.redPreferentePacificoLogo,
                edadTitular: this.cotizacion.edadTitular,
                primaTitular: this.primaTitular.redPreferentePacifico,
                familiares: this.redPreferentePacificoFam,
                total: this.redPreferentePacificoSumFam + this.primaTitular.redPreferentePacifico,
                aseguradora: this.pacifico,
                nacionalClinicasAcotadas: true,
              },
              {
                nombre: 'Red Preferente Rimac',
                logo: this.redPreferenteRimacLogo,
                edadTitular: this.cotizacion.edadTitular,
                primaTitular: this.primaTitular.redPreferenteRimac,
                familiares: this.redPreferenteRimacFam,
                total: this.redPreferenteRimacSumFam + this.primaTitular.redPreferenteRimac,
                aseguradora: this.rimac,
                nacionalClinicasAcotadas: true,
              },
              {
                nombre: 'Viva Salud',
                logo: this.vivaSaludLogo,
                edadTitular: this.cotizacion.edadTitular,
                primaTitular: this.primaTitular.vivaSalud,
                familiares: this.vivaSaludFam,
                total: this.vivaSaludSumFam + this.primaTitular.vivaSalud,
                aseguradora: this.mapfre,
                nacionalClinicasAcotadas: true,
              },

            ];

            // tslint:disable-next-line:no-shadowed-variable
            this.primasInternacional = this.primasSalud.filter( prima => prima.internacional === this.cotizacion.categorias.internacional);
            // tslint:disable-next-line:no-shadowed-variable
            this.primasNacional = this.primasSalud.filter( prima => prima.nacional === this.cotizacion.categorias.nacional);
            // tslint:disable-next-line:no-shadowed-variable
            this.primasNacionalSinReembolso = this.primasSalud.filter( prima => prima.nacionalSinReembolso === this.cotizacion.categorias.nacionalSinReembolso);
            // tslint:disable-next-line:no-shadowed-variable
            this.primasNacionalClinicasAcotadas = this.primasSalud.filter( prima => prima.nacionalClinicasAcotadas === this.cotizacion.categorias.nacionalClinicasAcotadas);
            // this.primasResult = of(this.primasInternacional.concat(primasNacional, primasNacionalSinReembolso, primasNacionalClinicasAcotadas));

          });
        });

      });
    });
  }

  filterOrderPrimas() {
    this.primasResult = of(this.primasSalud.sort((a, b) => a.total < b.total ? -1 : a.total > b.total ? 1 : 0));
  }


  openModalDetallePrima() {
    const dialogRef = this.dialog.open(DetallePrimaComponent, {
      panelClass: 'myModalContacto'
      // width: '600px',
      // data: this.links
    });
    dialogRef.afterClosed().subscribe();
  }

}
