<div>
    <!-- <div class="d-flex justify-content-between align-items-center mb-2 mb-md-3">
        <p class="tituloModal text-danger">Eliminar Reporte</p>
        <div class="w-100"></div>
        <button mat-icon-button (click)="cancelar()"><mat-icon>close</mat-icon> </button>
    </div> -->
    <div class="text-center my-2 my-md-3">
        <p class="lead">¿Seguro que desea eliminar?</p>
    </div>
    <div class="text-right">
        <button class="btn btn-link text-dark text-decoration-none" (click)="cancelar()">Cancelar</button>
        <button class="btn btn-danger ml-3" (click)="eliminarProducto(data.id)">
          <mat-icon class="align-middle" *ngIf="!deshabilitado">delete</mat-icon>
          <mat-spinner *ngIf="deshabilitado" [diameter]="24" class="float-left mr-2"></mat-spinner>
          Eliminar reporte</button>
    </div>

</div>