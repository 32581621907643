import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { Timestamp } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-crear-banner',
  templateUrl: './crear-banner.component.html',
  styleUrls: ['./crear-banner.component.scss']
})
export class CrearBannerComponent implements OnInit {

  

  formBanner: UntypedFormGroup;
  // tipos = [
  //   'Presentación única',
  //   'Presentación múltiple'
  // ];
  // medidas = [
  //   'Kg.', 'Ml.', 'Tab.'
  // ];
  visible = false;
  deshabilitado = false;
  
  selectedFile: FileList | null;
  nameItem: any;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  // 1 MB
  readonly maxSize = 1048576;
  actualSize: any;

  animales;
  categorias;
  marcas;
  tipos;
  medidas;
  // aseguradoras;

  tiposLinks = [ 'Interno', 'Externo'];

  aseguradoras = [
    'Rimac',
    'Pacifico',
    'La Positiva',
    'Mapfre',
    'Qualitas',
    'Sanitas',
    'Liberty',
    'Ohio National',
    'Vivir Seguros'
  ]

  constructor(
    private fs: FirebaseService,
    private ds: DataService,
    private fb: UntypedFormBuilder,
    private router: Router,
    public dialogRef: MatDialogRef<CrearBannerComponent>,
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) { }

  ngOnInit(): void {
    this.formBanner = this.fb.group({
      image: [ '', Validators.required],
      breadcrumb: [ '', Validators.required],
      gorro: [ '', Validators.required],
      titulo: ['', Validators.required],
      subtitulo: ['', Validators.required],
      // textoBoton: ['', Validators.required],
      // urlBoton: ['', Validators.required],
      aseguradoras: ['', Validators.required],
      descripciones: new UntypedFormArray ([
        new UntypedFormControl ('', [])
      ]),
      // botonLink: ['', Validators.required],
      // aseguradoras: this.fb.group({
      //   pacifico: [false],
      //   rimac: [false],
      //   mapfre: [false],
      //   qualitas: [false],
      //   laPositiva: [false],
      //   sanitas: [false]
      // }),
      // botones: this.fb.array([
      //   this.fb.group({
      //     tipo: ['', Validators.required],
      //     nombre: ['', Validators.required],
      //     url: ['', Validators.required]
      //   })
      // ]),
      publicado: [false],
      destacado: [false],
      fechaCreacion: [Timestamp.now()],
    });

    // this.aseguradoras = this.fs.getAseguradoras();
  }

  onSubmit() {
    if (this.formBanner.valid) {
      this.uploadFileAndCrearProducto();
      this.deshabilitado = true;
    } else {
      this.validateAllFormFields(this.formBanner);
      this.deshabilitado = false;
    }
  }

  // crearProducto() {
  //   this.fs.addProduct(this.formBanner.value)
  //     .then(() => {
  //       this.dialogRef.close();
  //     });
  // }

  detectFiles(event) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }

  uploadFileAndCrearProducto() {
    const myTest = this.afs.collection('banners').ref.doc();
    console.log(myTest.id);

    const file = this.selectedFile;
    // const filePath = `imagesProducts/${myTest.id}/name1`;
    const filePath = `imagesBanners/${myTest.id}/${this.nameItem}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().toPromise().then( (url) => {
          this.downloadURL = url;
          myTest.set({
            image: this.downloadURL,
            breadcrumb: this.formBanner.value.breadcrumb,
            gorro: this.formBanner.value.gorro,
            titulo: this.formBanner.value.titulo,
            subtitulo: this.formBanner.value.subtitulo,
            imageName: this.nameItem,
            // textoBoton: this.formBanner.value.textoBoton,
            // urlBoton: this.formBanner.value.urlBoton,
            aseguradoras: this.formBanner.value.aseguradoras,
            descripciones: this.formBanner.value.descripciones,
            // botonLink: this.formBanner.value.botonLink,
            // aseguradoras: {
            //   pacifico: this.formBanner.value.aseguradoras.pacifico,
            //   rimac: this.formBanner.value.aseguradoras.rimac,
            //   mapfre: this.formBanner.value.aseguradoras.mapfre,
            //   qualitas: this.formBanner.value.aseguradoras.qualitas,
            //   laPositiva: this.formBanner.value.aseguradoras.laPositiva,
            //   sanitas: this.formBanner.value.aseguradoras.sanitas
            // },
            // botones: this.formBanner.value.botones,
            publicado: this.formBanner.value.publicado,
            destacado: this.formBanner.value.destacado,
            fechaCreacion: this.formBanner.value.fechaCreacion,
          });
          // this.dialogRef.close();
          this.router.navigate(['admin/banners']);
          console.log( this.downloadURL );
        }).catch(err => { console.log(err); } );
      })
    )
    .subscribe();
  }


  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  agregarPresentacion() {
    // tslint:disable-next-line:no-string-literal
    (this.formBanner.controls['botones'] as UntypedFormArray).push(
      this.fb.group({
        tipo: ['', Validators.required],
        nombre: ['', Validators.required],
        url: ['', Validators.required]
      })
    );
  }

  eliminarPresentacion(index: number): void {
    // tslint:disable-next-line:no-string-literal
    // (this.formBanner.controls['presentaciones']as FormArray).removeAt(index);
    const control = this.formBanner.controls.botones as UntypedFormArray;
    control.removeAt(index);
  }

  agregarDescripcion() {
    (this.formBanner.controls.descripciones as UntypedFormArray).push(
      new UntypedFormControl('', [Validators.required])
    );
  }

  eliminarDescripcion(index: number): void {
    (this.formBanner.controls.descripciones as UntypedFormArray).removeAt(index);
  }

  errorImagen() {
    return this.formBanner.controls.image.hasError('required') ? 'La imagen es necesaria' :
    this.formBanner.controls.image.hasError('maxContentSize') ? 'El peso de la imagen no debe exceder a 1 MB' : '';
  }

}
