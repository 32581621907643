import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormContactoSaludComponent } from '../form-contacto-salud/form-contacto-salud.component';

@Component({
  selector: 'app-detalle-seguro-salud',
  templateUrl: './detalle-seguro-salud.component.html',
  styleUrls: ['./detalle-seguro-salud.component.scss']
})
export class DetalleSeguroSaludComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DetalleSeguroSaludComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log(this.data);
  }

  openModalContactoSeguroSalud() {
    this.dialog.open(FormContactoSaludComponent, {
      panelClass: 'modalContactoSeguroSalud',
      data: this.data
    });
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  goToConsolidado(consolidado) {
    // window.location.href = `${cartilla}`;
    window.open(`${consolidado}`, '_blank');
  }

}
