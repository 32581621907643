import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-detalle-promocion',
  templateUrl: './detalle-promocion.component.html',
  styleUrls: ['./detalle-promocion.component.scss']
})
export class DetallePromocionComponent implements OnInit {

  idPromocion;
  promocion;
  aseguradoras = [];
  new;
  aseguradorasNew;
  aseguradorasFilter = [];
  filteredData;

  constructor(
    private afs: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private ds: DataService,
    public router: Router,
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {

      this.idPromocion = params.id;

      console.log(this.idPromocion);
      
      this.afs.doc('banners/' + this.idPromocion).valueChanges().subscribe(res => {
        this.promocion = res;
        const filters = this.promocion.aseguradoras;
        console.log(filters);
        this.aseguradoras = this.ds.aseguradoras;

        // for (let i in filters) {
        //   this.filteredData = this.aseguradoras.find( item =>  item.nombre === filters[i] );
        //   console.log(this.filteredData);
        //   this.aseguradorasFilter.push(this.filteredData);
        // }

        this.promocion.aseguradoras.map( element => {
          console.log('xx');
          const aseguradoraFilter = this.aseguradoras.find( item =>  item.nombre === element );
          this.aseguradorasFilter.push(aseguradoraFilter);
        });

        console.log(this.aseguradorasFilter);

      });
    });

    // this.aseguradoras = this.ds.aseguradoras.filter( fil =>  fil.nombre === 'Mapfre' || fil.nombre === 'La Positiva' || fil.nombre === 'Qualitas' );

  }

}
