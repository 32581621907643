import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chequeo-preventivo',
  templateUrl: './chequeo-preventivo.component.html',
  styleUrls: ['./chequeo-preventivo.component.scss']
})
export class ChequeoPreventivoComponent implements OnInit {

  icons = [
    'heartbeat',
    'report-medical',
    'flask',
    'stethoscope'
  ];

  examenes = [
    {
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoRimac.svg?alt=media&token=c51c72ea-e0b5-4ba3-93c6-7b7e69b8b969',
      url: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/landings%2FchequeoPreventivo%2Fdetalle%20de%20examenes%20RIMAC.pdf?alt=media&token=ed0ce248-17b6-40df-ba45-5cdf1fb6dfe4'
    },
    {
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoPacifico.svg?alt=media&token=bb52ed5e-dd51-4adf-a775-1aa903e01901',
      url: 'https://web.pacificoseguros.com/programaintegralprevencion/'
    },
    {
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoMapfre.svg?alt=media&token=e48e4fe1-10d1-4c02-885a-1609573eaea5',
      url: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/landings%2FchequeoPreventivo%2Fdetalle%20de%20examenes_MAPFRE.pdf?alt=media&token=db70a9bc-c023-44e2-8825-b798d8929c72'
    },
    {
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoLaPositiva.svg?alt=media&token=79c5dbd9-4b6f-4893-bdae-a3e7ab802a05',
      url: 'https://www.lapositiva.com.pe/wps/portal/corporativo/home/blog/chequeo-anual-edad'
    },
  ];


  clinicasAfiliadas = [
    {
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoRimac.svg?alt=media&token=c51c72ea-e0b5-4ba3-93c6-7b7e69b8b969',
      url: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/landings%2FchequeoPreventivo%2FClinicas%20afiliadas%20Rimac.pdf?alt=media&token=0e47262b-2bed-4f80-b796-c8c48e104e58'
    },
    {
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoPacifico.svg?alt=media&token=bb52ed5e-dd51-4adf-a775-1aa903e01901',
      url: 'https://www.pacifico.com.pe/buscador/clinicas'
    },
    {
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoMapfre.svg?alt=media&token=e48e4fe1-10d1-4c02-885a-1609573eaea5',
      url: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/landings%2FchequeoPreventivo%2FClinicas%20afiliadas%20mapfre.pdf?alt=media&token=e3ac30e2-60d8-408f-9b16-742a8fa96c52'
    },
    {
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoLaPositiva.svg?alt=media&token=79c5dbd9-4b6f-4893-bdae-a3e7ab802a05',
      url: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/landings%2FchequeoPreventivo%2Fclinicas%20afiliadas%20la%20positiva.pdf?alt=media&token=5bfed7d8-bcab-465e-bfef-51883d8808f6'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
