import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { DatosComponent } from '../datos/datos.component';

@Component({
  selector: 'app-detalle-seguro',
  templateUrl: './detalle-seguro.component.html',
  styleUrls: ['./detalle-seguro.component.scss']
})
export class DetalleSeguroComponent implements OnInit {

  idSeguro;
  seguro;
  segurosPersonas;
  segurosEmpresas;

  constructor(
    private fs: FirebaseService,
    private activatedRoute: ActivatedRoute,
    private afs: AngularFirestore,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getSeguroDetails();
    this.segurosPersonas = this.fs.getSegurosPersonas();
    this.segurosEmpresas = this.fs.getSegurosEmpresas();
    // this.segurosPersonasFilter = this.fs.getSegurosPersonasFilter(seguroActual);
  }

  // segurosPersonasFilter(id) {
  //   this.segurosPersonas = this.fs.getSegurosPersonasFilter(seguroActual);
  // }

  // filterSegurosPersonas() {
  //   this.fs.getSegurosPersonas().filter(i => i)
  // }

  // getSeguroDetails() {
  //   const id = this.activatedRoute.snapshot.paramMap.get('id');
  //   this.fs.getSeguro(id).subscribe(reserva => {
  //     this.idSeguro = id;
  //     this.fs.getSeguro(this.idSeguro).subscribe( res => {
  //       // this.nota = res.data();
  //       this.seguro = res;
  //     });
  //   });
  // }

  getSeguroDetails() {
    const id = this.activatedRoute.params.subscribe(params => {
      this.idSeguro = params.id;
      this.fs.getSeguro(this.idSeguro).subscribe(res => {
        this.seguro = res;
        console.log(this.seguro.nombre);
        // this.segurosPersonas = this.fs.getSegurosPersonasFilter(this.seguro.nombre);
      });
    });
  }

  openModalContacto() {
    const dialogRef = this.dialog.open(DatosComponent, {
      panelClass: 'myModalContacto'
      // width: '600px',
      // data: this.links
    });
    dialogRef.afterClosed().subscribe();
  }




}
