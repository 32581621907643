import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormGroup, FormArray } from '@angular/forms';
import * as firebase from 'firebase/app';
import { FirebaseService } from '../../services/firebase.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-datos',
  templateUrl: './datos.component.html',
  styleUrls: ['./datos.component.scss']
})
export class DatosComponent implements OnInit {

  formContacto;
  loader: boolean;

  constructor(
    private fs: FirebaseService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DatosComponent>,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.formContacto = this.fb.group({
      nombre: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      celular: [ '', [Validators.required, Validators.pattern('[0-9]*')]],
      fechaCreacion: [ Timestamp.now()],
    });
  }



  onSubmit() {
    if (this.formContacto.valid) {
      this.guardarContacto();
      this.loader = true;
    } else {
      this.validateAllFormFields(this.formContacto);
      this.loader = false;
    }
  }

  guardarContacto() {
    this.fs.addContacto(this.formContacto.value)
    .then( () => {
      console.log('Datos enviados');
      this.dialogRef.close();
      this.snackBar.open('Datos enviados', 'CERRAR', {
        duration: 3000,
      });
    });
  }


  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  errorNombre() {
    return this.formContacto.controls.nombre.hasError('required') ? 'Ingresa tu nombre' : '';
  }

  errorEmail() {
    return this.formContacto.controls.email.hasError('required') ? 'El email es necesario.' :
    this.formContacto.controls.email.hasError('email') ? 'No es un email válido.' : '';
  }
  errorCelular() {
    return this.formContacto.controls.celular.hasError('required') ? 'El número de celular es necesario.' :
    this.formContacto.controls.celular.hasError('maxlength') ? 'No debe exceder los 9 caracteres.' :
    this.formContacto.controls.celular.hasError('pattern') ? 'Solo se admiten números sin espacios.' : '';
  }

}
