<div class="container pt-2 pt-md-3 pb-4 pb-md-5" *ngIf="promocion">


  <nav aria-label="breadcrumb" class="extraSmall">
    <ol class="breadcrumb">
      <!-- <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li> -->
      <li class="breadcrumb-item"><a routerLink="/promociones">Promociones</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{promocion.breadcrumb}}</li>
    </ol>
  </nav>

  
  <div class="bg-light rounded-3 py-4 py-md-6 px-3 px-md-6">

    <div class="row d-flex justify-content-between">

      <div class="boxCaption col-md-6">
        <p class="gorro">{{promocion.gorro}}</p>
        <p class="titulo">{{promocion.titulo}}</p>
        <p class="subtitulo">{{promocion.subtitulo}}</p>
        <p class="extraSmall text-muted mb-1">Disponible con:</p>
        
        <div class="grillaAseguradorasPromocion">
          <div class="bg-white rounded" *ngFor="let aseguradora of aseguradorasFilter">
            <img [src]="aseguradora.logo" alt="">
          </div>
        </div>

        <div class="my-2 my-md-3">
          <div class="d-flex py-1 py-md-2" *ngFor="let descripcion of promocion.descripciones">
            <div class="me-2">
              <i-tabler class="text-secondary" name="circle-check" style="stroke-width: 1.2;"></i-tabler>
            </div>
            <p class="descripcion mb-0" style="margin-top: 2px;">{{descripcion}}</p>
          </div>
        </div>
        
        <a class="btn btn-success"
          href="https://api.whatsapp.com/send?phone=51998484891&text=Hola, escribo desde la web de la Previsora. Necesito más información sobre esta promoción: *{{promocion.titulo}} / {{promocion.subtitulo}}*"
          target="blank">Me interesa
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-whatsapp ms-1" width="24"
            height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2D273D" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
            <path
              d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
          </svg>
        </a>
      </div>
      <div class="col-md-5 boxImage order-first order-md-last">
        <img class="imgSlide" [src]="promocion.image">
      </div>
    </div>

  </div>

</div>