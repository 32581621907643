import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-crear-seguro-lista',
  templateUrl: './crear-seguro-lista.component.html',
  styleUrls: ['./crear-seguro-lista.component.scss']
})
export class CrearSeguroListaComponent implements OnInit {

  formSeguro: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private fs: FirebaseService,
  ) { }

  ngOnInit(): void {
    this.formSeguro = this.fb.group({
      nombreSeguro: ['', Validators.required],
      companiaSeguro: ['', Validators.required],
    });
  }

}
