<!-- <div class="p-3">
    <p class="lead text-center">¿Eliminar Aviso?</p>
    <div class="alert alert-danger" role="alert">
        Esta acción no se puede deshacer.
    </div>
    <div class="text-right mt-4">
        <button class="btn btn-light px-3 mr-3" (click)="cancelar()">Cancelar</button>
        <button class="btn btn-danger px-3" (click)="eliminarAviso()">
            <mat-icon class="material-icons-outlined align-middle">delete</mat-icon> Eliminar
        </button>
    </div>
</div> -->



<div class="p-1 p-md-3">

    <div class="buttonClose">
        <button mat-icon-button (click)="cancelar()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <p class="h4 mt-2 mt-md-0">Eliminar solicitud SOAT</p>

    <div class="my-3 my-md-4">
        <p class="">¿Seguro que desea eliminar la solicitud <span class="h6">{{data}}</span>? Esta acción no se puede deshacer.
        </p>
    </div>

    <div class="">
        <button type="submit" class="btn btn-danger btn-block py-2 px-3" (click)="eliminarSolicitudSoat(data)">
            <span class="myColorSpinnerWhite d-flex justify-content-center" *ngIf="deshabilitado">
                <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
            </span>
            <div *ngIf="!deshabilitado">
                <span>Eliminar solicitud SOAT</span>
            </div>
        </button>
        <!-- <button class="btn btn-link text-dark mt-3" (click)="cancelar()">Cancelar</button> -->
    </div>

</div>