import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-copagos-vehicular-motos',
  templateUrl: './copagos-vehicular-motos.component.html',
  styleUrls: ['./copagos-vehicular-motos.component.scss']
})
export class CopagosVehicularMotosComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CopagosVehicularMotosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private analytics: AngularFireAnalytics,
  ) { }

  ngOnInit(): void {
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  clickWhatsApp() {
    this.analytics.logEvent('btnWhatsAppSeguroMoto');
  }

  clickLlamada() {
    this.analytics.logEvent('btnLlamadaSeguroMoto');
  }

}
