<div class="p-1 p-md-2">

  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="mb-3">
    <p class="h3">Conversémos</p>
    <p class="small">Te explicamos todos los detalles de tu seguro.</p>
  </div>


  <!-- <div class="  border-top border-bottom py-2">

    <div class="d-flex justify-content-between w-100">
      <div class="">
        <img class="logoResultadoSalud" [src]="data.logo">
      </div>
      <div>
        <p class="planSalud mb-0">{{data.plan}}</p>
      </div>
      <div class="text-end ms-3 ms-md-5">
        <p class="primaSalud mb-0">S/ {{data.prima | number: '1.0-0'}}</p>
        <p class="small text-muted mb-0" style="white-space: nowrap;">al mes</p>
      </div>
    </div>

  </div>


  <div class="mt-3">
    <p class="mb-0 small text-muted">Déjanos tus datos y nos pondremos en contacto para darte todos los detalles.</p>
  </div>


  <form [formGroup]="formContacto" (submit)="onSubmit()" novalidate class="row">

    <mat-form-field class="col-12">
      <mat-label>Nombre</mat-label>
      <input matInput formControlName="nombre" placeholder="Ingresa un nombre">
      <mat-error *ngIf="errorNombre()">{{errorNombre()}}</mat-error>
    </mat-form-field>

    <mat-form-field class="col-12">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" placeholder="Ingresa tu email">
      <mat-error *ngIf="errorEmail()">{{errorEmail()}}</mat-error>
    </mat-form-field>

    <mat-form-field class="col-12">
      <mat-label>Celular</mat-label>
      <input matInput formControlName="celular" placeholder="Ingresa tu celular">
      <mat-error *ngIf="errorCelular()">{{errorCelular()}}</mat-error>
    </mat-form-field>

    <div class="col-12 mb-3 mt-1">
      <mat-checkbox formControlName="aceptaTerminosCondiciones" class="terminosCheckbox">
        <span>
          He leído y acepto los <button type="button" class="myButtonCheckboxModal text-primary" (click)="openModalTerminos()"><u>términos y condiciones</u></button> del servicio.
        </span>
        <mat-error *ngIf="errorAceptaTerminosCondiciones()">{{errorAceptaTerminosCondiciones()}}
        </mat-error>
      </mat-checkbox>
    </div>

    <div class="d-grid">
      <button class="btn btn-primary shadow-sm">
        <div class="d-flex justify-content-center">
          <mat-spinner diameter="24" *ngIf="loader"></mat-spinner>
        </div>
        <span *ngIf="!loader">Enviar datos</span>
      </button>
    </div>

  </form> -->



    <!-- <div class="text-center">
      <p>Te explicamos todos lo detalles.</p>
    </div> -->

    
    
    <div class="h-100 d-flex align-items-center">
      <div class="d-grid gap-1 w-100">
        <a class="btn btn-success shadow mb-3" href="https://meetings.hubspot.com/jleon10" target="_blank" (click)="clickVideollamada()">Agenda una videollamada</a>
        <div class="text-center small text-muted">O si prefieres</div>
        <a class="btn btn-light fw-normal" href="https://api.whatsapp.com/send?phone=51998161616&text=Hola Jaime te escribo desde la web de La Previsora. Necesito que me asesoren con este seguro de salud: {{data.plan}} con {{data.aseguradora}}" target="_blank" (click)="clickWhatsApp()">Hablemos por WhatsApp</a>
        <a class="btn btn-light fw-normal" href="tel:998161616" target="_blank" (click)="clickLlamada()">Llámanos al 998161616</a>
      </div>
    </div>

</div>