import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EliminarAseguradoraComponent } from '../eliminar-aseguradora/eliminar-aseguradora.component';

@Component({
  selector: 'app-lista-aseguradoras',
  templateUrl: './lista-aseguradoras.component.html',
  styleUrls: ['./lista-aseguradoras.component.scss']
})
export class ListaAseguradorasComponent implements OnInit {

  count: number;
  loading: boolean;
  displayedColumns = [ 'imagen', 'id', 'fechaCreacion', 'nombre', 'publicado', 'mas' ];
  aseguradorasData = new MatTableDataSource();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.aseguradorasData.filter = filterValue;
  }

  constructor(
    private fs: FirebaseService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.fs.getAseguradoras().subscribe(data => {
      this.aseguradorasData.data = data;
      this.loading = false;
      this.count = data.length;
    });
    this.aseguradorasData.paginator = this.paginator;
    this.aseguradorasData.sort = this.sort;
  }


  eliminarAviso(idProduct) {
    this.fs.deleteBanner(idProduct);
  }


  openModalDelete(aseguradora) {
    this.dialog.open(EliminarAseguradoraComponent, {
      panelClass: 'dialogDeleteSolicitud',
      data: aseguradora
    });
  }


  ngAfterViewInit() {
    this.aseguradorasData.paginator = this.paginator;
    // this.avisosData.sort = this.sort;
  }


  actualizarPublicado(key, obj, e) {
    this.fs.updateAprobado(key, e);
  }
  

  trackByPublicado(item) {
    return item.publicado;
  }

}
