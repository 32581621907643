import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TerminosCondicionesModalComponent } from 'src/app/components/terminos-condiciones-modal/terminos-condiciones-modal.component';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/app';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-form-contacto-salud',
  templateUrl: './form-contacto-salud.component.html',
  styleUrls: ['./form-contacto-salud.component.scss']
})
export class FormContactoSaludComponent implements OnInit {

  formContacto: UntypedFormGroup;
  loader: boolean;
  solicitudSalud;

  constructor(
    public dialogRef: MatDialogRef<FormContactoSaludComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private fs: FirebaseService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore,
    private analytics: AngularFireAnalytics
  ) {
    this.solicitudSalud = this.afs.collection('solicitudesSalud').ref.doc();
    console.log(this.solicitudSalud.id);
  }

  ngOnInit(): void {

    this.formContacto = this.fb.group({
      nombre: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      celular: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(9), Validators.maxLength(9)]],
      aceptaTerminosCondiciones: [false, Validators.requiredTrue],
      fechaCreacion: [Timestamp.now()]
    });

    console.log(this.data);
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  agregarContactoSeguroSalud() {

  }

  onSubmit() {
    if (this.formContacto.valid) {
      this.guardarContacto();
      this.loader = true;
    } else {
      this.validateAllFormFields(this.formContacto);
      this.loader = false;
    }
  }

  guardarContacto() {
    this.fs.addContactoSeguroSalud(this.solicitudSalud.id, this.formContacto.value, this.data)
    .then( () => {
      console.log('Datos enviados');
      this.dialogRef.close();
      // this.snackBar.open('Datos enviados', 'CERRAR', {
      //   duration: 3000,
      // });
      this.router.navigate([`/cotizadores/salud/${this.solicitudSalud.id}/gracias`]);
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  openModalTerminos() {
    this.dialog.open(TerminosCondicionesModalComponent, {
      panelClass: 'dialogTerminosCondiciones',
    });
  }

  clickVideollamada() {
    this.analytics.logEvent('botonVideollamadaSalud');
  }

  clickWhatsApp() {
    this.analytics.logEvent('botonWhatsAppSalud');
  }

  clickLlamada() {
    this.analytics.logEvent('botonLlamadaSalud');
  }

  errorNombre() {
    return this.formContacto.controls.nombre.hasError('required') ? 'Ingresa tu nombre' : '';
  }


  errorEmail() {
    return this.formContacto.controls.email.errors?.required ? 'Ingrese un email' :
      this.formContacto.controls.email.errors?.email ? 'Ingrese un email válido' :
        '';
  }

  errorCelular() {
    return this.formContacto.controls.celular.hasError('required') ? 'Ingrese el número de celular' :
    this.formContacto.controls.celular.hasError('pattern') ? 'Solo se admiten números' :
    this.formContacto.controls.celular.hasError('minlength') ? 'Debe contener 9 dígitos' :
    this.formContacto.controls.celular.hasError('maxlength') ? 'No debe exceder los 9 dígitos' :
    '';
  }

  errorAceptaTerminosCondiciones() {
    return this.formContacto.controls.aceptaTerminosCondiciones.hasError('required') && this.formContacto.controls.aceptaTerminosCondiciones.touched ? 'Acepte los términos y condiciones' : '';
  }

}
