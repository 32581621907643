<div class="container py-4 py-md-6 pb-md-8">


  <div class="bg-secondary rounded-3 bannerChequeoPreventivo px-4 px-md-6 pt-4 pt-md-0 pb-0">
    <div class="row justify-content-between align-items-center">
      <div class="col-md-6">
        <div class="text-white">
          <p class="display-5 lh-1">Chequeo Preventivo</p>
          <p class="fs-5 lh-sm">Evaluaciones médicas preventivas y un control de riesgos, a los que tienes acceso según tu edad y sexo.</p>
        </div>
        <div class="d-flex gap-2">
          <i-tabler class="text-secondary" *ngFor="let icon of icons" [name]="icon" style="width: 42px; height: 42px; stroke-width: 1.2;"></i-tabler>
        </div>
      </div>
      <div class="col-md-4">
        <img class="mt-3 mt-md-5" src="https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/landings%2FchequeoPreventivo%2Fdoctor3d.png?alt=media&token=66319d95-b72b-420a-8ef4-1dd053f90af9" alt="" width="100%">
      </div>
    </div>
  </div>




  <div class="col-md-10 mx-auto">


    <div class="row justify-content-between my-6 my-md-7">
      <div class="col-md-3">
        <img src="https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/landings%2FchequeoPreventivo%2FdocMedic3d.png?alt=media&token=5b809ad2-2fa0-4a8e-9658-e3f894c4372a" alt="" width="100%">
      </div>
      <div class="col-md-8">
        <p class="h1">1. ¿Qué exámenes incluye el chequeo preventivo anual?</p>
        <p>Incluye los exámenes de laboratorio, evaluación médica, despistajes oncológicos y evaluaciones tanto oftalmológicas como odontológicas. Una vez al año, tú y tus familiares pueden acceder a estos beneficios gratuitamente. El chequeo preventivo aplica desde la primera renovacion en adelante.</p>
        <p>Detalle de exámenes con los que cuentas según tu seguro de salud:</p>
        <div class="d-inline-flex gap-1 flex-column">
          <div class="bg-light rounded d-flex gap-2 align-items-center ps-1 pe-3" *ngFor="let examen of examenes">
            <img [src]="examen.logo" alt="" width="110">
            <span class="text-muted">|</span>
            <a class="" [href]="examen.url" target="_blank">Detalle de exámenes <i-tabler class="ms-1" name="external-link"></i-tabler> </a>
          </div>
        </div>
      </div>
    </div>




    <div class="row justify-content-between mb-6 mb-md-7">
      <div class="col-md-3">
        <img src="https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/landings%2FchequeoPreventivo%2Fhospital3d.png?alt=media&token=93f0d3e0-ed7c-488b-927c-d161224f7edf" alt="" width="100%">
      </div>
      <div class="col-md-8">
        <p class="h1">2. ¿Cómo realizarme un chequeo preventivo regular?</p>
        <p>Lo primero que debes hacer es revisar en qué clínicas tienes atención médica, según tu plan o póliza de salud. Luego debes separar tu cita directamente con la clínica de tu preferencia.</p>
        <p>Sigue estos pasos para solicitar el servicio:</p>
        <p class="h5">Agenda tu cita, vía telefónica, en cualquiera de las clínicas afiliadas:</p>
        <div class="d-inline-flex gap-1 flex-column mb-3">
          <div class="bg-light rounded d-flex gap-2 align-items-center ps-1 pe-3" *ngFor="let examen of clinicasAfiliadas">
            <img [src]="examen.logo" alt="" width="110">
            <span class="text-muted">|</span>
            <a class="" [href]="examen.url" target="_blank">Ver clínicas afiliadas <i-tabler class="ms-1" name="external-link"></i-tabler> </a>
          </div>
        </div>
        <p class="h5">Preséntate puntualmente con tu DNI o carné de extranjería</p>
        <p>Una vez realizado tus exámenes, deberás acercarte a recoger tus resultados o también puedes revisar tu casilla de correo electrónico. Ten en cuenta que el informe médico estará disponible en 7 días útiles. No olvides seguir las recomendaciones que te indique tu médico.</p>
        <div class="border rounded-3 border-secondary p-3 p-md-4 mb-3">
          <p>Recuerda:</p>
          <ul>
            <li>Si vas a realizarte un análisis de laboratorio, acude en ayunas.</li>
            <li>La consulta con el médico y los exámenes auxiliares deben ser realizados en una sola atención.</li>
            <li>Si tienes exámenes pendientes de tu chequeo preventivo, puedes completarlos en otra clínica de la red de prevención que cuente con el servicio disponible (previa cita).</li>
          </ul>
        </div>
        <div class="rounded-3 p-3 p-md-4 bgLightPrimary mb-3">
          <p>IMPORTANTE: Este beneficio está cubierto al 100% en las pólizas AMI siguientes:</p>
          <ul>
            <li>Rimac: Salud preferencial, full salud, red médica, Red preferente, Salud de oro y oncológico integral.</li>
            <li>Pacifico: Medicvida internacional, Medicvida nacional, Multisalud, Red preferente, Multiplan, enfermedades graves, oncológico internacional y nacional.</li>
            <li>Mapfre: Previsor, Trebol salud, Trebol salud farmapfre y Viva salud plus.</li>
            <li>La Positiva: Medisalud global, Medisalud premium, Medisalud plus y Medisalud.</li>
          </ul>
        </div>
        <p>Revisa las condiciones y restricciones, referentes al chequeo preventivo, en tu póliza de seguro de salud, plan de salud o contáctanos.</p>
        <p>Los servicios preventivos serán brindados de acuerdo con la disponibilidad del proveedor siguiendo las disposiciones del MINSA.</p>
      </div>
    </div>




    <div class="row justify-content-between">
      <div class="col-md-3">
        <img src="https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/landings%2FchequeoPreventivo%2FphoneMedical.png?alt=media&token=c5b8d8a1-8e46-474a-ae17-9d60b6c1b8aa" alt="" width="100%">
      </div>
      <div class="col-md-8">
        <p class="h1">3. ¿Cómo realizarme mi chequeo preventivo virtual?</p>
        <p>¡Es muy fácil! Puedes realizarte un chequeo preventivo virtual una vez al año. Este beneficio está cubierto al 100% para afiliados de 18 años a más. Beneficio solo para asegurados de Rimac y Pacifico seguros en Lima metropolitana.</p>
        <p>Los exámenes de laboratorio son:</p>
        <ul>
          <li>Glucosa</li>
          <li>Hemograma completo</li>
          <li>Colesterol</li>
          <li>Colesterol HDL</li>
          <li>Triglicéridos</li>
          <li>PSA (hombres mayores de 50 años)</li>
          <li>Examen de Thevenon en heces (mayores de 50 años)</li>
          <li>Entre otros dependiendo tu plan.</li>
        </ul>
        <p class="h4">¿Cómo solicito mi chequeo virtual?</p>
        <ol>
          <li>
            <p>Llama al centro médico de tu preferencia:</p>
            <ul>
              <li>
                <p class="fw-bold">Para Pacifico:</p>
                <ol>
                  <li>SANNA: Teléfono: (01) 635 5000 opción 2 y luego opción 3. Horario de atención: De lunes a viernes de 9 am a 6 pm</li>
                  <li>Centro Médico Jockey Salud: Teléfono: (01) 712 3456 anexo 7912. Horario de atención: de lunes a viernes de 8 am a 4 pm.</li>
                </ol>
              </li>
              <li>
                <p class="fw-bold">Para Rimac:</p>
                <ol>
                  <li>Clinica Internacional Digital a través de siguiente WhatsApp 934594498</li>
                </ol>
              </li>
            </ul>
          </li>
          <li>Un asesor te brindara la fecha de tu cita de laboratorio</li>
          <li>Recibirás la visita a domicilio de un técnico de laboratorio para los exámenes correspondientes</li>
          <li>Te llamarán nuevamente para coordinar la fecha de tu teleorientación médica.</li>
          <li>Sigue las instrucciones el día de tu teleorientación médica.</li>
        </ol>
        <p class="h4">Indicaciones para que te prepares para los exámenes de sangre</p>
        <p>Para los exámenes de sangre se debe guardar ayuno de 12 horas, es decir, a partir de las 8:00 p.m. del día anterior al examen. Si tuvieras alguna enfermedad crónica, no dejes de tomar tus medicamentos. Además, se deben evitar comidas copiosas con alto contenido de grasas la noche anterior a la realización de la prueba.</p>
        <div class="border rounded-3 border-secondary p-3 p-md-4 mb-3">
          <p>Recuerda:</p>
          <p>*La consulta médica virtual consiste en una teleorientación y consejería médica que incluye lectura de resultados. No genera receta y no reemplaza una consulta presencial.</p>
        </div>
        <p>Si deseas más información, comunícate a <a href="mailto:nmanchego@previsora.pe">nmanchego@previsora.pe</a> o al 998484891</p>
      </div>
    </div>





  </div>



  



</div>