<div class="p-1 p-md-3">

  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="mt-3 mb-2">
    <!-- <p class="h5">Solicita el SOAT para tu moto {{data.formSoatMoto_.get('marca').value}} - {{data.formSoatMoto_.get('modelo').value}} con {{data.item_.aseguradora}} asolo S/. {{data.item_.prima}}</p> -->
    <!-- <p>{{data.formSoatMoto_.get('marca').value}} - {{data.formSoatMoto_.get('modelo').value}} </p> -->
    <!-- <p class="small">Te explicamos todos los detalles de tu seguro.</p> -->

    <p class="h3">Solicita tu SOAT</p>
    <div class="small">
      <div>
        <span class="text-muted">Tipo: </span>
        <span>Moto</span>
      </div>
      <div>
        <span class="text-muted">Marca: </span>
        <span>{{data.formSoatMoto_.get('marca').value}}</span>
      </div>
      <div>
        <span class="text-muted">Modelo: </span>
        <span>{{data.formSoatMoto_.get('modelo').value}}</span>
      </div>
      <div>
        <span class="text-muted">Aseguradora: </span>
        <span>{{data.item_.aseguradora}}</span>
      </div>
      <div>
        <span class="text-muted">Precio: </span>
        <span>S/. {{data.item_.prima}}</span>
      </div>
    </div>
  
  </div>

    
 
      <div class="d-grid gap-2 w-100 mb-1">
        <!-- <a class="btn btn-success shadow mb-3" href="https://meetings.hubspot.com/jleon10" target="_blank" (click)="clickVideollamada()">Agenda una videollamada</a>
        <div class="text-center small text-muted">O si prefieres</div> -->
        <a class="btn btn-success" href="https://api.whatsapp.com/send?phone=51998161616&text=Hola Jaime te escribo desde la web de La Previsora. Estoy interesado en un SOAT para mi moto {{data.formSoatMoto_.get('marca').value}} - {{data.formSoatMoto_.get('modelo').value}} con {{data.item_.aseguradora}} a S/. {{data.item_.prima}}" target="_blank" (click)="clickWhatsApp()">Pídelo por WhatsApp</a>
        <a class="btn btn-light fw-normal" href="tel:998161616" target="_blank" (click)="clickLlamada()">Llámanos al 998161616</a>
      </div>


</div>