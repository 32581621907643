import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FirebaseService } from 'src/app/services/firebase.service';
import { CrearBannerComponent } from '../crear-banner/crear-banner.component';
import { EditarBannerComponent } from '../editar-banner/editar-banner.component';
import { EliminarBannerComponent } from '../eliminar-banner/eliminar-banner.component';

@Component({
  selector: 'app-lista-banners',
  templateUrl: './lista-banners.component.html',
  styleUrls: ['./lista-banners.component.scss']
})
export class ListaBannersComponent implements OnInit {

  count: number;
  loading: boolean;
  displayedColumns = [ 'imagen', 'id', 'titulo', 'publicado', 'destacado', 'mas' ];
  bannersData = new MatTableDataSource();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.bannersData.filter = filterValue;
  }

  constructor(
    private fs: FirebaseService,
    private dialog: MatDialog,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.fs.getAllBanners().subscribe(data => {
      this.bannersData.data = data;
      this.loading = false;
      this.count = data.length;
    });
    this.bannersData.paginator = this.paginator;
    this.bannersData.sort = this.sort;
  }

  openModalCrear() {
    const dialogRef = this.dialog.open(CrearBannerComponent, {
      panelClass: 'modalFullScreen',
    });
    dialogRef.afterClosed().subscribe();
  }


  eliminarAviso(idProduct) {
    this.fs.deleteBanner(idProduct);
  }

  openModalDetalle(producto) {
    const dialogRef = this.dialog.open(CrearBannerComponent, {
      panelClass: 'modalFullScreen',
      data: producto
    });
    dialogRef.afterClosed().subscribe();
  }

  openModalDelete(banner) {
    this.dialog.open(EliminarBannerComponent, {
      panelClass: 'dialogDeleteSolicitud',
      data: banner
    });
  }

  openModalEdit(producto) {
    const dialogRef = this.dialog.open(EditarBannerComponent, {
      panelClass: 'modalFullScreen',
      data: producto
    });
    dialogRef.afterClosed().subscribe();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.bannersData.paginator = this.paginator;
    // this.avisosData.sort = this.sort;
  }

  // actualizarPublicado(key, obj, e) {
  //   this.fs.updateAprobado(key, e);
  // }

  // actualizarDestacado(key, obj, e) {
  //   this.fs.updateDestacado(key, e);
  // }

  // trackByPublicado(item) {
  //   return item.publicado;
  // }


  actualizarPublicado(id, publicado) {
    this.afs.doc('banners/' + id).update({ publicado });
  }
  

  trackByPublicado(item) {
    return item.publicado;
  }

  actualizarDestacado(id, destacado) {
    this.afs.doc('banners/' + id).update({ destacado});
  }

}
