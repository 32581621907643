import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-gracias-actualizacion',
  templateUrl: './gracias-actualizacion.component.html',
  styleUrls: ['./gracias-actualizacion.component.scss']
})
export class GraciasActualizacionComponent implements OnInit {

  idActualizacion;
  actualizacion;

  constructor(
    private fs: FirebaseService,
    private activatedRoute: ActivatedRoute,
    private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( params => {
      this.idActualizacion = params.id;

      this.fs.getActualizacionDatos(this.idActualizacion).subscribe(res => {
        this.actualizacion = res;
        console.log(this.actualizacion.nombres);
      });
    });
    // const id = this.activatedRoute.params.subscribe(params => {
    //   this.idCotizacion = params.id;

    //   this.fs.getCotizacion(this.idCotizacion).subscribe(res => {
    //     this.cotizacion = res;
    //     console.log(this.cotizacion.edadTitular);
  }

}
