export class Banner {
    id?: string;
    image?: string;
    imageName?: string;
    titulo?: string;
    descripcion?: string;
    botonLink?: string;
    botonUrl?: string;
    aseguradoras?: string[];
}
