import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EliminarSolicitudComponent } from '../eliminar-solicitud/eliminar-solicitud.component';

@Component({
  selector: 'app-lista-solicitudes',
  templateUrl: './lista-solicitudes.component.html',
  styleUrls: ['./lista-solicitudes.component.scss']
})
export class ListaSolicitudesComponent implements OnInit {

  loader = true;
  count: number;
  value;
  displayedColumns = ['id', 'dataUsuario.fechaCreacion', 'dataUsuario.nombres', 'dataAseguradora.aseguradora', 'dataAseguradora.precio', 'detalles', 'mas'];
  dataSolicitudesSoat = new MatTableDataSource();
  avisos: any[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSolicitudesSoat.filter = filterValue;
  }

  constructor(
    private fs: FirebaseService,
    public dialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {

    this.fs.getSolicitudesSoat().subscribe(data => {
      this.dataSolicitudesSoat.data = data;
      this.loader = false;
      this.count = data.length;
      // console.log(data)
    });
    this.dataSolicitudesSoat.paginator = this.paginator;
    this.dataSolicitudesSoat.sort = this.sort;

    // Para ordenar objetos anidados
    this.dataSolicitudesSoat.sortingDataAccessor = (item, property) => {
      // Split '.' to allow accessing property of nested object
      if (property.includes('.')) {
        const accessor = property.split('.');
        let value: any = item;
        accessor.forEach((a) => {
          value = value[a];
        });
        return value;
      }
      // Access as normal
      return item[property];
    };

    // Para filtrar objetos anidados, incluye minusculas
    this.dataSolicitudesSoat.filterPredicate = (data: any, filter: string) => {
      const dataStr = JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };
  }

  clearFilters() {
    this.dataSolicitudesSoat.filter = '';
    this.value = '';
  }

  leerSolicitud(solicitudId) {
    this.router.navigate([`/admin/solicitudesSoat/${solicitudId}`]);
  }

  editarAviso() {
  }

  urlAviso() {
  }

  eliminarAviso(idSolicitudSoat) {
    this.afs.doc('solicitudesSoat/' + idSolicitudSoat).delete();
  }

  // openModalDetalle(aviso) {
  //   const dialogRef = this.dialog.open(AvisoDetalleAdminComponent, {
  //     // width: '250px',
  //     panelClass: 'myDialogAdmin',
  //     data: { id: aviso }
  //   });
  //   dialogRef.afterClosed().subscribe();
  // }

  openModalDelete(idSolicitudSoat) {
    const dialogRef = this.dialog.open(EliminarSolicitudComponent, {
      panelClass: 'dialogDeleteSolicitud',
      data: idSolicitudSoat
    });
    dialogRef.afterClosed().subscribe();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.dataSolicitudesSoat.paginator = this.paginator;
    // this.dataSolicitudesSoat.sort = this.sort;
  }

  // actualizarAprobado(key, aviso, e) {
  //   this.fs.updateAprobado(key, e);
  //   this.fs.addAvisoAprobado(key, aviso);
  // }

  // trackByAprobado(item) {
  //   return item.aprobado;
  // }

}
