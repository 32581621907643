<div class="p-3 h-100">

  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-title class="mb-2">

    <div class="me-4">
      <p class="h4">Principales coberturas y exclusiones</p>
    </div>

    <p class="extraSmall mb-0 text-muted lh-sm">{{data.plan}} &#183; {{data.aseguradora}} &#183; S/ {{data.prima | number: '1.0-0'}} &#183; Ver detalle completo en: <a [href]="data.condicionado" target="_blank">Condicionado SBS</a></p>
    
  </div>

  <mat-dialog-content>
    <div class="myTab">
    <mat-tab-group>
      <mat-tab label="Coberturas">
        <div class="mt-3">
          <div class="d-flex listaCaracteristicas" *ngFor="let cobertura of data.coberturas">
            <div class="me-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="16" height="16" viewBox="0 0 24 24" stroke-width="2.5" stroke="#82EA79" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
            </div>
            <div>
              <p class="mb-0">{{cobertura}}</p>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Exclusiones">
        <div class="mt-3">
          <div class="d-flex listaCaracteristicas" *ngFor="let exclusion of data.exclusiones">
            <div class="me-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="#ff2825" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <div>
              <p class="mb-0">{{exclusion}}</p>
            </div>
          </div>
        </div>
      </mat-tab>

      <!-- <mat-tab label="Clínicas">
        <div class="listaCaracteristicas mt-3">
          <table class="table ">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Copago</th>
                <th scope="col">Cobertura</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let clinica of data.clinicas">
                <td>{{clinica.nombre}}</td>
                <td class="text-nowrap">{{clinica.copago}}</td>
                <td class="text-nowrap">{{clinica.cobertura}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-tab> -->

    </mat-tab-group>
  </div>
  </mat-dialog-content>

  <!-- <mat-dialog-actions>
    <div class="d-grid w-100 mb-0 mb-md-3">
      <button class="btn btn-primary shadow-sm" (click)="openModalContactoSeguroSalud()">Me interesa</button>
    </div>
  </mat-dialog-actions> -->

</div>