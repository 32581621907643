<div class="col-md-10 mx-auto py-4 py-md-5">
    <div class="row">
        <div class="col-md-6">
            <p class="h4">Crear reporte BI</p>
            <form [formGroup]="formReporte" (submit)="onSubmit()" novalidate class="row">
                <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Título</mat-label>
                    <input matInput type="text" formControlName="titulo">
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Breve descripcion</mat-label>
                    <input matInput type="text" formControlName="descripcion">
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Url</mat-label>
                    <input matInput type="text" formControlName="url">
                </mat-form-field>
                <div class="col-12">
                    <button class="btn btn-primary px-3" type="submit">
                    Crear reporte
                    <mat-icon class="align-middle" *ngIf="!deshabilitado">add</mat-icon>
                    <mat-spinner *ngIf="deshabilitado" [diameter]="24" class="float-right ml-2"></mat-spinner>
                  </button>
                </div>
            </form>
        </div>
    </div>
</div>