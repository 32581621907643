<div class="bg-white">

  <div class="col-md-10 mx-auto">

    <nav class="py-2 py-md-3 d-none d-md-block">
      <ol class="breadcrumb small">
        <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
        <li class="breadcrumb-item"><a routerLink="/cotizadores">Cotizadores</a></li>
        <li class="breadcrumb-item"><a routerLink="/cotizadores/salud">Salud</a></li>
        <li class="breadcrumb-item active">{{idCotizacion}}</li>
      </ol>
    </nav>

    <div class="py-3 py-md-4" *ngIf="cotizacion">
      <div class="text-center">
        <p class="h3">Cotización Seguro de Salud</p>
        <p class="text-muted">Mostrando resultados para:</p>
        <div class="row">
          <div class="col-md-6 mx-auto">
            <div class="grillaElementosSalud d-flex justify-content-center">
              <div class="rounded bg-light p-1 p-md-2">
                <span class="small">Titular</span>
                <p class="mb-0 font-weight-bold">{{cotizacion.edadTitular}} años</p>
              </div>
              <div class="rounded bg-light p-1 p-md-2" *ngFor="let familiar of cotizacion.familiares">
                <span class="small">{{familiar.vinculo}}</span>
                <p class="mb-0 semibold">{{familiar.edad}} años</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="">
      <div class="">


        <!-- <div class="mb-3 mb-md-4 d-flex justify-content-between align-items-center">
                    <p class="h5 mb-0">Seguros de Salud:</p>
                    <button class="btn btn-sm btn-light text-primary">
                  Compartir
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-share ml-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z"/>
                    <circle cx="6" cy="12" r="3" />
                    <circle cx="18" cy="6" r="3" />
                    <circle cx="18" cy="18" r="3" />
                    <line x1="8.7" y1="10.7" x2="15.3" y2="7.3" />
                    <line x1="8.7" y1="13.3" x2="15.3" y2="16.7" />
                  </svg>
                </button>
                </div> -->


        <!-- <div class="d-none d-md-block">
                    <div class="grillaResultadosSalud" *ngIf="primaTitular">
                        <div class="bg-white border rounded-lg px-3 pb-3" *ngFor="let prima of primasResult | async">
                            <div class="">
                                <img class="mr-2 bg-white" [src]="prima.logo" width="120px" style="margin-top: -1.75rem;">
                                <p class="lead my-2">{{prima.nombre}}</p>
                            </div>
                            <div class="d-flex justify-content-between my-1">
                                <span>Titular <span class="text-muted small">({{prima.edadTitular}} años)</span></span>
                                <span>{{prima.primaTitular | currency:'S/ ':'symbol':'1.0-0'}}</span>
                            </div>
                            <div class="d-flex justify-content-between my-1" *ngFor="let familiar of prima.familiares">
                                <span>{{familiar.vinculo}} <span class="text-muted small">({{familiar.edad}} años)</span></span>
                                <span>{{familiar.prima | currency:'S/ ':'symbol':'1.0-0'}}</span>
                            </div>
                            <hr class="my-2">
                            <div class="d-flex justify-content-end semibold">
                                <span class="mr-2">Prima anual:</span>
                                <span>{{prima.total | currency:'S/ ':'symbol':'1.0-0'}}</span>
                            </div>
                        </div>
                    </div>
                </div> -->




        <!-- <div *ngIf="primasNacional.length > 0">
                    <p class="seccionResultadoSalud">Nacional</p>
                    <div class="grillaResultadosSalud"> 
                        <app-card-resultado *ngFor="let prima of primasNacional" [prima]="prima"></app-card-resultado>  
                    </div>
                </div>

                <div *ngIf="primasNacionalSinReembolso.length > 0">
                    <p class="seccionResultadoSalud">Nacional sin reembolso</p>
                    <div class="grillaResultadosSalud" > 
                        <app-card-resultado *ngFor="let prima of primasNacionalSinReembolso" [prima]="prima"></app-card-resultado>  
                    </div>
                </div>
                <div *ngIf="primasNacionalClinicasAcotadas.length > 0">
                    <p class="seccionResultadoSalud">Nacional con clínicas acotadas</p>
                    <div class="grillaResultadosSalud" > 
                        <app-card-resultado *ngFor="let prima of primasNacionalClinicasAcotadas" [prima]="prima"></app-card-resultado>  
                    </div>
                </div>
                <div *ngIf="primasInternacional.length > 0">
                    <p class="seccionResultadoSalud">Internacional</p>
                    <div class="grillaResultadosSalud" > 
                        <app-card-resultado *ngFor="let prima of primasInternacional" [prima]="prima"></app-card-resultado>  
                    </div>
                </div>

                <p class="h3">Nacional</p>
                <mat-list class="border rounded shadow-sm">
                    <mat-list-item *ngFor="let prima of primasNacional; last as last" style="height: 100%;">
                        <div class="w-100 d-flex justify-content-between align-items-center py-2">    
                            <img class="bg-white" [src]="prima.logo" width="90px">
                            <p class="mb-0">{{prima.nombre}}</p>
                            <span>
                                <span class="h5 mb-0">{{prima.total | currency:'S/ ':'symbol':'1.0-0'}}</span><span> / año</span>
                            </span>
                        </div>
                        <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
                    </mat-list-item>
                </mat-list> -->

        <div *ngIf="primasNacional.length > 0" class="mb-4 mb-md-5">
          <p class="h4">Nacional</p>
          <mat-accordion>
            <app-card-resultado *ngFor="let prima of primasNacional" [prima]="prima"></app-card-resultado>
          </mat-accordion>
        </div>

        <div *ngIf="primasNacionalSinReembolso.length > 0" class="mb-4 mb-md-5">
          <p class="h4">Nacional sin reembolso</p>
          <mat-accordion>
            <app-card-resultado *ngFor="let prima of primasNacionalSinReembolso" [prima]="prima"></app-card-resultado>
          </mat-accordion>
        </div>

        <div *ngIf="primasNacionalClinicasAcotadas.length > 0" class="mb-4 mb-md-5">
          <p class="h4">Nacional con clínicas acotadas</p>
          <mat-accordion>
            <app-card-resultado *ngFor="let prima of primasNacionalClinicasAcotadas" [prima]="prima"></app-card-resultado>
          </mat-accordion>
        </div>

        <div *ngIf="primasInternacional.length > 0" class="mb-4 mb-md-5">
          <p class="h4">Internacional</p>
          <mat-accordion>
            <app-card-resultado *ngFor="let prima of primasInternacional" [prima]="prima"></app-card-resultado>
          </mat-accordion>
        </div>










        <!-- <mat-accordion class="d-block d-md-none">
                    <mat-expansion-panel *ngFor="let prima of primasResult | async">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{prima.nombre}}
                            </mat-panel-title>
                            <mat-panel-description class="justify-content-end align-items-center">
                                <span>{{prima.total | currency:'S/ ':'symbol':'1.0-0'}}</span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div>
                            <div class="d-flex justify-content-between my-1">
                                <span>Titular <span class="text-muted small">({{prima.edadTitular}} años)</span></span>
                                <span>{{prima.primaTitular | currency:'S/ ':'symbol':'1.0-0'}}</span>
                            </div>
                            <div class="d-flex justify-content-between my-1" *ngFor="let familiar of prima.familiares">
                                <span>{{familiar.vinculo}} <span class="text-muted small">({{familiar.edad}} años)</span></span>
                                <span>{{familiar.prima | currency:'S/ ':'symbol':'1.0-0'}}</span>
                            </div>
                            <hr class="my-2">
                            <div class="d-flex justify-content-end semibold h6 mb-0">
                                <span class="mr-2">Prima Anual:</span>
                                <span>{{prima.total | currency:'S/ ':'symbol':'1.0-0'}}</span>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion> -->


      </div>
    </div>
  </div>
</div>
<!-- <pre>{{primasSalud | json}}</pre> -->