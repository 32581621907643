import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { Timestamp } from 'firebase/firestore';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-crear-cotizacion-salud',
  templateUrl: './crear-cotizacion-salud.component.html',
  styleUrls: ['./crear-cotizacion-salud.component.scss']
})
export class CrearCotizacionSaludComponent implements OnInit {

  
  formSalud: UntypedFormGroup;
  loading = false;
  edades = [];
  vinculos = [ 'Cónyuge', 'Hijo(a)' ];

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private fs: FirebaseService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.formSalud = this.fb.group({
      celular: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(9), Validators.maxLength(9)]],
      edadTitular: ['', Validators.required],
      familiares: this.fb.array([]),
    });

    this.fs.getPrimasSaludAdulto().subscribe( (res: any) => {
      const edadesOrigin = res.map(a => a.edad);
      this.edades = edadesOrigin;
    });
  }

  onSubmit() {
    this.crearcontacto()
    .then( () => {
      this.router.navigate(['/admin/solicitudesSalud'])
    });
    this.loading = true;
    setTimeout(() => {
    }, 300);
  }

  
  crearcontacto() {
    return this.afs.collection('/cotizacionesSalud').add({
      dataContacto: {
        celular: this.formSalud.value.celular,
        fechaCreacion: Timestamp.now()
      },
      dataSeguro: {
        edadTitular: this.formSalud.value.edadTitular,
        familiares: this.formSalud.value.familiares
      },
      oculto: false
    });
  }

  getFamiliar() {
    return this.fb.group({
      vinculo: ['', Validators.required],
      edad: ['', Validators.required],
      costo: ['']
    });
  }

  agregarFamiliar() {
    const control = this.formSalud.controls.familiares as UntypedFormArray;
    control.push(this.getFamiliar());
  }

  eliminarFamiliar(i: number) {
    const control = this.formSalud.controls.familiares as UntypedFormArray;
    control.removeAt(i);
  }

  errorCelular() {
    return this.formSalud.controls.celular.hasError('required') ? 'Ingrese el número de celular' :
    this.formSalud.controls.celular.hasError('pattern') ? 'Solo se admiten números' :
    this.formSalud.controls.celular.hasError('minlength') ? 'Debe contener 9 dígitos' :
    this.formSalud.controls.celular.hasError('maxlength') ? 'No debe exceder los 9 dígitos' :
    '';
  }

  errorEdadTitular() {
    return this.formSalud.controls.edadTitular.hasError('required') ? 'Selecciona la edad del titular' : '';
  }

}
