import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
// import { FileValidator } from 'ngx-material-file-input';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { Timestamp } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-crear-aseguradora',
  templateUrl: './crear-aseguradora.component.html',
  styleUrls: ['./crear-aseguradora.component.scss']
})
export class CrearAseguradoraComponent implements OnInit {

  formAseguradora: UntypedFormGroup;
  deshabilitado: boolean;

  selectedFile: FileList | null;
  nameItem: any;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  // 1 MB
  readonly maxSize = 1048576;
  actualSize: any;

  constructor(
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.formAseguradora = this.fb.group({
      nombre: ['', Validators.required],
      // logo: [ '', [Validators.required, FileValidator.maxContentSize(this.maxSize)]],
      publicado: [false],
      fechaCreacion: [Timestamp.now()],
    })
  }

  onSubmit() {
    if (this.formAseguradora.valid) {
      this.uploadFileAndCrearAseguradora();
      this.deshabilitado = true;
    } else {
      this.validateAllFormFields(this.formAseguradora);
      this.deshabilitado = false;
    }
  }


  detectFiles(event) {
    this.selectedFile = event.target.files[0];
    this.nameItem = event.target.files[0].name;
    console.log(this.nameItem);
  }

  uploadFileAndCrearAseguradora() {
    const aseguradoras = this.afs.collection('aseguradoras').ref.doc();
    console.log(aseguradoras.id);

    const file = this.selectedFile;
    const filePath = `aseguradoras/${aseguradoras.id}/${this.nameItem}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    this.uploadPercent = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().toPromise().then( (url) => {
          this.downloadURL = url;
          aseguradoras.set({
            nombre: this.formAseguradora.value.nombre,
            fechaCreacion: this.formAseguradora.value.fechaCreacion,
            publicado: this.formAseguradora.value.publicado,
            nombreImage: this.nameItem,
            logo: this.downloadURL,
          });
          this.router.navigate(['admin/aseguradoras']);
          console.log( this.downloadURL );
        }).catch(err => { console.log(err); } );
      })
    )
    .subscribe();
  }


  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  errorNombre() {
    return this.formAseguradora.controls.nombre.hasError('required') ? 'El nombre es necesario' : '';
  }

  errorImagen() {
    return this.formAseguradora.controls.logo.hasError('required') ? 'La imagen es necesaria' :
    this.formAseguradora.controls.logo.hasError('maxContentSize') ? 'El peso no debe exceder a 1 MB' : '';
  }


  

  

}
