<div mat-dialog-title class="d-flex justify-content-end mb-0">
    <!-- <p class="titleModal mb-0  pl-3 pt-3">Tratamiento de datos personales</p> -->
    <button class="pt-1 pr-1 pt-md-2 pr-md-2" mat-icon-button (click)="cancelar()"><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content class="px-3">
    <div class="px-3 px-md-6 py-0 py-md-3 text-dark">
        <p class="titleModal">Política de privacidad</p>
        <div class="bodyModal">
            <p>La Previsora considera importante garantizar la protección de Datos Personales de sus clientes. Es por eso que, en cumplimiento con la Ley Nro. – 29733, Ley de Protección de Datos Personales (en adelante “LPDP”) y el D.S. No. 003-2013 que
                aprueba el Reglamento de la LPDP, hemos desarrollado las medidas de seguridad necesarias para el correcto uso de su información.</p>
            <p class="subtitleModal">1. Tratamiento de datos personales</p>
            <p>Los datos personales que nos proporciona, sea de manera física, oral o electrónica, son los necesarios para brindarles nuestros servicios, como gestionar sus pólizas de seguros, atenderlo ante un siniestro, hacer seguimiento de diversos trámites,
                fines comerciales, entre otros similares.</p>
            <p class="subtitleModal">2. Derechos del titular de datos personales</p>
            <p>Como titular de datos personales, tiene los siguientes derechos:</p>
            <ul>
                <li>
                    Derecho de Acceso / Información: tiene derecho a conocer y obtener información sobre los datos personales con los que contamos en nuestros bancos, la finalidad para la cual son utilizados tus datos personales, quiénes tienen acceso a ellos, en cuáles
                    de nuestras bases de datos se almacenan.
                </li>
                <li>
                    Derecho de Rectificación / Actualización: Solicitar la modificación, actualización, inclusión de los datos que resulten inexactos,incompletos, desactualizados o falsos.
                </li>
                <li>
                    Derecho de Cancelación / Supresión: eliminar sus datos de nuestro banco de datos si han dejado de ser necesarios para la finalidad que fueron recopilados, si venció el plazo para su tratamiento o cuando el tratamiento no se realice conforme a las normas
                    de protección de datos personales.
                </li>
                <li>
                    Derecho de oposición: puede oponerse a que sus datos sean tratados o que formen parte de nuestro banco de datos cuando no haya autorizado su recopilación, por haber sido tomada de fuentes de acceso al público o por un motivo legítimo y fundado, referido
                    a una situación personal concreta.
                </li>
                <li>
                    Derecho de Revocación: dejar sin efecto, en cualquier momento y sin justificación previa, la autorización al tratamiento de datos que hubieses dado en algún momento.
                </li>
            </ul>

            <p>Usted puede ejercer sus derechos que como titular de datos personales le asisten (acceso, rectificación, cancelación, oposición, información o revocación). Para tal efecto, puede enviar una comunicación gratuitamente a previsora@previsora.pe
                con la referencia: “Atención de Derechos ARCO”.</p>
        </div>

    </div>
</mat-dialog-content>