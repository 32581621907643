import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FirebaseService } from 'src/app/services/firebase.service';
import { EliminarBeneficioComponent } from '../eliminar-beneficio/eliminar-beneficio.component';

@Component({
  selector: 'app-lista-beneficios',
  templateUrl: './lista-beneficios.component.html',
  styleUrls: ['./lista-beneficios.component.scss']
})
export class ListaBeneficiosComponent implements OnInit {

  count: number;
  loading: boolean;
  displayedColumns = [  'id', 'fechaCreacion', 'titulo', 'publicado', 'destacado', 'mas' ];
  beneficiosData = new MatTableDataSource();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.beneficiosData.filter = filterValue;
  }

  constructor(
    private fs: FirebaseService,
    private dialog: MatDialog,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.fs.getBeneficiosAll().subscribe(data => {
      this.beneficiosData.data = data;
      this.loading = false;
      this.count = data.length;
    });
    this.beneficiosData.paginator = this.paginator;
    this.beneficiosData.sort = this.sort;
  }


  eliminarAviso(idProduct) {
    this.fs.deleteBanner(idProduct);
  }


  openModalDelete(aseguradora) {
    this.dialog.open(EliminarBeneficioComponent, {
      panelClass: 'dialogDeleteSolicitud',
      data: aseguradora
    });
  }


  ngAfterViewInit() {
    this.beneficiosData.paginator = this.paginator;
    // this.avisosData.sort = this.sort;
  }


  // actualizarPublicado(key, obj, e) {
  //   this.fs.updateAprobado(key, e);
  // }

  actualizarPublicado(id, publicado) {
    this.afs.doc('beneficios/' + id).update({ publicado });
  }
  

  trackByPublicado(item) {
    return item.publicado;
  }

  actualizarDestacado(id, destacado) {
    this.afs.doc('beneficios/' + id).update({ destacado});
  }

}
