<div class="px-3 px-md-4 py-3 py-md-4">

  <div class="d-flex flex-column">
    <a class="linkMenuMobile" *ngFor="let link of linksMenu" [routerLink]="link.url" (click)="sidenavMain.toggle()" data-bs-dismiss="offcanvas">{{link.nombre}}</a>
  </div>

  <div class="d-grid gap-2 mt-3 mt-md-4">
    <button class="btn btn-outline-secondary" (click)="openModalConsultas()" data-bs-dismiss="offcanvas" aria-label="Close">Consultas / siniestros</button>
    <a class="btn btn-outline-secondary" href="https://previnet.previsora.pe/admin/login" target="blank">Mis seguros</a>
    <a class="btn btn-primary shadow-sm" routerLink="/cotizadores" data-bs-dismiss="offcanvas" aria-label="Close">
      <i-tabler class="me-2" name="calculator"></i-tabler>
      COMPARA SEGUROS
    </a>
  </div>

 

</div>
