<!-- <nav class="container">
    <ol class="breadcrumb small">
        <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
        <li class="breadcrumb-item active">Nosotros</li>
    </ol>
</nav> -->

<div class="backNosotros">
    <div class="container d-flex justify-content-end align-items-center h-100">
        <!-- <div class="caption">
            <p class="h3 font-weight-light text-right">Más de 30 años de experiencia brindando asesoría profesional y especializada en seguros.</p>
        </div> -->
    </div>
</div>

<div class="container">
    <div class="col-md-7 mx-auto text-center py-3 py-md-5">
        <!-- <p class="h1">Nosotros</p> -->
        <p class="h3 font-weight-light">Más de 30 años de experiencia brindando asesoría profesional y especializada en seguros.</p>

    </div>
    <div class="grillaNosotros">
        <div class="rounded-3 p-4 myShadow d-flex align-items-start" *ngFor="let caracteristica of caracteristicas">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check me-2" width="48" height="48" viewBox="0 0 24 24" stroke-width="1" stroke="#82EA79" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z"/>
              <circle cx="12" cy="12" r="9" />
              <path d="M9 12l2 2l4 -4" />
            </svg>
            </div>

            <p class="mb-0"> {{caracteristica}}</p>
        </div>
    </div>

    <div class="text-center col-md-6 mx-auto">
        <div class="pt-4 pt-md-6">
            <p class="h2 text-primary">#EstamosAntes</p>
            <p>Estamos antes de que suceda algún imprevisto para asesorarte de la mejor manera y brindarte la información necesaria para que puedas prevenir.</p>
        </div>
        <div class="pt-4 pt-md-6">
            <p class="h2 text-secondary">#EstamosAhora</p>
            <p>Estamos ahora para ayudarte en el momento en que lo necesites ya sea por un siniestro que acaba de suceder. Ponte en contacto con nosotros.</p>
        </div>
        <div class="pt-4 pt-md-6">
            <p class="h2 text-success">#EstamosSiempre</p>
            <p>Estamos ahora para ayudarte en el momento en que lo necesites ya sea por un siniestro que acaba de suceder. Ponte en contacto con nosotros.</p>
        </div>
    </div>
</div>