import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-asignar',
  templateUrl: './asignar.component.html',
  styleUrls: ['./asignar.component.scss']
})
export class AsignarComponent implements OnInit {

  deshabilitado: boolean;
  corredor = new UntypedFormControl(this.data.corredor, [Validators.required]);
  corredorSelected: string;

  corredores = [
    'La Previsora',
    'Eagles',
    'Centra'
  ];

  constructor(
    public dialogRef: MatDialogRef<AsignarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.corredor.valueChanges.subscribe( res => {
      this.corredorSelected = res
      console.log(this.corredorSelected);
    });
  }

  
  asignarCorredor(idSolicitudSalud) {
    this.deshabilitado = true;
    this.afs.doc('cotizacionesSalud/' + idSolicitudSalud).update({
      corredor: this.corredor.value
    })
    .then(() => {
      this.notificarEmail(idSolicitudSalud);
      this.dialogRef.close();
      this.snackBar.open(`Asignado a ${this.corredor.value}` , 'CERRAR', {
        duration: 3000,
      });
    });
    
  }

  cancelar() {
    this.dialogRef.close(false);
  }

  textMessage(whatsAppCorredor, nombreCorredor) {
    window.open(`https://api.whatsapp.com/send?phone=51${whatsAppCorredor}&text=Hola ${nombreCorredor}, se ha registrado una nueva cotización de Seguro de Salud: Celular ${this.data.item.dataContacto.celular}, la edad del titular es ${this.data.item.dataSeguro.edadTitular} años. Familiares: ${this.data.item.dataSeguro.familiares.length > 0 ? `${this.data.item.dataSeguro.familiares.map( familiar => `${familiar.vinculo} de ${familiar.edad} años`)}` : 'Ninguno'}`, '_blank');
  }



  notificarEmail(idSolicitudSalud) {
    if (this.corredor.value === 'La Previsora') {
      this.afs.doc('cotizacionesSaludPrevisora/' + idSolicitudSalud).set({
        corredor: 'La Previsora',
        dataContacto: this.data.item.dataContacto,
        dataSeguro: this.data.item.dataSeguro
      });
      this.textMessage('941851049', 'La Previsora');
    }
    if (this.corredor.value === 'Eagles') {
      this.afs.doc('cotizacionesSaludEagles/' + idSolicitudSalud).set({
        corredor: 'Eagles',
        dataContacto: this.data.item.dataContacto,
        dataSeguro: this.data.item.dataSeguro
      });
      this.textMessage('941851049', 'Eagles');
    }
    if (this.corredor.value === 'Centra') {
      this.afs.doc('cotizacionesSaludCentra/' + idSolicitudSalud).set({
        corredor: 'Centra',
        dataContacto: this.data.item.dataContacto,
        dataSeguro: this.data.item.dataSeguro
      });
      this.textMessage('993506749', 'Centra');
    }
  }

}
