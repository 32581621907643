<div class="container py-2 py-md-3">
    <nav>
        <ol class="breadcrumb small">
            <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
            <li class="breadcrumb-item active">Contacto</li>
        </ol>
    </nav>

    <div class="py-4 py-md-5">
        <p class="h1 mb-2 mb-md-3 font-weight-light">Conversemos</p>
        <div class="row d-flex justify-content-between">
            <div class="col-md-4">
                <p class="">Te asesoramos antes, durante y después de la contratación de tu seguro, con las mejores condiciones al mejor precio.</p>
                <hr>
                <p class="text-muted small">Inscrita en la ficha Nro. 134211 del Registro Mercantil de Lima. Registro SBS J593</p>
            </div>
            <div class="col-md-6">
                <p class="mb-1 small">Asesoría al instante:</p>
                <a class="btn btn-primary" href="https://api.whatsapp.com/send?phone=51998161616&text=Hola%20Jaime,%20necesito%20que%20me%20asesores%20con%20un%20seguro" target="blank">
                    <span class="mr-1">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#41D4C1" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z"/>
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 11l2 2l4 -4" />
                  </svg>
                </span> Conversa con Jaime León
                </a>
                <p class="mt-3 mb-0 small">Llámanos al:</p>
                <a class="h5 font-weight-light" href="tel:014402828">440-2828</a>
                <p class="mt-3 mb-0 small">Escríbenos a:</p>
                <a class="h5 font-weight-light" href="mailto:jleon@previsora.pe">jleon@previsora.pe</a>

            </div>
        </div>
    </div>
</div>