<div class="p-1 p-md-3">

  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>


  <p class="h3 my-2">{{data.item_.plan}}</p>

  <!-- <div class="small">
    <div>
      <span class="text-muted">Marca: </span>
      <span>{{data.formVehicularMoto_.get('marca').value}}</span>
    </div>
    <div>
      <span class="text-muted">Año de fabricación: </span>
      <span>{{data.formVehicularMoto_.get('anioFabricacion').value}}</span>
    </div>
    <div>
      <span class="text-muted">Valor comercial: </span>
      <span>USD {{data.formVehicularMoto_.get('valorComercial').value}}</span>
    </div>
  </div> -->

  <div class="small mb-3">
    <div *ngFor="let item of caracteristicas">
      <span>{{item.nombre}}: </span>
      <span>{{item.valor}}</span>
    </div>
  </div>

  <div class="d-grid gap-3 w-100 mb-1">
    <a class="btn btn-success" [href]="messageWhatsApp" (click)="sendEventAnalitycsWhatsApp()" target="_blank">Pídelo por WhatsApp</a>
    <a class="btn btn-light fw-normal" href="tel:{{numero}}" target="_blank" (click)="sendEventAnalitycsLlamada()">Llámanos al {{numero}}</a>
  </div>

</div>