import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-derechos-arco',
  templateUrl: './derechos-arco.component.html',
  styleUrls: ['./derechos-arco.component.scss']
})
export class DerechosArcoComponent implements OnInit {

  derechos = [
    {
      nombre: 'A-cceso',
      descripcion: 'Derecho de Acceso: tienes derecho a conocer cuáles de tus datos personales están incluidos en nuestra base de datos, la forma en que fueron recopilados, las razones que motivaron su recopilación y a solicitud de quién se hizo su recopilación, las transferencias realizadas, así como las condiciones y uso que les damos.'
    },
    {
      nombre: 'R-ectificación',
      descripcion: 'Puedes solicitar la actualización, inclusión o modificación de tus datos personales cuando sean parcial o totalmente inexactos, incompletos, erróneos o falsos. Debes especificar en la sección "Descripción de la solicitud" los datos personales que deseas actualizar, incluir o modificar. Además, debes adjuntar los documentos de sustento aplicables.'
    },
    {
      nombre: 'C-ancelación',
      descripcion: 'Puedes solicitar que cancelemos alguno de tus datos personales (cancelación parcial) o todos ellos (cancelación total) de nuestras bases de datos. La solicitud procederá: Si tus datos personales han dejado de ser necesarios para la finalidad que fueron recopilados. Cuando hubiera vencido el plazo establecido para su tratamiento; o Si tus datos personales no están siendo utilizados conforme a las normas de protección de datos personales. Ten en consideración que tu solicitud no procederá si mantienes una relación contractual con nosotros y tus datos son necesarios para ejecutar dicha relación contractual. Sin perjuicio del ejercicio de tu derecho de cancelación y supresión la empresa conservará la información que corresponda por el plazo previsto en las normas vigentes.'
    },
    {
      nombre: 'O-posición',
      descripcion: 'Tienes derecho a oponerte a figurar en nuestras bases de datos personales o a que utilicemos tus datos personales: Cuando no hayas autorizado su recopilación por haber sido tomados de fuentes públicas; o Cuando habiendo dado tu consentimiento, acredites la existencia de motivos que justifiquen tu pedido.'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
