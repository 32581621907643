<div class="col-md-6 mx-auto py-4 py-md-6">
    <p class="h4">Crear Prima Salud</p>
    <form [formGroup]="formPrima" (submit)="onSubmit()" novalidate>
        <div class="row">
            <mat-form-field class="col-12">
                <mat-label>Edad</mat-label>
                <input matInput type="number" formControlName="edad">
            </mat-form-field>
            <mat-form-field class="col-4">
                <mat-label>Medicvida Internacional</mat-label>
                <input matInput type="number" formControlName="medicvidaInternacional">
            </mat-form-field>
            <mat-form-field class="col-4">
                <mat-label>Salud Preferencial</mat-label>
                <input matInput type="number" formControlName="saludPreferencial">
            </mat-form-field>
            <mat-form-field class="col-4">
                <mat-label>Internacional Previsora</mat-label>
                <input matInput type="number" formControlName="internacionalPrevisora">
            </mat-form-field>
            <mat-form-field class="col-4">
                <mat-label>Medicvida Nacional</mat-label>
                <input matInput type="number" formControlName="medicvidaNacional">
            </mat-form-field>
            <mat-form-field class="col-4">
                <mat-label>Fullsalud</mat-label>
                <input matInput type="number" formControlName="fullsalud">
            </mat-form-field>
            <mat-form-field class="col-4">
                <mat-label>Multisalud</mat-label>
                <input matInput type="number" formControlName="multisalud">
            </mat-form-field>
            <mat-form-field class="col-4">
                <mat-label>Red Médica</mat-label>
                <input matInput type="number" formControlName="redMedica">
            </mat-form-field>
            <mat-form-field class="col-4">
                <mat-label>Trebol Salud</mat-label>
                <input matInput type="number" formControlName="trebolSalud">
            </mat-form-field>
            <mat-form-field class="col-4">
                <mat-label>Red Preferente Pacífico</mat-label>
                <input matInput type="number" formControlName="redPreferentePacifico">
            </mat-form-field>
            <mat-form-field class="col-4">
                <mat-label>Red Preferente Rimac</mat-label>
                <input matInput type="number" formControlName="redPreferenteRimac">
            </mat-form-field>
            <mat-form-field class="col-4">
                <mat-label>Viva Salud</mat-label>
                <input matInput type="number" formControlName="vivaSalud">
            </mat-form-field>
            <mat-form-field class="col-4">
                <mat-label>Viva Salud Plus</mat-label>
                <input matInput type="number" formControlName="vivaSaludPlus">
            </mat-form-field>
        </div>
        <div class="text-right mt-3">
            <button class="btn btn-primary">Crear Prima</button>
        </div>
    </form>
</div>