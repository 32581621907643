import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user;
  displayName: string;

  constructor(
    public router: Router,
    public snackBar: MatSnackBar,
  ) { }

  async emailLogin(email: string, password: string ) {

    const auth = getAuth();

    await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      console.log(user);
      this.router.navigate(['/admin']);
    })
    .catch((error) => {
      const errorMessage = error.message;
      console.log(errorMessage);
    });

  }

  signOut() {

    const auth = getAuth();
    signOut(auth).then(() => {
      this.router.navigate(['/login']);
    }).catch((error) => {
      console.log(error);
      
    });
  }


}
