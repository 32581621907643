<div class="pl-3">
  <div class="text-end">
    <button mat-icon-button (click)="cancelar()">
      <img src="assets/images/iconClose.svg" alt="">
    </button>
  </div>
  <div class="d-flex flex-column">

    <a class="linkMenuMobile" *ngFor="let link of linksMenu" [routerLink]="link.url" (click)="cancelar()">{{link.nombre}}</a>

    <hr>

    <div class="mb-3">
      <a class="btn btn-outline-primary btn-circle mr-1" routerLink="/cotizadores" (click)="cancelar()">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calculator" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="4" y="3" width="16" height="18" rx="2" />
          <rect x="8" y="7" width="8" height="3" rx="1" />
          <line x1="8" y1="14" x2="8" y2="14.01" />
          <line x1="12" y1="14" x2="12" y2="14.01" />
          <line x1="16" y1="14" x2="16" y2="14.01" />
          <line x1="8" y1="17" x2="8" y2="17.01" />
          <line x1="12" y1="17" x2="12" y2="17.01" />
          <line x1="16" y1="17" x2="16" y2="17.01" />
        </svg>
      </a>
      <a class="btn btn-outline-primary btn-circle mr-1" href="https://api.whatsapp.com/send?phone=51998161616&text=Hola,%20necesito%20que%20me%20asesoren%20con%20un%20seguro" target="blank" (click)="cancelar()">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-whatsapp" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
          <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" />
        </svg>
      </a>
      <a class="btn btn-outline-primary btn-circle mr-1" href="tel:998161616" (click)="cancelar()">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" />
          <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
        </svg>
      </a>
    </div>

    <div class="d-grid gap-2">
      <a class="btn btn-primary shadow-sm" routerLink="/cotizadores" (click)="cancelar()">COMPARA SEGUROS</a>
      <a class="btn btn-outline-primary" href="https://previnet.previsora.pe/admin/login" target="blank" (click)="cancelar()">Zona clientes</a>
    </div>


<!-- 
    <div>
      <a class="btn btn-outline-primary" href="https://previnet.previsora.pe/admin/login" target="blank" (click)="cancelar()">
        <span class="d-flex align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user mr-1" width="21" height="21" viewBox="0 0 24 24" stroke-width="2" stroke="#7960E0" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" />
            <circle cx="12" cy="7" r="4" />
            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
          </svg>
          Zona clientes
        </span>
      </a>
    </div> -->



    <!-- <div>
            <a class="mr-2" href="https://www.facebook.com/LaPrevisoraCorredores" target="blank">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-facebook" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4B445F" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z"/>
            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
          </svg>
            </a>
            <a class="mr-4" href="https://www.instagram.com/laprevisoracorredores/" target="blank">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-instagram" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4B445F" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z"/>
            <rect x="4" y="4" width="16" height="16" rx="4" />
            <circle cx="12" cy="12" r="3" />
            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
          </svg>
            </a>
        </div>

        <div class="mb-4 mb-md-0">
            <hr>
            <p class="mb-0">La Previsora Corredores de Seguros S.A.C.</p>
            <p class="mb-0 small text-muted">Jr. Tomas Ramsey 930 of. 309, Magdalena. Lima - Perú.</p>
            <p class="mb-0 small text-muted"><span class="font-weight-bold">T. </span><a class="text-reset" href="tel:014402828">(01)440-2828</a> <span class="mx-2">|</span> <span class="font-weight-bold">C. </span> <a class="text-reset" href="tel:51998161616">998161616</a></p>
            <a class="text-reset small text-muted" href="mailto:jleon@previsora.pe">jleon@previsora.pe</a>
        </div> -->

  </div>
</div>