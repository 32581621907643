<div class="p-3 p-md-4">

  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <p class="h4 mb-3">Exclusiones principales</p>

  <div *ngFor="let exclusion of data">
    <div class="d-flex gap-1 mb-2">
      <div>
        <i-tabler name="x" class="text-danger me-1" style="width: 21px;"></i-tabler>
      </div>
      <div>
        <p class="mb-0 small">{{exclusion}}</p>
      </div>
    </div>
  </div>

</div>