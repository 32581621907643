import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editar-reporte-bi',
  templateUrl: './editar-reporte-bi.component.html',
  styleUrls: ['./editar-reporte-bi.component.scss']
})
export class EditarReporteBiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
