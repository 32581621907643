<!-- <div class="d-flex align-items-center pr-3 pr-md-4">
    <img class="imgSeguro rounded-left mr-3 mr-md-4" [src]="seguro.image">
    <p class="lead mb-0">{{seguro.nombre}}</p>
    <img class="ml-auto" src="assets/images/arrowRight.svg" alt="">
</div>
<a class="stretched-link" [routerLink]="'segurosPersonas/' + seguro.id"></a> -->


<div class="position-relative">
    <img class="imgCardSeguro" [src]="seguro.image">
    <div class="bg-light baseCardSeguro d-flex justify-content-between align-items-center p-3">
        <p class="mb-0" style="line-height: 120%;">{{seguro.nombre}}</p>
        <div class="d-none d-md-block">
            <button class="buttonCardSeguro">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#41D4C1" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z"/>
              <line x1="5" y1="12" x2="19" y2="12" />
              <line x1="13" y1="18" x2="19" y2="12" />
              <line x1="13" y1="6" x2="19" y2="12" />
            </svg>
          </button>
        </div>
    </div>
    <a class="stretched-link" [routerLink]="'/seguros' + seguro.categoria + '/' + seguro.id"></a>
</div>