import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalle-solicitud-salud',
  templateUrl: './detalle-solicitud-salud.component.html',
  styleUrls: ['./detalle-solicitud-salud.component.scss']
})
export class DetalleSolicitudSaludComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
