import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Banner } from 'src/app/classes/banner';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-eliminar-banner',
  templateUrl: './eliminar-banner.component.html',
  styleUrls: ['./eliminar-banner.component.scss']
})
export class EliminarBannerComponent implements OnInit {

  deshabilitado: boolean;

  constructor(
    private fs: FirebaseService,
    private dialogRef: MatDialogRef<EliminarBannerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Banner,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  eliminarBanner(idReserva) {
    this.deshabilitado = true;
    this.fs.deleteBanner(idReserva)
    .then(() => {
      this.dialogRef.close();
      this.snackBar.open('Banner eliminado', 'CERRAR', {
        duration: 3000,
      });
    });

    this.fs.deleteBannerStorage(this.data.id, this.data.imageName);
  }

  cancelar() {
    this.dialogRef.close();
  }

}
