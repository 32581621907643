<div class="container">


  <nav class="extraSmall my-2 mb-3 mb-md-5">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item active">Aseguradoras</li>
    </ol>
  </nav>


  <div class="d-flex justify-content-between align-items-center mb-2 mb-md-3">
		<p class="h2 mb-0">Aseguradoras</p>
		<div>
			<a class="btn btn-primary" routerLink="crearAseguradora">Agregar</a>
		</div>
	</div>



  <div class="table-responsive">

		<table mat-table [dataSource]="aseguradorasData" matSort class="example-table" [trackBy]="trackByPublicado">

      <ng-container matColumnDef="imagen">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Imagen</th>
				<td mat-cell *matCellDef="let aseguradora"> <img class="imageAseguradoraAdmin border my-2 rounded"
						[src]="aseguradora.logo"></td>
			</ng-container>

			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
				<td mat-cell *matCellDef="let aseguradora"> {{aseguradora.id}} </td>
			</ng-container>

			<ng-container matColumnDef="fechaCreacion">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha creación</th>
				<td mat-cell *matCellDef="let aseguradora"> {{ aseguradora.fechaCreacion.toDate() | date: 'medium' }} </td>
			</ng-container>

			<ng-container matColumnDef="nombre">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
				<td mat-cell *matCellDef="let aseguradora"> {{ aseguradora.nombre }} </td>
			</ng-container>

			<ng-container matColumnDef="publicado">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Publicado</th>
				<td mat-cell *matCellDef="let aseguradora">
					<mat-slide-toggle color="primary" #toggle [ngModel]="aseguradora.publicado"
						(ngModelChange)="actualizarPublicado(aseguradora.id, aseguradora, $event)">
					</mat-slide-toggle>
				</td>
			</ng-container>







      <ng-container matColumnDef="mas">
				<th mat-header-cell *matHeaderCellDef class=""></th>
				<td mat-cell *matCellDef="let item" class="text-end">
					<button mat-icon-button [matMenuTriggerFor]="menu">
						<mat-icon class="material-icons-outlined">more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">

						<button mat-menu-item (click)="openModalDelete(item)">
							<mat-icon class="material-icons-outlined text-danger">delete</mat-icon>
							<span class="text-danger">Eliminar reserva</span>
						</button>

					</mat-menu>
				</td>
			</ng-container>





			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>

	<mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
	</mat-paginator>

</div>