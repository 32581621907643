<div class="p-1 p-md-3">
  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <p class="h4 mt-2 mt-md-0">Asignar a</p>
  <div class="my-2 my-md-3">
    <mat-radio-group class="d-flex flex-column" [formControl]="corredor">
      <mat-radio-button class="my-1" *ngFor="let corredor of corredores" [value]="corredor">{{corredor}}</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="d-grid">
    <button type="submit" class="btn btn-primary" (click)="asignarCorredor(data.idSolicitudSalud)">
      <span class="myColorSpinnerWhite d-flex justify-content-center" *ngIf="deshabilitado">
        <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
      </span>
      <div *ngIf="!deshabilitado">
        <span>Enviar notificación</span>
      </div>
    </button>
  </div>
</div>