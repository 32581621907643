import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { ConsultasComponent } from '../consultas/consultas.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() sidenavMain;
  linksMenu;

  constructor(
    private ds: DataService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.linksMenu = this.ds.linksMenu;
  }

  openModalConsultas() {
    this.dialog.open(ConsultasComponent, {
      panelClass: 'modalConsultasSiniestros'
    })
  }

}
