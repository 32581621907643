import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MotivoComponent } from '../motivo/motivo.component';

@Component({
  selector: 'app-estado',
  templateUrl: './estado.component.html',
  styleUrls: ['./estado.component.scss']
})
export class EstadoComponent implements OnInit {

  deshabilitado: boolean;
  estado = new UntypedFormControl(this.data.estado, [Validators.required]);

  estados = [
    'No contactado', 'Contactado', 'Cotizado', 'No desea', 'Respuesta pendiente', 'Ganada'
  ];

  
  constructor(
    public dialogRef: MatDialogRef<MotivoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
  }

  actualizarMotivo(idSolicitudSalud) {
    this.deshabilitado = true;
    this.afs.doc('cotizacionesSalud/' + idSolicitudSalud).update({
      estado: this.estado.value
    })
    .then(() => {
      this.dialogRef.close();
      this.snackBar.open('Motivo actualizado', 'CERRAR', {
        duration: 3000,
      });
    });
  }

  cancelar() {
    this.dialogRef.close(false);
  }


}
