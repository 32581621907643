import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.component.html',
  styleUrls: ['./promociones.component.scss']
})
export class PromocionesComponent implements OnInit {

  promociones;

  constructor(
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.getPromociones();
  }

  getPromociones() {
    this.promociones = this.afs.collection('banners', ref => ref
      .where('publicado', '==', true)
      .orderBy('fechaCreacion', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
    return this.promociones;
  }


}
