<div>

  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="mb-3">
    <p class="h3 mb-2">¿Quiénes somos?</p>
  </div>

  <div class="lh-1">
    <p class="mb-1">¿Somos una compañía de seguros?</p>
    <p class="text-muted small">No. Somos un corredor de seguros, es decir un intermediario entre el usuario que adquiere el seguro y la compañía aseguradora.</p>

    <p class="mb-1">¿Contratar un seguro con La Previsora cuesta más?</p>
    <p class="text-muted small">No. Contratar tu seguro a través de La Previsora no tiene ningún costo adicional para ti.</p>

    <p class="mb-1">¿Entonces qué gana La Previsora?</p>
    <p class="text-muted small">Recibimos una comisión de agenciamiento, es decir la compañía aseguradora es quien nos paga.</p>

    <p class="mb-1">¿Cuál es el beneficio de contratar un seguro con La Previsora?</p>
    <p class="text-muted small mb-0">Te ofrecemos productos de todas las aseguradoras. Dado que trabajamos con todas las compañías siempre tendrás el mejor seguro, al mejor precio, con las mejores coberturas.</p>
  </div>

</div>