import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-form-contact-direct',
  templateUrl: './form-contact-direct.component.html',
  styleUrls: ['./form-contact-direct.component.scss']
})
export class FormContactDirectComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FormContactDirectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private analytics: AngularFireAnalytics,
  ) { }

  ngOnInit(): void {
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  clickVideollamada() {
    this.analytics.logEvent('botonVideollamadaSalud');
  }

  clickWhatsApp() {
    this.analytics.logEvent('botonWhatsAppSalud');
  }

  clickLlamada() {
    this.analytics.logEvent('botonLlamadaSalud');
  }

}
