<!-- <div class="py-3 py-md-6">

  <div class="row d-flex justify-content-between gy-4">


    <div class="col-md-4 text-center text-md-start">
      <p class="h1 lh-1">Compara seguros</p>
      <p class="">Precios al instante, coberturas, copagos entre las principales compañías de seguros</p>
      <div class="grillaAseguradorasComparadores px-4 px-md-0">
        <div *ngFor="let item of aseguradoras">
          <img [src]="item.logo" alt="" width="100%">
        </div>
      </div>
    </div>


    <div class="col-md-12">

      <div class="d-flex align-items-center mb-3">
        <span class="me-2">
          <img src="https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/iconCalcSoles.svg?alt=media&token=a564e34e-76f5-4b0b-98f0-a1f3a68e8969" alt="">
        </span>
        <p class="text-primary h4 fw-light mb-0" style="letter-spacing: 0.3rem;">
          COMPARA SEGUROS</p>
      </div>


      <div class="grillaComparadores">

        <div class="rounded-3 bg-light p-3 p-md-4 px-lg-5 position-relative" *ngFor="let comparador of comparadores">
          <div class="d-flex flex-row flex-md-column">
            <div class="me-3">

              <img class="mb-3" [src]="comparador.image" alt="" width="81px" height="81px">
             
            </div>
            <div class="">
              <p class="text-uppercase small mb-1">{{comparador.descripcion}}</p>
              <p class="h4 lh-1">{{comparador.nombre}}</p>

              <div class="mt-3">
                <a class="stretched-link" [routerLink]="comparador.url">Comparar
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right ms-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <line x1="15" y1="16" x2="19" y2="12" />
                    <line x1="15" y1="8" x2="19" y2="12" />
                  </svg>
                </a>
              </div>

              <a class="stretched-link" [routerLink]="comparador.url"></a>
             
            </div>
          </div>
  
        </div>

      </div>

    </div>
  </div>

</div> -->







<div class="my-3 my-md-5">

  <div class="row justify-content-between align-items-center">

    <div class="col-md-4">

      <div class="d-flex gap-3 align-items-center mb-3">

        <div>
          <i-tabler class="text-primary" name="calculator" style="width: 96px; height: 96px; stroke-width: 1;"></i-tabler>
        </div>
        <p class="text-primary h2 fw-light mb-0 lh-1" style="letter-spacing: 0.3rem;">COMPARA SEGUROS</p>
      </div>

    </div>

    <div class="col-md-7">

      <div class="row gy-2 gy-md-3">

        <div class="col-md-12" *ngFor="let comparador of comparadores">

          <div class="bg-light rounded-3 position-relative ">

            <div class="d-flex justify-content-between align-items-center gap-3 gap-md-5 p-3 p-md-4">

              <div class="d-flex gap-4 align-items-center">

                <div>
                  <img class="" [src]="comparador.image" alt="" width="120">
                </div>
    
                <div>
                  <p class="text-uppercase mb-1">{{comparador.descripcion}}</p>
                  <p class="h2 mb-0">{{comparador.nombre}}</p>
                </div>
  
              </div>
  
              <div>
                <i-tabler class="text-dark" name="arrow-right" style="width: 42px; height: 42px; stroke-width: 1.35;"></i-tabler>
              </div>
  
              

            </div>

            <a class="stretched-link" [routerLink]="comparador.url"></a>

          </div>

        </div>

      </div>

    </div>



  </div>

</div>