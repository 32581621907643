import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../../services/firebase.service';
import { DataService } from '../../services/data.service';
import { SwiperOptions } from 'swiper';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  segurosPersonas;
  segurosEmpresas;
  aseguradorasSalud;
  aseguradoras;
  banners;
  aseguradorasFilter = [];
  bannersFilter = [];

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    speed: 800,
    effect: 'slide',
    spaceBetween: 20,
    observer: true,
    autoplay: {
      delay: 4500,
      disableOnInteraction: false,
    },
  };

  caracteristicas = [
    'Asesoramiento integral: Antes, durante y después',
    'Las mejores opciones para cada seguro',
    'Asistencia 24/7 los 365 días del año',
    'Con nosotros contratar tu seguro es más simple',
  ];

  opciones = [
    {
      titulo: 'Salud, Vida Individual y SOAT',
      descripcion: 'Consulta o reporta un siniestro',
    },
    {
      titulo: 'Vehículos y Hogar',
      descripcion: 'Consulta o reporta un siniestro',
    },
    {
      titulo: 'Patrimonial, Accidentes Personales Colectivos, FOLA, EPS, SCTR y Vida Ley',
      descripcion: 'Consulta o reporta un siniestro',
    },
  ];

  // cotizadores = [
  //   {
  //     nombre: ''
  //   }
  // ]

  
  icons = [
    'heartbeat',
    'report-medical',
    'flask',
    'stethoscope'
  ];



  constructor(
    private fs: FirebaseService,
    private ds: DataService
  ) { }

  ngOnInit(): void {

    this.fs.getBannersHome()
      .subscribe(res => {
        this.banners = res;
      });

    this.fs.getSegurosPersonas()
      .subscribe(res => {
        this.segurosPersonas = res;
        // console.log(this.segurosPersonas);
      });

    this.fs.getSegurosEmpresas()
      .subscribe(res => {
        this.segurosEmpresas = res;
        // console.log(this.segurosEmpresas);
      });

    this.fs.getAseguradoras()
      .subscribe(res => {
        this.aseguradoras = res;
      });

   

    // this.fs.getAseguradoras()
    // .subscribe( res => {
    //   this.aseguradoras = res;
    //   this.aseguradorasFilter = this.aseguradoras.filter( data =>
    //     data.shortName === 'laPositiva' ||
    //     data.shortName === 'mapfre' ||
    //     data.shortName === 'pacifico' ||
    //     data.shortName === 'qualitas' ||
    //     data.shortName === 'rimac' ||
    //     data.shortName === 'sanitas'
    //   );
    //   console.log(this.aseguradoras);
    //   console.log(this.aseguradorasFilter);
    // });

    this.aseguradorasSalud = this.ds.aseguradorasSalud;

    // this.primasResult = of(this.primasSalud.filter((a, b) => a.total < b.total ? -1 : a.total > b.total ? 1 : 0));
    // this.duplicar();
  }


}
