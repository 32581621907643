import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-beneficios-lista',
  templateUrl: './beneficios-lista.component.html',
  styleUrls: ['./beneficios-lista.component.scss']
})
export class BeneficiosListaComponent implements OnInit {

  beneficios = [];

  constructor(
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    this.fs.getBeneficiosInterna().subscribe( res => {
      this.beneficios = res;
    });
  }

}
