<div class="">

  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <p class="lead lh-sm">¿Qué tipo de seguros quieres consultar?</p>


  <mat-accordion *ngFor="let item of opciones; let primero = first; let ultimo = last" [ngClass]="{ 'primerItemAcordion': primero, 'ultimoItemAcordion': ultimo }">
    <mat-expansion-panel>
      <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
        <mat-panel-description class="w-100">
          <div>
            <p class="lead mb-0">{{item.titulo}}</p>
            <p class="small text-muted mb-0">{{item.descripcion}}</p>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="d-flex gap-2 gap-md-3 py-2 py-md-3">
        <div>
          <img class="rounded-circle imgConsultaSiniestro bg-secondary" [src]="item.image">
        </div>
        <div class="w-100">
          <div class="mb-3">
            <p class="lead mb-1">{{item.nombre}}</p>
            <p class="text-muted text-uppercase mb-0">{{item.cargo}}</p>
          </div>
          <div class="d-flex flex-column flex-md-row gap-2">
            <div class="d-grid w-100">
              <a class="btn btn-sm btn-success" [href]=" 'https://api.whatsapp.com/send?phone=51' + item.numero + '&text=Hola, te escribo desde la web de La Previsora' " target="_blank">
                <i-tabler class="me-1" name="brand-whatsApp" style="width: 21px; height: 21px;"></i-tabler>
                {{item.numero}}
              </a>
            </div>
            <div class="d-grid w-100">
              <button class="btn btn-sm btn-outline-primary">
                <i-tabler class="me-1" name="phone" style="width: 21px; height: 21px;"></i-tabler>
                {{item.numero}}
              </button>
            </div>
        
         
          </div>
        </div>
      </div>

    </mat-expansion-panel>
  </mat-accordion>


</div>
