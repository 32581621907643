import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { FirebaseService } from '../../services/firebase.service';
import * as firebase from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-actualizacion-datos',
  templateUrl: './actualizacion-datos.component.html',
  styleUrls: ['./actualizacion-datos.component.scss']
})
export class ActualizacionDatosComponent implements OnInit {

  formDatos: UntypedFormGroup;
  @ViewChild('f') form;

  deshabilitado: boolean;
  tiposDocumentos = ['DNI', 'Carnet de extranjería'];

  constructor(
    private fb: UntypedFormBuilder,
    private fs: FirebaseService,
    public snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.formDatos = this.fb.group({
      nombres: ['', Validators.required],
      apellidoPaterno: ['', Validators.required],
      apellidoMaterno: ['', Validators.required],
      tipoDocumento: ['', Validators.required],
      numeroDocumento: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(12)]],
      // numeroDocumentoDni: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(8), Validators.maxLength(8)] ],
      // numeroDocumentoCe: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(12)] ],
      email: ['', [Validators.required, Validators.email]],
      celular: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(9), Validators.maxLength(9)]],
      acepta: [false, Validators.requiredTrue],
      fechaCreacion: [Timestamp.now()]
    });
  }

  
  onSubmit() {
    if (this.formDatos.valid) {
      // this.removeControls();
      this.guardarActualizacion();
      this.deshabilitado = true;
    } else {
      this.validateAllFormFields(this.formDatos);
      this.deshabilitado = false;
    }
  }

  removeControls() {
    if (this.formDatos.value.numeroDocumentoCe === '') {
      this.formDatos.removeControl('numeroDocumentoCe');
    }
    if (this.formDatos.value.numeroDocumentoDni === '') {
      this.formDatos.removeControl('numeroDocumentoDni');
    }
  }

  // guardarActualizacion() {
  //   this.fs.addActualizacionDatos(this.formDatos.value)
  //   .then(() => {
  //     console.log(this.formDatos.value);
  //     this.form.resetForm({
  //       fechaCreacion: Timestamp.now(),
  //       tipoDocumento: 'DNI'
  //     });
  //     this.snackBar.open('¡Gracias, tus datos han sido enviados!', 'CERRAR', {
  //       duration: 10000,
  //     });
  //     this.deshabilitado = false;
  //   }, error => console.error('error al enviar el formulario:', error));
  // }

  guardarActualizacion() {
    const actualizacion = this.afs.collection('actualizacionDatos').ref.doc();
    console.log(actualizacion.id);
    actualizacion.set(this.formDatos.value)
      .then(() => {
        console.log(this.formDatos.value);
        this.router.navigate([`/actualizacionDatos/${actualizacion.id}`]);
        // this.snackBar.open('¡Gracias, tus datos han sido enviados!', 'CERRAR', {
        //   duration: 10000,
        // });
        this.deshabilitado = false;
      }, error => console.error('error al enviar el formulario:', error));
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  errorNombres() {
    return this.formDatos.controls.nombres.hasError('required') ? 'Ingrese sus nombres' : '';
  }

  errorApellidoPaterno() {
    return this.formDatos.controls.apellidoPaterno.hasError('required') ? 'Ingrese su Apellido Paterno' : '';
  }

  errorApellidoMaterno() {
    return this.formDatos.controls.apellidoMaterno.hasError('required') ? 'Ingrese su Apellido Materno' : '';
  }

  errorTipoDocumento() {
    return this.formDatos.controls.tipoDocumento.hasError('required') ? 'Seleccione un tipo' : '';
  }

  errorNumeroDocumento() {
    return this.formDatos.controls.numeroDocumento.hasError('required') ? 'Ingrese el número de documento' :
      this.formDatos.controls.numeroDocumento.hasError('pattern') ? 'Solo se admiten números.' :
        this.formDatos.controls.numeroDocumento.hasError('maxlength') ? 'No debe exceder los 12 dígitos' : '';
  }


  errorNumeroDocumentoDni() {
    return this.formDatos.controls.numeroDocumentoDni.hasError('required') ? 'Ingrese el número de DNI' :
      this.formDatos.controls.numeroDocumentoDni.hasError('pattern') ? 'Solo se admiten números.' :
        this.formDatos.controls.numeroDocumentoDni.hasError('minlength') ? 'Debe contener 8 dígitos' :
          this.formDatos.controls.numeroDocumentoDni.hasError('maxlength') ? 'No debe exceder los 8 dígitos' : '';
  }

  errorNumeroDocumentoCe() {
    return this.formDatos.controls.numeroDocumentoCe.hasError('required') ? 'Ingrese el número de Carnet de extranjería' :
      this.formDatos.controls.numeroDocumentoCe.hasError('pattern') ? 'Solo se admiten números.' :
        this.formDatos.controls.numeroDocumentoCe.hasError('maxlength') ? 'No debe exceder los 12 dígitos' : '';
  }

  errorEmail() {
    return this.formDatos.controls.email.hasError('required') ? 'Ingrese un email' :
      this.formDatos.controls.email.hasError('email') ? 'No es un email válido' : '';
  }

  errorCelular() {
    return this.formDatos.controls.celular.hasError('required') ? 'Ingrese un número de celular' :
      this.formDatos.controls.celular.hasError('pattern') ? 'Solo se admiten números.' :
        this.formDatos.controls.celular.hasError('minlength') ? 'Debe contener 9 dígitos' :
          this.formDatos.controls.celular.hasError('maxlength') ? 'No debe exceder los 9 dígitos' : '';
  }
  errorAcepta() {
    return this.formDatos.controls.acepta.hasError('required') ? 'Es necesario aceptar este campo' : '';
  }

}
