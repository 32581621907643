import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio-admin',
  templateUrl: './inicio-admin.component.html',
  styleUrls: ['./inicio-admin.component.scss']
})
export class InicioAdminComponent implements OnInit {

  seccionesAdmin = [
    {
      nombre: 'Actualización de datos',
      url: '/admin/actualizacionDatos'
    },
    {
      nombre: 'Reportes BI',
      url: '/admin/reportes'
    },
    {
      nombre: 'Solicitudes SOAT',
      url: '/admin/solicitudesSoat'
    },
    {
      nombre: 'Cotizaciones salud',
      url: '/admin/solicitudesSalud'
    },
    {
      nombre: 'Banners',
      url: '/admin/banners'
    },
    {
      nombre: 'Beneficios',
      url: '/admin/beneficios'
    },
    {
      nombre: 'Aseguradoras',
      url: '/admin/aseguradoras'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
