<div class="container">

  <nav class="extraSmall my-2">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/aseguradoras">Aseguradoras</a></li>
      <li class="breadcrumb-item active">Crear</li>
    </ol>
  </nav>

  <p class="h2">Crear Aseguradora</p>

  <form [formGroup]="formAseguradora" (ngSubmit)="onSubmit()" novalidate class="col-md-4">

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Nombre</mat-label>
      <input matInput formControlName="nombre">
      <mat-error *ngIf="errorNombre()">{{errorNombre()}}</mat-error>
    </mat-form-field>

    <!-- <mat-form-field appearance="outline" class="w-100">
      <mat-label>Logo</mat-label>
      <ngx-mat-file-input #removableInput formControlName="logo" placeholder="Seleccionar imagen" accept=".jpg, .jpeg, .png" (change)="detectFiles($event)"></ngx-mat-file-input>
      <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
        <mat-icon>clear</mat-icon>
      </button>
      <mat-icon matSuffix *ngIf="removableInput.empty">image_search</mat-icon>
      <mat-error *ngIf="errorImagen()">{{errorImagen()}}</mat-error>
    </mat-form-field> -->


      <div class="d-grid mt-2" style="width: 200px;">
      <button type="submit" class="btn btn-primary">
        <span class="myColorSpinner d-flex justify-content-center" *ngIf="deshabilitado">
          <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
        </span>
        <div *ngIf="!deshabilitado" class="d-flex-inline">
          <span>Crear aseguradora</span>
        </div>
      </button>
    </div>



  </form>

</div>