import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormContactoComponent } from '../../form-contacto/form-contacto.component';

@Component({
  selector: 'app-modal-vehicular-motos',
  templateUrl: './modal-vehicular-motos.component.html',
  styleUrls: ['./modal-vehicular-motos.component.scss']
})
export class ModalVehicularMotosComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalVehicularMotosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private analytics: AngularFireAnalytics,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  clickWhatsApp() {
    this.analytics.logEvent('btnWhatsAppSeguroMoto');
  }

  clickLlamada() {
    this.analytics.logEvent('btnLlamadaSeguroMoto');
  }

  openModalContact() {
    this.analytics.logEvent('btnDejanosTusDatosSeguroMoto');
    this.dialog.open(FormContactoComponent, {
      panelClass: 'dialogDeleteSolicitud',
      data: {
        ...this.data.formVehicularMoto_.value,
        aseguradora: this.data.item_.aseguradora,
        prima: this.data.item_.prima
      }
      // data: {
      //   item_: this.item,
      //   formVehicularMoto_: this.formVehicularMoto
      // }
    });
  }

}
