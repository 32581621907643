<div class="container">

    <div class="d-flex justify-content-center align-items-center" style="height: 400px;" *ngIf="loader">
        <span class="myColorSpinnerSoatDetalle ">
            <mat-spinner class="text-center" [diameter]="36"></mat-spinner>
        </span>
    </div>

    <div class="py-3 py-md-4" *ngIf="solicitudSoat">

        <!-- <nav class="small">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
                <li class="breadcrumb-item"><a routerLink="/admin/solicitudesSoat">Solicitudes SOAT</a></li>
                <li class="breadcrumb-item active text-muted">{{idSolicitudSoat}}</li>
            </ol>
        </nav> -->

        <div class="d-flex justify-content-between align-items-center mb-4 mb-md-5">
            <div>
                <a routerLink="/admin/solicitudesSoat" class="btn btn-outline-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                    <span class="ml-2 d-none d-md-inline-block">Regresar</span>
                </a>
            </div>
            <div>
                <p class="small mb-0">Código Solicitud SOAT:</p>
                <p class="h6 mb-0">{{idSolicitudSoat}}</p>
            </div>
        </div>



        <p class="h6 mb-1">Data Aseguradora</p>
        <div class="border rounded p-3">
            <div *ngFor="let item of dataAseguradora; last as last">
                <p class="gorro">{{item.nombre}}</p>
                <p class="valor">{{item.valor}}</p>
                <mat-divider class="my-2" *ngIf="!last"></mat-divider>
            </div>
        </div>

        <p class="h6 mb-1 mt-3">Data Vehículo</p>
        <div class="border rounded p-3">
            <div *ngFor="let item of dataVehiculo; last as last">
                <p class="gorro">{{item.nombre}}</p>
                <p class="valor">{{item.valor}}</p>
                <mat-divider class="my-2" *ngIf="!last"></mat-divider>
            </div>
        </div>

        <!-- <div>
            <p class="h6 mb-1 mt-3">Data Usuario</p>
            <div class="border rounded p-3">
                <div *ngFor="let item of dataUsuario; last as last">
                    <p class="gorro">{{item.nombre}}</p>
                    <p class="valor">{{item.valor}}</p>
                    <mat-divider class="my-2" *ngIf="!last"></mat-divider>
                </div>
            </div>
        </div> -->

        <div *ngIf="solicitudSoat.dataUsuario.tipo === 'Persona natural' ">
            <p class="h6 mb-1 mt-3">Data Usuario</p>
            <div class="border rounded p-3">
                <div *ngFor="let item of dataUsuarioPersonaNatural; last as last">
                    <p class="gorro">{{item.nombre}}</p>
                    <p class="valor">{{item.valor}}</p>
                    <mat-divider class="my-2" *ngIf="!last"></mat-divider>
                </div>
            </div>
        </div>


        <div *ngIf="solicitudSoat.dataUsuario.tipo === 'Empresa' ">
            <p class="h6 mb-1 mt-3">Data Usuario</p>
            <div class="border rounded p-3">
                <div *ngFor="let item of dataUsuarioEmpresa; last as last">
                    <p class="gorro">{{item.nombre}}</p>
                    <p class="valor">{{item.valor}}</p>
                    <mat-divider class="my-2" *ngIf="!last"></mat-divider>
                </div>
            </div>
        </div>


    </div>



</div>