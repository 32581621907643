<div class="container py-3 py-md-4">



	<div class="d-flex justify-content-between align-items-center mb-2 mb-md-3">
		<p class="h3 mb-0">Banners</p>
		<div>
			<a class="btn btn-primary"  routerLink="crearBanner"><mat-icon>add</mat-icon> Crear banner</a>
		</div>
	</div>



	<div class="table-responsive">

		<table mat-table [dataSource]="bannersData" matSort class="example-table" [trackBy]="trackByPublicado">

			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Código</th>
				<td mat-cell *matCellDef="let banner"> {{ banner.id }} </td>
			</ng-container>

			<ng-container matColumnDef="fechaCreacion">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha creación</th>
				<td mat-cell *matCellDef="let banner"> {{ banner.fechaCreacion.toDate() | date: 'short' }} </td>
			</ng-container>

			<ng-container matColumnDef="autorNombre">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Autor Nombre</th>
				<td mat-cell *matCellDef="let banner"> {{ banner.autorNombre }} </td>
			</ng-container>

			<ng-container matColumnDef="departamento">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Ubicación</th>
				<td mat-cell *matCellDef="let banner"> {{ banner.departamento }} </td>
			</ng-container>

			<ng-container matColumnDef="categoria">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Categoria</th>
				<td mat-cell *matCellDef="let banner"> {{ banner.categoria}} </td>
			</ng-container>

			<ng-container matColumnDef="imagen">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Imagen</th>
				<td mat-cell *matCellDef="let banner"> <img class="imageBannerAdmin border my-2 rounded-sm"
						[src]="banner.image"></td>
			</ng-container>

			<ng-container matColumnDef="titulo">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Título</th>
				<td mat-cell *matCellDef="let banner"> {{ banner.titulo }} </td>
			</ng-container>

			<ng-container matColumnDef="descripcion">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Descripcion</th>
				<td mat-cell *matCellDef="let banner"> {{banner.descripcion | slice: 0:64}}...</td>
			</ng-container>

			<ng-container matColumnDef="publicado">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Publicado</th>
				<td mat-cell *matCellDef="let banner">
					<mat-slide-toggle color="primary" #toggle [ngModel]="banner.publicado"
						(ngModelChange)="actualizarPublicado(banner.id, $event)">
					</mat-slide-toggle>
				</td>
			</ng-container>

			<ng-container matColumnDef="destacado">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Destacado</th>
				<td mat-cell *matCellDef="let banner">
					<mat-slide-toggle color="primary" #toggle [ngModel]="banner.destacado"
						(ngModelChange)="actualizarDestacado(banner.id, $event)">
					</mat-slide-toggle>
				</td>
			</ng-container>

			<ng-container matColumnDef="detalles">
				<th mat-header-cell *matHeaderCellDef>Detalles</th>
				<td mat-cell *matCellDef="let banner">
					<button mat-icon-button (click)="openModalDetalle(banner)">
						<mat-icon class="material-icons-outlined text-muted">remove_red_eye</mat-icon>
					</button>
				</td>
			</ng-container>

			<ng-container matColumnDef="editar">
				<th mat-header-cell *matHeaderCellDef>Editar</th>
				<td mat-cell *matCellDef="let banner">
					<button mat-icon-button (click)="openModalEdit(banner)">
						<mat-icon class="material-icons-outlined text-muted">edit</mat-icon>
					</button>
				</td>
			</ng-container>

			<ng-container matColumnDef="eliminar">
				<th mat-header-cell *matHeaderCellDef>Eliminar</th>
				<td mat-cell *matCellDef="let banner">
					<button mat-icon-button (click)="openModalDelete(banner)">
						<mat-icon class="material-icons-outlined text-muted">delete</mat-icon>
					</button>
				</td>
			</ng-container>

			<ng-container matColumnDef="mas">
				<th mat-header-cell *matHeaderCellDef class=""></th>
				<td mat-cell *matCellDef="let item" class="text-end">
					<button mat-icon-button [matMenuTriggerFor]="menu">
						<mat-icon class="material-icons-outlined">more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">

						<button mat-menu-item (click)="openModalDelete(item)">
							<mat-icon class="material-icons-outlined text-danger">delete</mat-icon>
							<span class="text-danger">Eliminar banner</span>
						</button>

					</mat-menu>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>

	<mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
	</mat-paginator>





</div>