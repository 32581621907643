import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soat-campaing',
  templateUrl: './soat-campaing.component.html',
  styleUrls: ['./soat-campaing.component.scss']
})
export class SoatCampaingComponent implements OnInit {

  opcionesSoat = [
    {
      aseguradoraLogo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoVivir.png?alt=media&token=207b60b4-4983-44c7-b9ca-de7df8c49c63',
      precio: 44,
      urlButton: 'https://www.vivirseguros.pe/soat/?sale=0X2022A12M21D1603',
      beneficios: [
        'Cobertura a nivel nacional',
        'Atención de emergencias las 24 horas, los 365 días del año',
        'Chofer de Reemplazo (Copago S/20.00)',
        'Traslado de Grúa (Copago S/35.00)',
        'Servicio de Carwash (S/ 50.00)',
        'Asistencia Mascota (Emergencia Automovilística, hasta S/150)',
        'Asistencia del Hogar (Hasta S/150)',
        'Vida Plus descuentos y tarifas especiales'
      ]
    },
    {
      aseguradoraLogo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoPacifico.svg?alt=media&token=bb52ed5e-dd51-4adf-a775-1aa903e01901',
      precio: 55,
      urlButton: 'https://web.pacifico.com.pe/seguros/soat/compraonline/laprevisora'
    },
    // {
    //   aseguradoraLogo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoMapfre.svg?alt=media&token=e48e4fe1-10d1-4c02-885a-1609573eaea5',
    //   precio: 66,
    //   urlButton: 'https://soat.mapfre.com.pe/cotiza-intermediario/AF60AE64E9060876E0530AA0785452BD'
    // },
    // {
    //   aseguradoraLogo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoCrecer.png?alt=media&token=8ca977ba-ea97-4c6c-abbb-59e1c064dba7',
    //   precio: 59,
    //   urlButton: 'https://app.crecerseguros.pe/SoatCrecerOnline/Link?p=previsora&v=++kSN3K0nEg='
    // },
  ];

  constructor() { }

  ngOnInit(): void {
    
  }

}
