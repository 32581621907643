<div>

  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <p class="h4">Eliminar</p>
  <p class="">¿Seguro que desea eliminar? Esta acción no se puede deshacer.</p>

  <div class="d-grid">
    <button type="submit" class="btn btn-danger" (click)="eliminarBanner(data.id)">
      <span class="myColorSpinner d-flex justify-content-center" *ngIf="deshabilitado">
        <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
      </span>
      <div *ngIf="!deshabilitado">
        <span>Eliminar</span>
      </div>
    </button>
  </div>

</div>