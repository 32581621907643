<div class="container pt-2 pt-md-3 pb-4 pb-md-5">

  <nav aria-label="breadcrumb" class="extraSmall">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item active" aria-current="page">Promociones</li>
    </ol>
  </nav>

  <div class="grillaPromociones">
    <div *ngFor="let promocion of promociones | async">
      <div class="d-flex align-items-center bg-light p-4 p-md-5 rounded-3 position-relative">
        <div class="d-none d-md-block me-md-5">
          <img [src]="promocion.image" width="200px">
        </div>
        <div class="w-100">
          <p class="text-primary text-uppercase">{{promocion.gorro}}</p>
          <p class="h3">{{promocion.titulo}}</p>
          <p>{{promocion.subtitulo}}</p>
          <div class="text-end d-none d-md-block">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-forward-up" width="42" height="42" viewBox="0 0 24 24" stroke-width="1" stroke="#82EA79" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M15 13l4 -4l-4 -4m4 4h-11a4 4 0 0 0 0 8h1" />
            </svg>
          </div>
        </div>
        <a class="stretched-link" [routerLink]=" promocion.id "></a>
      </div> 
    </div>
  </div>


</div>