import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { FirebaseService } from '../../services/firebase.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/app';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-crear-seguro',
  templateUrl: './crear-seguro.component.html',
  styleUrls: ['./crear-seguro.component.scss']
})
export class CrearSeguroComponent implements OnInit {

  formSeguro: UntypedFormGroup;
  categorias = [ 'Personas', 'Empresas'];

  constructor(
    private fb: UntypedFormBuilder,
    private fs: FirebaseService,
    private router: Router,
    private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {
    this.formSeguro = this.fb.group({
      id: ['', [Validators.required]],
      categoria: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      // icon: [''],
      image: [''],
      subtitulo: ['', [Validators.required]],
      // descripcion: [''],
      fechaCreacion: [ Timestamp.now()],
      caracteristicas: new UntypedFormArray ([
        new UntypedFormControl ('', [Validators.required])
      ]),
    });
  }

  agregarCaracteristica() {
    // tslint:disable-next-line:no-string-literal
    (this.formSeguro.controls['caracteristicas'] as UntypedFormArray).push(
      // new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(9)])
      new UntypedFormControl('', [Validators.required])
    );
  }

  eliminarCaracteristica(index: number): void {
    // tslint:disable-next-line:no-string-literal
    (this.formSeguro.controls['caracteristicas']as UntypedFormArray).removeAt(index);
    }

  onSubmit() {
    if (this.formSeguro.valid) {
      this.crearSeguro();
    } else {
      this.validateAllFormFields(this.formSeguro);
    }
  }

  crearSeguro() {
    // this.fs.addSeguro( this.formSeguro.value)
    //   .then(() => {
    //     this.router.navigate(['/']);
    //     this.dialogRef.close();
    //   });

    const myIdSeguro = this.formSeguro.value.id;
    const mySeguro = this.afs.collection('seguros').ref.doc(myIdSeguro);
    console.log(mySeguro.id);
    mySeguro.set(this.formSeguro.value);
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
