import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, SwiperOptions } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  banners = [];

  config: SwiperOptions = {
    autoplay: { delay: 3000 },
    // pagination: { clickable: true },
    speed: 800,
    effect: 'slide',
    spaceBetween: 20,
    // observer: true,
  };

  constructor(
    private fs: FirebaseService
  ) { }

  ngOnInit(): void {
    this.fs.getBannersHome()
    .subscribe(res => {
      this.banners = res;
    });
  }

}
