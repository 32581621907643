import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FirebaseService } from '../../../../services/firebase.service';
import { EditarReporteBiComponent } from '../editar-reporte-bi/editar-reporte-bi.component';
import { EliminarReporteBiComponent } from '../eliminar-reporte-bi/eliminar-reporte-bi.component';

@Component({
  selector: 'app-lista-reportes-bi',
  templateUrl: './lista-reportes-bi.component.html',
  styleUrls: ['./lista-reportes-bi.component.scss']
})
export class ListaReportesBiComponent implements OnInit {

  reportes;
  count: number;
  loading: boolean;
  displayedColumns = [ 'titulo', 'descripcion', 'url', 'mas' ];
  dataReportes = new MatTableDataSource();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  value;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataReportes.filter = filterValue;
  }


  constructor(
    private fs: FirebaseService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.reportes = this.fs.getReportesBi();
    this.fs.getReportesBi().subscribe(data => {
      this.dataReportes.data = data;
      this.loading = false;
      this.count = data.length;
    });
    this.dataReportes.paginator = this.paginator;
    this.dataReportes.sort = this.sort;

    // excluir acentos
    this.dataReportes.filterPredicate = (data: any, filter: string): boolean => {
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        return (currentTerm + (data as { [key: string]: any })[key] + '◬');
      }, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      const transformedFilter = filter.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    }
  }

  clearFilters() {
    this.dataReportes.filter = '';
    this.value = '';
  }

  // openModalCrear() {
  //   const dialogRef = this.dialog.open(CrearProductoComponent, {
  //     panelClass: 'modalFullScreen',
  //   });
  //   dialogRef.afterClosed().subscribe();
  // }


  // eliminarAviso(idProduct) {
  //   this.fs.deleteProduct(idProduct);
  // }

  // openModalDetalle(producto) {
  //   const dialogRef = this.dialog.open(DetalleProductoAdminComponent, {
  //     panelClass: 'modalFullScreen',
  //     data: producto
  //   });
  //   dialogRef.afterClosed().subscribe();
  // }

  openReserva(reservaId) {
    this.router.navigate([`/admin/reservas/${reservaId}`]);
  }

  openModalDelete(producto) {
    const dialogRef = this.dialog.open(EliminarReporteBiComponent, {
      data: producto
    });
    dialogRef.afterClosed().subscribe();
  }

  openModalEdit(producto) {
    const dialogRef = this.dialog.open(EditarReporteBiComponent, {
      panelClass: 'modalFullScreen',
      data: producto
    });
    dialogRef.afterClosed().subscribe();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.dataReportes.paginator = this.paginator;
    // this.avisosData.sort = this.sort;
  }

  actualizarPublicado(key, obj, e) {
    this.fs.updateAprobado(key, e);
  }

  actualizarDestacado(key, obj, e) {
    this.fs.updateDestacado(key, e);
  }

  trackByPublicado(item) {
    return item.publicado;
  }

}
