<div class="bg-white" [ngClass]="getRoute()">
  <div class="container">

    <div class="d-flex justify-content-between align-items-center py-3 py-md-4">
      <a routerLink="/">
        <img class="logoPrevisora"
          src="https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoPrevisoraHor.svg?alt=media&token=cf0e4b7a-896b-4166-976c-775339c878c6">
      </a>

      <div class="d-none d-md-block w-100">
        <div class="d-flex justify-content-end align-items-center">

          <div class="d-md-none d-lg-block">

            <a class="btn btn-outline-primary btn-circle me-1"
              href="https://api.whatsapp.com/send?phone=51998161616&text=Hola,%20necesito%20que%20me%20asesoren%20con%20un%20seguro"
              target="blank">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-whatsapp" width="28"
                height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
                <path
                  d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" />
              </svg>
            </a>

            <a class="btn btn-outline-primary btn-circle me-1" href="tel:998161616">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" width="28" height="28"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <path
                  d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
              </svg>
            </a>

            <button class="btn btn-outline-secondary" (click)="openModalConsultas()">Consultas / siniestros</button>

            <a class="btn btn-outline-secondary mx-2" href="https://previnet.previsora.pe/admin/login" target="blank">
              <span>Mis seguros</span>
            </a>

            
            <a class="btn btn-primary shadow-sm me-2" routerLink="/cotizadores">
              <div class="d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calculator me-1" width="28"
                  height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round"
                  stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <rect x="4" y="3" width="16" height="18" rx="2" />
                  <rect x="8" y="7" width="8" height="3" rx="1" />
                  <line x1="8" y1="14" x2="8" y2="14.01" />
                  <line x1="12" y1="14" x2="12" y2="14.01" />
                  <line x1="16" y1="14" x2="16" y2="14.01" />
                  <line x1="8" y1="17" x2="8" y2="17.01" />
                  <line x1="12" y1="17" x2="12" y2="17.01" />
                  <line x1="16" y1="17" x2="16" y2="17.01" />
                </svg>
                <span>
                  COMPARA SEGUROS
                </span>
              </div>
            </a>

          </div>

        </div>
      </div>


      <!-- <button mat-icon-button (click)="sidenavMain.toggle()">
        <img src="assets/images/iconMenuMobile.svg">
      </button> -->

      <div>

        <button mat-icon-button data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight">
          <img src="assets/images/iconMenuMobile.svg">
        </button>

        <div class="myOffCanva">
          <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div class="offcanvas-header d-flex justify-content-end">
              <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                aria-label="Close">
              </button>
            </div>
            <div class="offcanvas-body">
              <app-menu></app-menu>
            </div>
          </div>
        </div>

      </div>


    </div>
  </div>
</div>