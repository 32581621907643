<div class="bannerOncologico">
    <div class="col-md-10 mx-auto d-flex align-items-start align-items-md-center h-100">
        <div class="bannerContenido pt-3 text-center text-md-left">
            <p class="titulo mb-0">Seguro Oncológico</p>
            <p class="descripcion px-3 px-md-0 pb-0 pb-md-4">Chequeo preventivo, quimioterapia, cirugía oncológica, radioterapia y terapia biológica al 100%</p>
            <a class="btn btn-secondary shadow" href="https://api.whatsapp.com/send?phone=51998161616&text=Hola,%20necesito%20que%20me%20asesoren%20con%20el%20seguro%20Oncológico" target="blank">
                <span class="mr-1">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"/>
                <circle cx="9" cy="7" r="4" />
                <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                <path d="M16 11l2 2l4 -4" />
              </svg>
            </span> Asesoría al instante
            </a>
            <!-- <a class="btn btn-link text-white" href="https://api.whatsapp.com/send?phone=51998161616&text=Hola,%20me%20pueden%20enviar%20el%20comparativo%20del%20seguro%20Oncológico?" target="blank">Solicitar comparativo</a> -->
        </div>
    </div>
</div>

<div class="col-md-10 mx-auto py-5 py-md-6">
    <div class="row d-flex align-items-center">
        <div class="col-md-5">
            <p class="h2 font-weight-light">¿Qué cubre tu <br><span class="text-primary">Seguro Oncológico?</span></p>
            <p>Coberturas antes, durante y después del tratamiento.
            </p>
            <a class="btn btn-primary shadow" href="https://api.whatsapp.com/send?phone=51998161616&text=Hola,%20necesito%20que%20me%20asesoren%20con%20el%20seguro%20Oncológico" target="blank">
                <span class="mr-1">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#41D4C1" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z"/>
              <circle cx="9" cy="7" r="4" />
              <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
              <path d="M16 11l2 2l4 -4" />
            </svg>
          </span> Asesoría al instante
            </a>
        </div>
        <div class="col-md-7">
            <div class="my-2 px-3 py-2 rounded shadow d-flex align-items-center" *ngFor="let caracteristica of caracteristicas">
                <span class="text-secondary h4 mb-0 mr-2 mr-md-3"><i class="far fa-check-circle"></i></span>
                <span>{{caracteristica}}</span>
            </div>
        </div>
    </div>
</div>


<div class="bg-secondary py-4 py-md-7">
    <div class="col-md-10 mx-auto text-center ">
        <p class="h2 mb-0">Mira el video</p>
        <p class="lead">Conoce todo sobre tu seguro Oncológico</p>
        <div class="col-md-8 embed-responsive embed-responsive-16by9 mx-auto">
            <iframe width="100%" height="auto" src="https://www.youtube.com/embed/rLYOW3fOplc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</div>

<div class="col-md-10 mx-auto py-4 py-md-6">
    <div class="text-center">
        <p class="h2 font-weight-light mb-3 mb-md-4">Opciones en Seguros Oncológicos</p>
    </div>

    <div class="grillaSegurosOncologicos">
        <div class="border rounded-lg p-3 p-md-4" *ngFor="let oncologico of segurosOncologicos">
            <p class="h4 text-primary mb-3">{{oncologico.nombreSeguro}}</p>
            <ul class="fa-ul mb-0border">
                <li class="" *ngFor="let caracteristica of oncologico.caracteristicas"><span class="fa-li text-secondary"><i class="far fa-check-circle"></i></span> {{caracteristica}}</li>
            </ul>
        </div>
    </div>
</div>

<div class="col-md-10 mx-auto py-4 py-md-5">
    <div class="text-center">
        <p class="h2 font-weight-light">Comparativo Oncológico</p>
        <div class="col-md-5 mx-auto">
            <p class="">Te enviamos un comparativo entre las distintas compañías de seguro para elijas el mejor.</p>
        </div>
        <a href="#" class="btn btn-primary" href="https://api.whatsapp.com/send?phone=51998161616&text=Hola,%20me%20pueden%20enviar%20el%20comparativo%20del%20seguro%20Oncológico?" target="blank">
            Solicitar comparativo
            <mat-icon class="material-icons-outlined ml-2">arrow_forward</mat-icon>
          </a>
    </div>
</div>