import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QuienesSomosComponent } from '../salud/quienes-somos/quienes-somos.component';

@Component({
  selector: 'app-asesoria',
  templateUrl: './asesoria.component.html',
  styleUrls: ['./asesoria.component.scss']
})
export class AsesoriaComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  openModalQuienesSomos() {
    this.dialog.open(QuienesSomosComponent, {
      // panelClass: 'modalContactoSeguroSalud',
      panelClass: 'dialogDeleteSolicitud'
    });
  }

}
