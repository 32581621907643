<div class="bg-light vh-100">


  <div class="container">

    <div class="row justify-content-center align-items-center">

      <div class="col-md-6">

        <div class="pt-4" *ngIf="card">

          <div class="text-center" style="margin-bottom: 7rem;">
            <img class="logoPrevisoraCard" src="https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoPrevisoraVert.svg?alt=media&token=d2214493-4521-406c-b224-e0e38ad7c8d8">
          </div>

          <div class="">

            <div class="text-center rounded p-4 p-md-5 bg-white shadow-sm h-100">

              <div style="margin-top: -7rem;">
                <img class="bg-secondary cardPhoto rounded-circle border border-secondary border-4 mb-3" [src]="card.foto">
              </div>

              <div class="mb-3 mb-md-4">
                <p class="h1 mb-0">{{card.nombre}} {{card.apellidos}}</p>
                <p class="h6 text-muted text-uppercase">{{card.cargo}}</p>
              </div>

              <div>
                <a class="btn btn-success py-2 px-3 mb-3" [href]="messageWhatsApp" target="_blank">
                  <div class="d-flex align-items-center">
                    <i-tabler name="brand-whatsApp"
                      style="width: 42px; height: 42px; stroke-width: 1.35; margin-right: 6px;">
                    </i-tabler>
                    <div class="text-start">
                      <span class="extraSmall">WHATSAPP</span>
                      <p class="h5 lh-1 mb-0" style="margin-top: -3px;">{{card.celular}}</p>
                    </div>
                  </div>
                </a>
              </div>

              <div>
                <a class="lead" [href]=" 'mailto:' + card.email ">{{card.email}}</a>
              </div>

              <hr class="my-3">

              <div class="px-3">
                <a class="h4" href="/">PREVISORA.PE</a>
                <p class="small lh-sm">Av. Mariscal La Mar 750 Of. 412, Miraflores - Lima</p>
              </div>
           
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>