<div class="col-md-6 mx-auto py-4 py-md-5">
    <form [formGroup]="formSeguro" (submit)="onSubmit()" novalidate>
        <mat-radio-group formControlName="categoria">
            <mat-radio-button class="mr-3" *ngFor="let categoria of categorias" [value]="categoria">{{categoria}}</mat-radio-button>
        </mat-radio-group>
        <mat-form-field class="w-100">
            <mat-label>Id</mat-label>
            <input matInput formControlName="id" placeholder="Sin espacios">
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Imagen Url</mat-label>
            <input matInput formControlName="image">
        </mat-form-field>
        <!-- <mat-form-field class="w-100">
            <mat-label>Ícono Url</mat-label>
            <input matInput formControlName="icon">
        </mat-form-field> -->
        <mat-form-field class="w-100">
            <mat-label>Nombre del seguro</mat-label>
            <input matInput formControlName="nombre">
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Subtítulo</mat-label>
            <input matInput formControlName="subtitulo" rows="3">
        </mat-form-field>
        <!-- <mat-form-field class="w-100">
            <mat-label>Descripción</mat-label>
            <textarea matInput formControlName="descripcion" rows="5"></textarea>
        </mat-form-field> -->
        <div class="w-100" formArrayName="caracteristicas" *ngFor="let caracteristica of formSeguro.controls['caracteristicas']['controls']; let i = index;">
            <mat-form-field class="w-100">
                <mat-label>Característica {{i+1}}</mat-label>
                <input matInput [formControlName]="i">
                <button *ngIf="i != 0" matSuffix mat-icon-button (click)="eliminarCaracteristica(i)"><mat-icon>close</mat-icon></button>
            </mat-form-field>
        </div>
        <div>
            <button class="btn btn-sm btn-outline-primary" type="button" (click)="agregarCaracteristica()">Agregar característica</button>
        </div>
        <div class="text-right">
            <button class="btn btn-primary">Crear Seguro</button>
        </div>
    </form>
</div>