import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-terminos-condiciones-modal',
  templateUrl: './terminos-condiciones-modal.component.html',
  styleUrls: ['./terminos-condiciones-modal.component.scss']
})
export class TerminosCondicionesModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TerminosCondicionesModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  cancelar() {
    this.dialogRef.close();
  }

}
