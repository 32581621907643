import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Card } from 'src/app/classes/card';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-card-detalle',
  templateUrl: './card-detalle.component.html',
  styleUrls: ['./card-detalle.component.scss']
})
export class CardDetalleComponent implements OnInit {

  idCard: string;
  cards: Card[] = [];
  card: Card;
  messageWhatsApp: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private ds: DataService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( params => {
      this.idCard = params.id;
      console.log(this.idCard);

      this.cards = this.ds.cards;
      this.card = this.cards.find( (find: Card) => find.id === this.idCard )
      console.log(this.card);
      this.messageWhatsApp = `https://api.whatsapp.com/send?phone=51${this.card.celular}&text=Hola ${this.card.nombre}, te escribo desde tu tarjeta virtual`
    });
  }

}
