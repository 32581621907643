import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


// Angularfire2
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
// import { provideAnalytics, getAnalytics, ScreenTrackingService } from '@angular/fire/analytics';
// import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideStorage, getStorage } from '@angular/fire/storage';

// Swiper Angular
import { SwiperModule } from 'swiper/angular';

// Tabler icons
import { TablerIconsModule } from 'angular-tabler-icons';
// import * as TablerIcons from 'angular-tabler-icons/icons';
import {
  IconBrandWhatsapp,
  IconX,
  IconCheck,
  IconPhone,
  IconCalculator,
  IconHeartbeat,
  IconReportMedical,
  IconFlask,
  IconStethoscope,
  IconExternalLink,
  IconCircleCheck,
  IconArrowRight
} from 'angular-tabler-icons/icons';

const icons = {
  IconBrandWhatsapp,
  IconX,
  IconCheck,
  IconPhone,
  IconCalculator,
  IconHeartbeat,
  IconReportMedical,
  IconFlask,
  IconStethoscope,
  IconExternalLink,
  IconCircleCheck,
  IconArrowRight
};

// Español Angular
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es-ES');


// Angular Material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicComponent } from './components/public/public.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { SeguroOncologicoComponent } from './components/seguro-oncologico/seguro-oncologico.component';
import { MenuMobileComponent } from './components/menu-mobile/menu-mobile.component';
import { CrearSeguroComponent } from './components/crear-seguro/crear-seguro.component';
import { AdminComponent } from './components/admin/admin.component';
import { DetalleSeguroComponent } from './components/detalle-seguro/detalle-seguro.component';
import { DatosComponent } from './components/datos/datos.component';
import { SegurosPersonasComponent } from './components/seguros-personas/seguros-personas.component';
import { AdminSegurosComponent } from './components/admin-seguros/admin-seguros.component';
import { CardSeguroComponent } from './components/card-seguro/card-seguro.component';
import { LibroReclamacionesComponent } from './components/libro-reclamaciones/libro-reclamaciones.component';
import { GraciasComponent } from './components/gracias/gracias.component';
import { LanzamientosComponent } from './components/lanzamientos/lanzamientos.component';
// import { CotizadorSaludComponent } from './components/cotizador-salud/cotizador-salud.component';

import { CrearSeguroListaComponent } from './components/crear-seguro-lista/crear-seguro-lista.component';
import { CrearPrimaComponent } from './components/crear-prima/crear-prima.component';
import { SegurosEmpresasComponent } from './components/seguros-empresas/seguros-empresas.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component';
import { PoliticaPrivacidadComponent } from './components/politica-privacidad/politica-privacidad.component';
import { DerechosArcoComponent } from './components/derechos-arco/derechos-arco.component';
import { ActualizacionDatosComponent } from './components/actualizacion-datos/actualizacion-datos.component';
// import { CotizadoresComponent } from './components/cotizadores/cotizadores.component';
// import { GraciasCotizadorComponent } from './components/cotizadores/gracias-cotizador/gracias-cotizador.component';
// import { CotizadorSalud2Component } from './components/cotizadores/salud/cotizador-salud2/cotizador-salud2.component';
// import { GraciasSaludComponent } from './components/cotizadores/salud/gracias-salud/gracias-salud.component';
// import { CotizadorSoatComponent } from './components/cotizadores/soat/cotizador-soat/cotizador-soat.component';
// import { FormDatosSoatComponent } from './components/cotizadores/soat/form-datos-soat/form-datos-soat.component';
// import { GraciasSoatComponent } from './components/cotizadores/soat/gracias-soat/gracias-soat.component';
// import { CotizadorSoatAutoComponent } from './components/cotizadores/soatAutos/cotizador-soat-auto/cotizador-soat-auto.component';
// import { CotizadorSoatMotoComponent } from './components/cotizadores/soatMotos/cotizador-soat-moto/cotizador-soat-moto.component';
// import { VehicularComponent } from './components/cotizadores/vehicular/vehicular.component';
// import { CotizadorVehicularMotoComponent } from './components/cotizadores/vehicularMotos/cotizador-vehicular-moto/cotizador-vehicular-moto.component';

// import { CardSoatMotoComponent } from './components/cotizadores/soatMotos/card-soat-moto/card-soat-moto.component';
// import { CardVehicularMotoComponent } from './components/cotizadores/vehicularMotos/card-vehicular-moto/card-vehicular-moto.component';
// import { CardResultadoComponent } from './components/cotizadores/salud/card-resultado/card-resultado.component';

import { PrivacidadComponent } from './components/privacidad/privacidad.component';
import { CotizadorSaludComponent } from './components/cotizadores/salud/cotizador-salud/cotizador-salud.component';
import { ResultadosSaludComponent } from './components/cotizadores/salud/resultados-salud/resultados-salud.component';
import { DetallePrimaComponent } from './components/cotizadores/salud/detalle-prima/detalle-prima.component';
import { GraciasActualizacionComponent } from './components/gracias-actualizacion/gracias-actualizacion.component';

import { CrearBannerComponent } from './components/admin/banners/crear-banner/crear-banner.component';
import { EditarBannerComponent } from './components/admin/banners/editar-banner/editar-banner.component';
import { EliminarBannerComponent } from './components/admin/banners/eliminar-banner/eliminar-banner.component';
import { DetalleBannerComponent } from './components/admin/banners/detalle-banner/detalle-banner.component';
import { ListaBannersComponent } from './components/admin/banners/lista-banners/lista-banners.component';
import { ActualizacionDatosAdminComponent } from './components/admin/actualizacion-datos/actualizacion-datos-admin/actualizacion-datos-admin.component';
import { CrearActualizacionDatosComponent } from './components/admin/actualizacion-datos/crear-actualizacion-datos/crear-actualizacion-datos.component';
import { EditarActualizacionDatosComponent } from './components/admin/actualizacion-datos/editar-actualizacion-datos/editar-actualizacion-datos.component';
import { EliminarActualizacionDatosComponent } from './components/admin/actualizacion-datos/eliminar-actualizacion-datos/eliminar-actualizacion-datos.component';
import { DetalleActualizacionDatosComponent } from './components/admin/actualizacion-datos/detalle-actualizacion-datos/detalle-actualizacion-datos.component';
import { LoginComponent } from './components/login/login.component';
import { InicioAdminComponent } from './components/admin/inicio-admin/inicio-admin.component';
import { HeaderAdminComponent } from './components/admin/header-admin/header-admin.component';
import { SoatComponent } from './components/campanas/soat/soat.component';
import { CrearReporteBiComponent } from './components/admin/reportesBi/crear-reporte-bi/crear-reporte-bi.component';
import { EliminarReporteBiComponent } from './components/admin/reportesBi/eliminar-reporte-bi/eliminar-reporte-bi.component';
import { EditarReporteBiComponent } from './components/admin/reportesBi/editar-reporte-bi/editar-reporte-bi.component';
import { ListaReportesBiComponent } from './components/admin/reportesBi/lista-reportes-bi/lista-reportes-bi.component';
import { DetalleReporteBiComponent } from './components/admin/reportesBi/detalle-reporte-bi/detalle-reporte-bi.component';
import { TerminosCondicionesComponent } from './components/terminos-condiciones/terminos-condiciones.component';



import { TerminosCondicionesModalComponent } from './components/terminos-condiciones-modal/terminos-condiciones-modal.component';
import { ListaSolicitudesComponent } from './components/cotizadores/soat/admin/lista-solicitudes/lista-solicitudes.component';
import { DetalleSolicitudComponent } from './components/cotizadores/soat/admin/detalle-solicitud/detalle-solicitud.component';
import { EliminarSolicitudComponent } from './components/cotizadores/soat/admin/eliminar-solicitud/eliminar-solicitud.component';

import { DetalleSeguroSaludComponent } from './components/cotizadores/salud/detalle-seguro-salud/detalle-seguro-salud.component';
import { FormContactoSaludComponent } from './components/cotizadores/salud/form-contacto-salud/form-contacto-salud.component';

import { ClinicasComponent } from './components/cotizadores/salud/clinicas/clinicas.component';
import { CardClinicaComponent } from './components/cotizadores/salud/card-clinica/card-clinica.component';
import { CartillaComponent } from './components/cotizadores/salud/cartilla/cartilla.component';
import { ListaSolicitudesSaludComponent } from './components/cotizadores/salud/admin/lista-solicitudes-salud/lista-solicitudes-salud.component';
import { EliminarSolicitudSaludComponent } from './components/cotizadores/salud/admin/eliminar-solicitud-salud/eliminar-solicitud-salud.component';
import { DetalleSolicitudSaludComponent } from './components/cotizadores/salud/admin/detalle-solicitud-salud/detalle-solicitud-salud.component';
import { MotivoComponent } from './components/cotizadores/salud/admin/motivo/motivo.component';
import { EstadoComponent } from './components/cotizadores/salud/admin/estado/estado.component';
import { OcultarSolicitudSaludComponent } from './components/cotizadores/salud/admin/ocultar-solicitud-salud/ocultar-solicitud-salud.component';
import { QuienesSomosComponent } from './components/cotizadores/salud/quienes-somos/quienes-somos.component';


import { AsesoriaComponent } from './components/cotizadores/asesoria/asesoria.component';
import { FormContactDirectComponent } from './components/cotizadores/form-contact-direct/form-contact-direct.component';


import { ModalVehicularMotosComponent } from './components/cotizadores/vehicularMotos/modal-vehicular-motos/modal-vehicular-motos.component';
import { CopagosVehicularMotosComponent } from './components/cotizadores/vehicularMotos/copagos-vehicular-motos/copagos-vehicular-motos.component';
import { FormContactoComponent } from './components/cotizadores/form-contacto/form-contacto.component';

import { CardSoatAutoComponent } from './components/cotizadores/soatAutos/card-soat-auto/card-soat-auto.component';
import { MenuComponent } from './components/menu/menu.component';
import { ComparadoresComponent } from './components/comparadores/comparadores.component';
import { DetallePromocionComponent } from './components/detalle-promocion/detalle-promocion.component';
import { PromocionesComponent } from './components/promociones/promociones.component';
import { CrearBeneficioComponent } from './components/admin/beneficios/crear-beneficio/crear-beneficio.component';
import { ListaBeneficiosComponent } from './components/admin/beneficios/lista-beneficios/lista-beneficios.component';
import { CrearAseguradoraComponent } from './components/admin/aseguradoras/crear-aseguradora/crear-aseguradora.component';
import { ListaAseguradorasComponent } from './components/admin/aseguradoras/lista-aseguradoras/lista-aseguradoras.component';
import { EliminarAseguradoraComponent } from './components/admin/aseguradoras/eliminar-aseguradora/eliminar-aseguradora.component';
import { EliminarBeneficioComponent } from './components/admin/beneficios/eliminar-beneficio/eliminar-beneficio.component';
import { BeneficiosComponent } from './components/beneficios/beneficios.component';
import { BeneficiosListaComponent } from './components/beneficios-lista/beneficios-lista.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ModalMeInteresaComponent } from './components/cotizadores/modal-me-interesa/modal-me-interesa.component';
import { AsignarComponent } from './components/cotizadores/salud/admin/asignar/asignar.component';
import { CrearCotizacionSaludComponent } from './components/cotizadores/salud/admin/crear-cotizacion-salud/crear-cotizacion-salud.component';

import { AngularFireModule, FIREBASE_APP_NAME, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { SoatCampaingComponent } from './components/soat-campaing/soat-campaing.component';
import { CardDetalleComponent } from './components/card-detalle/card-detalle.component';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { ExclusionesComponent } from './components/exclusiones/exclusiones.component';
import { ConsultasComponent } from './components/consultas/consultas.component';
import { ChequeoPreventivoComponent } from './components/chequeo-preventivo/chequeo-preventivo.component';
import { VentaDigitalComponent } from './components/venta-digital/venta-digital.component';


@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    HeaderComponent,
    FooterComponent,
    InicioComponent,
    SeguroOncologicoComponent,
    MenuMobileComponent,
    CrearSeguroComponent,
    AdminComponent,
    DetalleSeguroComponent,
    DatosComponent,
    SegurosPersonasComponent,
    AdminSegurosComponent,
    CardSeguroComponent,
    LibroReclamacionesComponent,
    GraciasComponent,
    LanzamientosComponent,
    CotizadorSaludComponent,
    CrearSeguroListaComponent,
    CrearPrimaComponent,
    SegurosEmpresasComponent,
    ContactoComponent,
    NosotrosComponent,
    PoliticaPrivacidadComponent,
    DerechosArcoComponent,
    ActualizacionDatosComponent,
    // CotizadoresComponent,
    // GraciasCotizadorComponent,
    // CotizadorSoatComponent,
    // FormDatosSoatComponent,
    // GraciasSoatComponent,
    // CotizadorSalud2Component,
    // GraciasSaludComponent,
    // CotizadorSoatMotoComponent,
    // CotizadorVehicularMotoComponent,
    // CotizadorSoatAutoComponent,
    // VehicularComponent,

    // CardSoatMotoComponent,
    // CardVehicularMotoComponent,
    // CardResultadoComponent,

    PrivacidadComponent,
    ResultadosSaludComponent,
    DetallePrimaComponent,
    GraciasActualizacionComponent,
   
    CrearBannerComponent,
    EditarBannerComponent,
    EliminarBannerComponent,
    DetalleBannerComponent,
    ListaBannersComponent,
    ActualizacionDatosAdminComponent,
    CrearActualizacionDatosComponent,
    EditarActualizacionDatosComponent,
    EliminarActualizacionDatosComponent,
    DetalleActualizacionDatosComponent,
    LoginComponent,
    InicioAdminComponent,
    HeaderAdminComponent,
    SoatComponent,
    CrearReporteBiComponent,
    EliminarReporteBiComponent,
    EditarReporteBiComponent,
    ListaReportesBiComponent,
    DetalleReporteBiComponent,
    TerminosCondicionesComponent,
    TerminosCondicionesModalComponent,
    ListaSolicitudesComponent,
    DetalleSolicitudComponent,
    EliminarSolicitudComponent,
    DetalleSeguroSaludComponent,
    FormContactoSaludComponent,
    ClinicasComponent,
    CardClinicaComponent,
    CartillaComponent,
    ListaSolicitudesSaludComponent,
    EliminarSolicitudSaludComponent,
    DetalleSolicitudSaludComponent,
    MotivoComponent,
    EstadoComponent,
    OcultarSolicitudSaludComponent,
    QuienesSomosComponent,
    
    AsesoriaComponent,
    FormContactDirectComponent,
   
    ModalVehicularMotosComponent,
    CopagosVehicularMotosComponent,
    FormContactoComponent,
    CardSoatAutoComponent,
    MenuComponent,
    ComparadoresComponent,
    DetallePromocionComponent,
    PromocionesComponent,
    CrearBeneficioComponent,
    ListaBeneficiosComponent,
    CrearAseguradoraComponent,
    ListaAseguradorasComponent,
    EliminarAseguradoraComponent,
    EliminarBeneficioComponent,
    BeneficiosComponent,
    BeneficiosListaComponent,
    CarouselComponent,
    ModalMeInteresaComponent,
    AsignarComponent,
    CrearCotizacionSaludComponent,
    SoatCampaingComponent,
    CardDetalleComponent,
    ExclusionesComponent,
    ConsultasComponent,
    ChequeoPreventivoComponent,
    VentaDigitalComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule,
    MatIconModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatCardModule,
    MatTabsModule,
    MatToolbarModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatExpansionModule,
    MatTooltipModule,
    MatBadgeModule,
    HttpClientModule,
    SwiperModule,
    TablerIconsModule.pick(icons),
  ],
  providers: [
    // ScreenTrackingService,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MatDialogRef, useValue: {} },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
