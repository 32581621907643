import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FirebaseService } from '../../../../services/firebase.service';

@Component({
  selector: 'app-crear-reporte-bi',
  templateUrl: './crear-reporte-bi.component.html',
  styleUrls: ['./crear-reporte-bi.component.scss']
})
export class CrearReporteBiComponent implements OnInit {

  formReporte: UntypedFormGroup;
  deshabilitado = false;

  constructor(
    private fb: UntypedFormBuilder,
    private fs: FirebaseService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.formReporte = this.fb.group({
      titulo: ['', Validators.required],
      descripcion: ['', Validators.required],
      url: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.formReporte.valid) {
      this.crearProducto();
      this.deshabilitado = true;
    } else {
      this.validateAllFormFields(this.formReporte);
      this.deshabilitado = false;
    }
  }

  crearProducto() {
    this.fs.addReporteBi(this.formReporte.value)
      .then(() => {
        this.router.navigate(['/admin/reportes']);
        // this.router.navigate([`/actualizacionDatos/${actualizacion.id}`]);
      });
  }


  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
