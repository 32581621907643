<div class="container">

    <nav class="my-2 my-md-3">
        <ol class="breadcrumb small">
            <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
            <li class="breadcrumb-item active">Actualización de datos</li>
        </ol>
    </nav>

    <p class="h3 mt-3 mt-md-5">Actualiza tus datos</p>

    <form class="col-md-10 px-0" #f="ngForm" [formGroup]="formDatos" (ngSubmit)="onSubmit()" novalidate>
        <div class="row">
            <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>Nombres</mat-label>
                <input matInput formControlName="nombres" placeholder="¿Tienes más de uno? Inclúyelos.">
                <mat-error *ngIf="errorNombres()">{{errorNombres()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12 col-md-3">
                <mat-label>Apellido Paterno</mat-label>
                <input matInput formControlName="apellidoPaterno">
                <mat-error *ngIf="errorApellidoPaterno()">{{errorApellidoPaterno()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12 col-md-3">
                <mat-label>Apellido Materno</mat-label>
                <input matInput formControlName="apellidoMaterno">
                <mat-error *ngIf="errorApellidoMaterno()">{{errorApellidoMaterno()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>Tipo documento</mat-label>
                <mat-select formControlName="tipoDocumento">
                    <mat-option *ngFor="let tipo of tiposDocumentos" [value]="tipo">{{tipo}}</mat-option>
                </mat-select>
                <mat-error *ngIf="errorTipoDocumento()">{{errorTipoDocumento()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>Número de documento</mat-label>
                <input matInput formControlName="numeroDocumento">
                <mat-error *ngIf="errorNumeroDocumento()">{{errorNumeroDocumento()}}</mat-error>
            </mat-form-field>
            <!-- <mat-form-field appearance="outline" class="col-12 col-md-6" *ngIf="formDatos.value.tipoDocumento === 'DNI'">
                <mat-label>Número de DNI</mat-label>
                <input matInput formControlName="numeroDocumentoDni" placeholder="00000000">
                <mat-error *ngIf="errorNumeroDocumentoDni()">{{errorNumeroDocumentoDni()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12 col-md-6" *ngIf="formDatos.value.tipoDocumento === 'Carnet de extranjería'">
                <mat-label>Número Carnet de Extranjería</mat-label>
                <input matInput formControlName="numeroDocumentoCe">
                <mat-error *ngIf="errorNumeroDocumentoCe()">{{errorNumeroDocumentoCe()}}</mat-error>
            </mat-form-field> -->
            <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
                <mat-error *ngIf="errorEmail()">{{errorEmail()}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12 col-md-6">
                <mat-label>Celular</mat-label>
                <input matInput formControlName="celular" placeholder="000000000">
                <mat-error *ngIf="errorCelular()">{{errorCelular()}}</mat-error>
            </mat-form-field>
            <div class="col-md-12">
                <mat-checkbox class="text-muted" formControlName="acepta">Declaro haber entregado mis datos reales y haber tenido los medios para actualizarlos a mi disposición. Asimismo, autorizo a La Previsora Corredores de Seguros S.A.C. modificar y/o sustituir el correo electrónico/teléfono que tiene registrado
                    en su base de datos por el consignado en este proceso.
                    <mat-error class="small" *ngIf="errorAcepta()">{{errorAcepta()}}</mat-error>
                </mat-checkbox>

            </div>
            <div class="col-12 mt-3">
                <button type="submit" class="btn btn-primary">
                  <svg *ngIf="!deshabilitado" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z"/>
                    <path d="M7 12l5 5l10 -10" />
                    <path d="M2 12l5 5m5 -5l5 -5" />
                  </svg>
                  <mat-spinner *ngIf="deshabilitado" [diameter]="24" class="float-left mr-2"></mat-spinner>
                  Actualizar datos</button>
            </div>
        </div>
    </form>

</div>