import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { FirebaseService } from '../../services/firebase.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-crear-prima',
  templateUrl: './crear-prima.component.html',
  styleUrls: ['./crear-prima.component.scss']
})
export class CrearPrimaComponent implements OnInit {

  formPrima: UntypedFormGroup;

  constructor(
    private fs: FirebaseService,
    private fb: UntypedFormBuilder,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.formPrima = this.fb.group({
      edad: ['', Validators.required],
      medicvidaInternacional: ['', Validators.required],
      saludPreferencial: ['', Validators.required],
      internacionalPrevisora: ['', Validators.required],
      medicvidaNacional: ['', Validators.required],
      fullsalud: ['', Validators.required],
      multisalud: ['', Validators.required],
      redMedica: ['', Validators.required],
      trebolSalud: ['', Validators.required],
      redPreferentePacifico: ['', Validators.required],
      redPreferenteRimac: ['', Validators.required],
      vivaSalud: ['', Validators.required],
      vivaSaludPlus: ['', Validators.required],
    });
  }


  onSubmit() {
    if (this.formPrima.valid) {
      this.crearPrima();
    } else {
      this.validateAllFormFields(this.formPrima);
    }
  }


  crearPrima() {
    const myIdPrima = this.formPrima.value.edad.toString();
    const myPrima = this.afs.collection('primasSalud').ref.doc(myIdPrima);
    console.log(myPrima.id);
    myPrima.set(this.formPrima.value);
  }


  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
