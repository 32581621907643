import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  linksMenu = [
    { nombre: 'Inicio', url: '/' },
    { nombre: 'Seguros Personas', url: '/segurosPersonas' },
    { nombre: 'Seguros Empresas', url: '/segurosEmpresas' },
    { nombre: 'Nosotros', url: '/nosotros' },
    { nombre: 'Contacto', url: '/contacto' }
  ];

  documentos = [
    'DNI',
    'RUC',
    'C.E.'
  ]

  aseguradorasSalud = [
    'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoPacifico.svg?alt=media&token=bb52ed5e-dd51-4adf-a775-1aa903e01901',
    'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoRimac.svg?alt=media&token=c51c72ea-e0b5-4ba3-93c6-7b7e69b8b969',
    'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoMapfre.svg?alt=media&token=e48e4fe1-10d1-4c02-885a-1609573eaea5'
  ];

  aseguradoras = [
    {
      nombre: 'Rimac',
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoRimac.svg?alt=media&token=c51c72ea-e0b5-4ba3-93c6-7b7e69b8b969'
    },
    {
      nombre: 'Pacifico',
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoPacifico.svg?alt=media&token=bb52ed5e-dd51-4adf-a775-1aa903e01901'
    },
    {
      nombre: 'Mapfre',
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoMapfre.svg?alt=media&token=e48e4fe1-10d1-4c02-885a-1609573eaea5'
    },
    {
      nombre: 'La Positiva',
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoLaPositiva.svg?alt=media&token=79c5dbd9-4b6f-4893-bdae-a3e7ab802a05'
    },
    {
      nombre: 'Qualitas',
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoQualitas.svg?alt=media&token=83b4361f-c22d-47a4-8f47-c718a65a7c7a'
    },
    {
      nombre: 'Crecer',
      logo: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoCrecer.png?alt=media&token=8ca977ba-ea97-4c6c-abbb-59e1c064dba7'
    },
  ];

  aseguradorasColor = [
    'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoRimac.svg?alt=media&token=c51c72ea-e0b5-4ba3-93c6-7b7e69b8b969',
    'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoPacifico.svg?alt=media&token=bb52ed5e-dd51-4adf-a775-1aa903e01901',
    'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoMapfre.svg?alt=media&token=e48e4fe1-10d1-4c02-885a-1609573eaea5',
    'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradoras%2FlogoLaPositiva.svg?alt=media&token=79c5dbd9-4b6f-4893-bdae-a3e7ab802a05'
  ]


  aseguradorasWhite = [
    'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradorasWhite%2FlogoRimac.png?alt=media&token=b60e1102-c663-4e97-bec6-d6de5b381ced',
    'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradorasWhite%2FlogoPacifico.png?alt=media&token=f4eac03b-4f7c-4349-97d3-9d175b683ab0',
    'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradorasWhite%2FlogoMapfre.png?alt=media&token=5cdc86c2-398d-4b6e-be24-ef46c3bceefd',
    'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoAseguradorasWhite%2FlogoLaPositiva.png?alt=media&token=631989d3-92ab-4601-b4b9-57ed6b9d7030'
  ];

  // cards: import("d:/apps/previsoraAppNew/src/app/classes/card").Card[];

  cards = [
    {
      id: 'jaimeLeon',
      foto: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2Fjaime0.png?alt=media&token=34dd1855-5c9f-440f-a3cb-c3899ded8ced',
      nombre: 'Jaime',
      apellidos: 'León',
      cargo: 'Gerente General',
      celular: '998161616',
      email: 'jaime@previsora.pe', 
    },
    {
      id: 'melinaLopez',
      foto: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2Fmelina.jpg?alt=media&token=cd5e6d10-abe8-4067-a839-bb1b0b41298b',
      nombre: 'Melina',
      apellidos: 'López',
      cargo: 'Coordinadora',
      celular: '949668440',
      email: 'melina@previsora.pe', 
    },
    {
      id: 'fernandoConroy',
      foto: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2FfernandoConroy.png?alt=media&token=8bf24008-a526-45ba-97d3-d241d263f057',
      nombre: 'Fernando',
      apellidos: 'Conroy',
      cargo: 'Corredor Exclusivo',
      celular: '985033086',
      email: 'fernando@previsora.pe', 
    },
    {
      id: 'nydiaManchego',
      foto: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2Fnydia.jpg?alt=media&token=920e10f3-fad8-4e92-9ee1-5222374729b1',
      nombre: 'Nydia',
      apellidos: 'Manchego',
      cargo: 'Salud y Vida',
      celular: '998484891',
      email: 'nydia@previsora.pe', 
    },
    {
      id: 'normaTicona',
      foto: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2Fnorma.jpg?alt=media&token=788760d5-9fcc-449b-9f53-bb7ddac0da1c',
      nombre: 'Norma',
      apellidos: 'Ticona',
      cargo: 'Seguros Patrimoniales',
      celular: '914083971',
      email: 'norma@previsora.pe', 
    },
    {
      id: 'alexisMunoz',
      foto: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2Falexis.png?alt=media&token=4ad98cb3-6c78-473b-8805-35c9a7ab2ccd',
      nombre: 'Alexis',
      apellidos: 'Muñoz',
      cargo: 'Seguros Laborales',
      celular: '945417567',
      email: 'riesgoslaborales@previsora.pe', 
    },
    {
      id: 'iyumiKokubun',
      foto: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2Fiyumi.png?alt=media&token=86984e52-8106-44a6-a892-6100a21e3944',
      nombre: 'Iyumi',
      apellidos: 'Kokubun',
      cargo: 'Seguros Laborales',
      celular: '945357560',
      email: 'riesgoslaborales@previsora.pe', 
    },
    {
      id: 'astridUrbano',
      foto: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2Fastrid.png?alt=media&token=9d0ed790-e33a-44c0-ad6a-e7d1cd51655c',
      nombre: 'Astrid',
      apellidos: 'Urbano',
      cargo: 'Ejecutiva Riesgos Patrimoniales',
      celular: '914083971',
      email: 'aurbano@previsora.pe', 
    },
    {
      id: 'fiorellaHuaringa',
      foto: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2FfiorellaHuaringa.png?alt=media&token=5902a3ee-df27-4da0-9f50-2da66698158c',
      nombre: 'Fiorella',
      apellidos: 'Huaringa',
      cargo: 'Vehículos y Hogar',
      celular: '975079393',
      email: 'fiorella@previsora.pe', 
    },
    {
      id: 'claudiaBenites',
      foto: 'https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/colaboradores%2FclaudiaBenites.png?alt=media&token=b256fb34-1332-4c92-bbfa-7d54d180a98d',
      nombre: 'Claudia',
      apellidos: 'Benites Castillo',
      cargo: 'Seguros Laborales',
      celular: '945357560',
      email: 'riesgoslaborales@previsora.pe', 
    },
  ]




  constructor(
    private http: HttpClient
  ) { }

  getMarcasSoat() {
    return this.http.get('assets/json/marcasSoat.json');
  }

  getModelosSoat() {
    return this.http.get('assets/json/modelosSoat.json');
  }

  getDepartamentos() {
    return this.http.get('assets/json/ubigeo_peru_2016_departamentos.json');
  }

  getProvincias() {
    return this.http.get('assets/json/ubigeo_peru_2016_provincias.json');
  }

  getDistritos() {
    return this.http.get('assets/json/ubigeo_peru_2016_distritos.json');
  }

  getCoberturasSalud() {
    return this.http.get('assets/json/coberturasSalud.json');
  }

  getExclusionesSalud() {
    return this.http.get('assets/json/exclusionesSalud.json');
  }

  getClinicasRedPreferenteRim() { return this.http.get('assets/json/clinicasMedisalud.json'); }
  getClinicasVivaSalud() { return this.http.get('assets/json/clinicasVivaSalud.json'); }
  getClinicasRedPreferentePac() { return this.http.get('assets/json/clinicasRedPreferentePac.json'); }
  getClinicasMedisalud() { return this.http.get('assets/json/clinicasMedisalud.json'); }

  getClinicasRedMedica() { return this.http.get('assets/json/clinicasRedMedica.json'); }
  getClinicasVivaSaludPlus() { return this.http.get('assets/json/clinicasVivaSaludPlus.json'); }
  getClinicasMultisalud() { return this.http.get('assets/json/clinicasMultisalud.json'); }
  getClinicasMedisaludPlus() { return this.http.get('assets/json/clinicasMedisaludPlus.json'); }

  getClinicasFullSalud() { return this.http.get('assets/json/clinicasFullSalud.json'); }
  getClinicasTrebolSalud() { return this.http.get('assets/json/clinicasTrebolSalud.json'); }
  getClinicasMedicvidaNacional() { return this.http.get('assets/json/clinicasMedicvidaNacional.json'); }
  getClinicasMedisaludPremium() { return this.http.get('assets/json/clinicasMedisaludPremium.json'); }

  getClinicasSaludPreferencial() { return this.http.get('assets/json/clinicasSaludPreferencial.json'); }
  getClinicasMedicvidaInternacional() { return this.http.get('assets/json/clinicasMedicvidaInternacional.json'); }
  getClinicasMedisaludGlobal() { return this.http.get('assets/json/clinicasMedisaludGlobal.json'); }

}
