<div class="col-md-10 mx-auto">

    <nav class="my-2 my-md-3">
        <ol class="breadcrumb small">
            <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
            <li class="breadcrumb-item"><a routerLink="/cotizadores">Cotizadores</a></li>
            <li class="breadcrumb-item active">Salud</li>
        </ol>
    </nav>


    <div class="col-md-4 mx-auto">
        <!-- <mat-horizontal-stepper [linear]="isLinear" labelPosition="bottom" #stepper>
            <mat-step [stepControl]="formSalud"> -->
        <form [formGroup]="formSalud" (ngSubmit)="onSubmit()" novalidate class="pt-3 pt-md-4">
            <ng-template matStepLabel>Datos</ng-template>
            <div class="row">

                <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Edad del titular</mat-label>
                    <mat-select formControlName="edadTitular">
                        <mat-option *ngFor="let edad of edades | async" [value]="edad.edad">{{edad.edad}} años</mat-option>
                    </mat-select>
                    <mat-error *ngIf="errorEdadTitular()">{{errorEdadTitular()}}</mat-error>
                </mat-form-field>

                <div formArrayName="familiares" class="col-12">
                    <div *ngFor="let familiar of formSalud.controls['familiares']['controls']; let i = index;">
                        <div class="d-flex" [formGroupName]="i">
                            <div class="row w-100">
                                <!-- <p class="col-12 mb-0 small text-muted">Familiar {{i+1}}</p> -->
                                <!-- <mat-form-field appearance="standard" class="col-6">
                                    <mat-label>Vínculo</mat-label>
                                    <input matInput formControlName="vinculo">
                                </mat-form-field> -->
                                <mat-form-field appearance="outline" class="col-6">
                                    <mat-label>Vínculo</mat-label>
                                    <mat-select formControlName="vinculo">
                                        <mat-option *ngFor="let vinculo of vinculos" [value]="vinculo">{{vinculo}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="col-6">
                                    <mat-label>Edad</mat-label>
                                    <mat-select formControlName="edad">
                                        <mat-option *ngFor="let edad of edades | async" [value]="edad.edad">{{edad.edad}} años</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- <mat-form-field appearance="standard" class="col-4 col-md-4">
                                    <mat-label>Precio S/.</mat-label>
                                    <input matInput type="number" formControlName="precio">
                                </mat-form-field> -->
                            </div>
                            <div class="flex-shrink-1 ml-2" style="padding-top: 18px;">
                                <button mat-icon-button (click)="eliminarFamiliar(i)">
                                  <mat-icon color="primary" class="material-icons-outlined">close</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <button class="mb-3 btn btn-light btn-sm text-primary px-2" type="button" (click)="agregarFamiliar()">
                          <!-- <mat-icon class="align-middle">add</mat-icon> -->
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-plus" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7960E0" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z"/>
                            <circle cx="9" cy="7" r="4" />
                            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            <path d="M16 11h6m-3 -3v6" />
                          </svg>
                          <span class="ml-1">
                            Agregar familiar
                          </span>
                          </button>
                    </div>
                </div>

                <div class="col-12">
                    <p>Tipo de Seguro</p>
                    <div class="d-flex flex-column" formGroupName="categorias">
                        <span *ngFor="let checkbox of checkboxes" class="d-flex align-items-center">
                            <span class="">
                                <mat-checkbox class="" [formControlName]="checkbox.id">{{checkbox.nombre}}</mat-checkbox>
                            </span>
                        <span class="">
                                <button type="button" mat-icon-button [matTooltip]="checkbox.descripcion" matTooltipClass="myTooltip" matTooltipPosition="above"><mat-icon style="font-size: 21px">help_outline</mat-icon></button>
                            </span>
                        </span>





                        <!-- <mat-checkbox formControlName="nacional">Nacional</mat-checkbox>
                        <mat-checkbox formControlName="nacionalSinReembolso">Nacional sin reembolso</mat-checkbox>
                        <mat-checkbox formControlName="nacionalClinicasAcotadas">Nacional con clínicas acotadas</mat-checkbox> -->
                        <div class="invalid-feedback" *ngIf="formSalud.controls['categorias'].errors && formSalud.controls['categorias'].errors.requireOneCheckboxToBeChecked">Seleccionar al menos una casilla</div>
                        <!-- <mat-error *ngIf="formSalud.controls['categorias'].errors && formSalud.controls['categorias'].errors.requireOneCheckboxToBeChecked">sdgfsdg</mat-error> -->
                        <!-- <mat-error *ngIf="errorCheckbox()">{{errorCheckbox()}}</mat-error> -->
                    </div>
                </div>



                <div class="col-12 text-right mt-3 mt-md-4">
                    <button class="btn btn-primary btn-block px-3">
                    <!-- <mat-icon class="align-middle" *ngIf="!deshabilitado">add</mat-icon> -->
                    <svg *ngIf="!deshabilitado" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calculator mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z"/>
                      <rect x="4" y="3" width="16" height="18" rx="2" />
                      <rect x="8" y="7" width="8" height="3" rx="1" />
                      <line x1="8" y1="14" x2="8" y2="14.01" />
                      <line x1="12" y1="14" x2="12" y2="14.01" />
                      <line x1="16" y1="14" x2="16" y2="14.01" />
                      <line x1="8" y1="17" x2="8" y2="17.01" />
                      <line x1="12" y1="17" x2="12" y2="17.01" />
                      <line x1="16" y1="17" x2="16" y2="17.01" />
                    </svg>
                    <mat-spinner *ngIf="deshabilitado" [diameter]="24" class="float-left mr-2"></mat-spinner>
                    Ver precios
                  </button>
                </div>

            </div>
        </form>


        <!-- </mat-step>

        </mat-horizontal-stepper> -->
    </div>
</div>