import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalle-banner',
  templateUrl: './detalle-banner.component.html',
  styleUrls: ['./detalle-banner.component.scss']
})
export class DetalleBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
