<div class="bg-primary mt-5 mt-md-7">
  <div class="container pt-4 pt-md-5 pb-4 pb-md-6 text-white">
    <div class="text-center">
      <!-- <img class="mr-2 mr-md-3" src="assets/images/logoPrevisoraWhite.svg" width="160px"> -->
      <p class="h4 mb-1">La Previsora Corredores de Seguros S.A.C.</p>
      <p class="mb-0 small">Inscrita en la ficha Nro. 134211 del Registro Mercantil de Lima. Registro SBS J593</p>
      <p class="small">RUC: 20335001657</p>
      <hr class="my-3 my-md-4">
    </div>
    <div class="d-flex flex-column flex-md-row justify-content-md-between">
      <div class="d-flex flex-column mb-4 mb-md-0">
        <p class="lead">Legales</p>
        <a [ngClass]="  first ? 'text-success' : 'text-reset'  " class="py-1" [routerLink]="link.url" *ngFor="let link of linksFooter; first as first">{{link.nombre}}</a>
      </div>
      <div class="d-flex flex-column flex-md-row">
        <!-- <img class="mr-2 mr-md-3" src="assets/images/logoPrevisoraWhite.svg" width="160px"> -->
        <div>
          <p class="lead">Institucional</p>
          <!-- <p class="lead">La Previsora Corredores de Seguros S.A.C.</p> -->
          <p class="mb-0">Av. Mariscal La Mar 750 ofic. 412, Miraflores.</p>
          <span class="me-3"><span class="fw-bold">T. </span><a class="text-reset" href="tel:014402828">(01)440-2828</a></span>
          <span><span class="fw-bold">C. </span> <a class="text-reset" href="tel:51998161616">998161616</a></span>
          <p>
            <a class="text-reset" href="mailto:jleon@previsora.pe">jleon@previsora.pe</a>
          </p>
        </div>
      </div>
      <div>
        <img src="https://firebasestorage.googleapis.com/v0/b/previsora-64387.appspot.com/o/logoPrevisoraWhite.svg?alt=media&token=b8bbd53b-05b0-4a53-a0f5-1ea761361588" width="160px">
      </div>


    </div>
  </div>
</div>