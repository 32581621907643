<div class="container">

  <nav class="extraSmall my-2 mb-3 mb-md-5">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
      <li class="breadcrumb-item active">Beneficios</li>
    </ol>
  </nav>

  <div class="grillaBeneficiosHome">
    <div class="" *ngFor="let beneficio of beneficios">
      <div class="rounded-3 h-100">
        <div class="bg-light rounded-3 p-3 p-md-4 mb-3">
          <p class="h5 mb-0">{{beneficio.titulo}}</p>
        </div>
      <div class="itemBeneficio d-flex" *ngFor="let descripcion of beneficio.descripciones">
        <div class="me-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="20"
            height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#82EA79" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="9" />
            <path d="M9 12l2 2l4 -4" />
          </svg>
        </div>
        <p class="descripcion">{{descripcion}}</p>
      </div>
    </div>
    </div>
  </div>

</div>