<div class="p-1 p-md-3">
  <div class="buttonClose">
    <button mat-icon-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <p class="h4 mt-2 mt-md-0">Motivo</p>
  <div class="mt-2 mt-md-3">
    <mat-form-field appearance="outline" class="w-100">
      <!-- <mat-label>Escriba el motivo</mat-label> -->
      <textarea matInput cdkTextareaAutosize  cdkAutosizeMinRows="2" [formControl]="motivo" placeholder="Escriba el motivo"></textarea>
    </mat-form-field>
    <!-- <p class="">¿Seguro que desea eliminar la solicitud? Esta acción no se puede deshacer.</p> -->
  </div>
  <div class="d-grid">
    <button type="submit" class="btn btn-primary" (click)="actualizarMotivo(data.idSolicitudSalud)">
      <span class="myColorSpinnerWhite d-flex justify-content-center" *ngIf="deshabilitado">
        <mat-spinner class="text-center" [diameter]="24"></mat-spinner>
      </span>
      <div *ngIf="!deshabilitado">
        <span>Actualizar motivo</span>
      </div>
    </button>
  </div>
</div>