import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-exclusiones',
  templateUrl: './exclusiones.component.html',
  styleUrls: ['./exclusiones.component.scss']
})
export class ExclusionesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ExclusionesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: [],
  ) { }

  ngOnInit(): void {
  }

  cancelar(): void {
    this.dialogRef.close();
  }

}
