import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { Beneficio } from 'src/app/classes/beneficio';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.scss']
})
export class BeneficiosComponent implements OnInit {

  beneficios = [];
  aseguradoras;

  constructor(
    private fs: FirebaseService,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {

    this.fs.getBeneficiosHome()
    .subscribe(res => {
      this.beneficios = res;
    });

  }

}
