<div class="container">

  <nav class="small my-2">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/admin">Admin</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/banners">Banners</a></li>
    </ol>
  </nav>

  <div class="my-3 my-md-4">
    <p class="h2">Crear Banner</p>
  </div>

  <form [formGroup]="formBanner" (submit)="onSubmit()" novalidate class="mb-5 mb-md-6 col-md-6">
    <div class="row">

      
      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Breadcrumb</mat-label>
        <input matInput formControlName="breadcrumb">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Gorro</mat-label>
        <input matInput formControlName="gorro">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Título</mat-label>
        <input matInput formControlName="titulo" placeholder="Nombre del producto">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Subtítulo</mat-label>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" formControlName="subtitulo" placeholder="Describe el producto"></textarea>
      </mat-form-field>

      <div formArrayName="descripciones" class="col-12">
        <div class="d-flex" *ngFor="let descripcion of formBanner.controls['descripciones']['controls']; let i = index;">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Descripción {{i+1}}</mat-label>
            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10"
              [formControlName]="i"></textarea>
          </mat-form-field>
          <div class="pt-2">
            <button class="ms-2" mat-button matSuffix mat-icon-button (click)="eliminarDescripcion(i)">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="col-12 mb-3 mb-md-5">
        <button type="button" class="btn btn-light btn-sm text-primary" (click)="agregarDescripcion()">Agregar
          Descripción</button>
      </div>

      <div class="col-12 mb-3">
        <input type="file" formControlName="image" accept=".jpg, .jpeg, .png" (change)="detectFiles($event)">
      </div>

      <!-- <div class="col 12">
        <div class="row gx-2 gx-md-4">

          <mat-form-field appearance="outline" class="col-6">
            <mat-label>Texto botón</mat-label>
            <input matInput formControlName="textoBoton">
          </mat-form-field>
    
          <mat-form-field appearance="outline" class="col-6">
            <mat-label>Url botón</mat-label>
            <input matInput formControlName="urlBoton">
          </mat-form-field>

        </div> 
      </div> -->

      

      <!-- <div class="col-12 mb-3">
                <p>Url Botón</p>
                <mat-radio-group formControlName="botonTipoLink">
                    <mat-radio-button class="mr-3" *ngFor="let tipo of tiposLinks" [value]="tipo">{{tipo}}</mat-radio-button>
                </mat-radio-group>
            </div> -->



      <!-- <div class="col-12" formArrayName="botones">
                <div *ngFor="let presentacion of formBanner.controls['botones']['controls']; let i = index;">
                    <div class="px-3 pt-3 bg-light rounded mb-3" [formGroupName]="i">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <mat-radio-group formControlName="tipo">
                                    <mat-radio-button class="mr-3" *ngFor="let tipo of tiposLinks" [value]="tipo">{{tipo}}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <mat-form-field appearance="outline" class="col-12 col-md-6">
                                <mat-label>Nombre</mat-label>
                                <input matInput formControlName="nombre">
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="col-12 col-md-6">
                                <mat-label>Url</mat-label>
                                <input matInput formControlName="url">
                            </mat-form-field>
                        </div>
                        <div class="flex-shrink-1 d-flex align-items-center" *ngIf="i !=0">
                            <button mat-icon-button (click)="eliminarPresentacion(i)"><mat-icon color="primary" class="material-icons-outlined">delete</mat-icon></button>
                        </div>
                    </div>
                </div>
                <div class="">
                    <button class="mb-3 btn btn-link btn-sm px-0" type="button" (click)="agregarPresentacion()"> <mat-icon class="align-middle">add</mat-icon> Agregar botón</button>
                </div>
            </div> -->

      <!-- <div class="col-12">
                <p>Con el respaldo de:</p>
            </div> -->


      <!-- <div class="col-12 d-flex flex-column" formGroupName="aseguradoras">
        <span class="d-flex align-items-center" *ngFor="let checkbox of aseguradoras">
          <span class="">
            <mat-checkbox class="" [formControlName]="checkbox">{{checkbox}}</mat-checkbox>
          </span>
        </span>
      </div> -->

      


      <mat-form-field appearance="outline">
        <mat-label>Aseguradoras</mat-label>
        <mat-select formControlName="aseguradoras" multiple>
          <mat-option *ngFor="let aseguradora of aseguradoras" [value]="aseguradora">{{aseguradora}}</mat-option>
        </mat-select>
      </mat-form-field>


      

     

      



      <!-- <mat-form-field appearance="outline" class="col-12">
                <mat-label>Imagen</mat-label>
                <ngx-mat-file-input #removableInput formControlName="image" placeholder="Seleccionar imagen" accept=".jpg, .jpeg, .png" (change)="detectFiles($event)"></ngx-mat-file-input>
                <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
              <mat-icon>clear</mat-icon>
              </button>
                <mat-icon matSuffix *ngIf="removableInput.empty">image_search</mat-icon>
                <mat-error *ngIf="errorImagen()">{{errorImagen()}}</mat-error>
                <mat-hint>Peso máximo de la imagen: 1 MB.</mat-hint>
            </mat-form-field> -->

      <!-- <div class="col-12 text-right">
        <button class="btn btn-primary px-3">
          <mat-icon class="align-middle" *ngIf="deshabilitado">add</mat-icon>
          <mat-spinner *ngIf="!deshabilitado" [diameter]="24" class="float-left mr-2"></mat-spinner>
          Crear banner
        </button>
      </div> -->

      <div class="d-grid mt-3 mt-md-4" style="width: 220px;">
        <button class="btn btn-primary" type="submit">
          <span class="d-flex justify-content-center" *ngIf="deshabilitado">
            <mat-spinner [diameter]="24" class="float-left mr-2"></mat-spinner>
          </span>
          <span *ngIf="!deshabilitado">
            <mat-icon class="align-middle">add</mat-icon> Crear banner
          </span>
        </button>
      </div>

    </div>
  </form>
  <!-- <pre>{{formProduct.value | json}}</pre> -->
</div>